import { useRef } from "react";
import { TransactionDataLoader, TransactionValues } from "company/types";
import {
    TransactionFilters,
    TransactionType,
    useTransactionTable,
} from "company/routes/Transactions/hooks/useTransactionTable";
import { useLoaderData } from "react-router-dom";
import Table from "components/Table";
import Section from "components/Section";
import Filters, {
    FiltersRef,
} from "company/routes/Transactions/components/Filters";
import LoadingBox, { LoadingPlaceholderStyle } from "components/LoadingBox";
import spacing from "theme/spacing";
import FailedDataFetchingMessage from "company/components/FailedDataFetchingMessage";
import Anchor from "components/Anchor";
import EmptyTableMessage from "components/EmptyTableMessage";
import RefreshCounter, { CounterType } from "components/RefreshCounter";

const columns: TransactionValues[] = [
    TransactionValues.dateDue,
    TransactionValues.invoiced,
    TransactionValues.allowanceBalance,
    TransactionValues.sender,
    TransactionValues.receiver,
    TransactionValues.network,
    TransactionValues.itemName,
    TransactionValues.invoice,
    TransactionValues.details,
];

const Canceled = () => {
    const { pagination: defaultPagination, sort: initialSort } =
        useLoaderData<TransactionDataLoader>();

    const dateRangeFilters = false;

    const initialFilters: TransactionFilters = {};

    const filtersRef = useRef<FiltersRef>(null);

    const {
        totalResults,
        headings,
        records,
        page,
        sort,
        handleFilterChange,
        handleSortChange,
        handlePageChange,
        transactionsIsLoading,
        transactionsIsError,
        transactionsIsFetching,
        transactionsRefetch,
        transactionsDataUpdatedAt,
    } = useTransactionTable({
        type: TransactionType.CANCELED,
        columns,
        initialFilters,
        initialSort,
        pagination: defaultPagination,
        filtersRef,
    });

    return (
        <>
            <Filters
                dateRangeFilters={dateRangeFilters}
                ref={filtersRef}
                onChange={handleFilterChange}
            />

            <Section>
                <RefreshCounter
                    refreshedAt={transactionsDataUpdatedAt}
                    counter={CounterType.Running}
                    onRefresh={transactionsRefetch}
                    refreshing={transactionsIsFetching}
                />
                {transactionsIsLoading ? (
                    <LoadingBox
                        height={spacing.md}
                        placeholderStyle={LoadingPlaceholderStyle.Table}
                        tablePlaceholderRows={defaultPagination.perPage}
                        tablePlaceholderCols={columns.length}
                    />
                ) : transactionsIsError ? (
                    <FailedDataFetchingMessage />
                ) : (
                    <Table
                        defaultSort={sort}
                        refetching={transactionsIsFetching}
                        pagination={{
                            page,
                            perPage: defaultPagination.perPage,
                        }}
                        externalPageAndSort={true}
                        totalRecords={totalResults}
                        data={{
                            headings,
                            records,
                        }}
                        onPageChange={handlePageChange}
                        onSort={handleSortChange}
                        ifNoRecords={
                            <EmptyTableMessage
                                title="No transactions found"
                                description={
                                    <>
                                        Need help? Take a look at our{" "}
                                        <Anchor
                                            href={
                                                import.meta.env
                                                    .VITE_LOOP_DOCS_API_ENABLED_CONTRACT_SENDING_PAYMENT_REQUESTS
                                            }
                                        >
                                            documentation
                                        </Anchor>{" "}
                                        or{" "}
                                        <Anchor
                                            href={`mailto:${
                                                import.meta.env
                                                    .VITE_EMAIL_SUPPORT
                                            }`}
                                        >
                                            contact us
                                        </Anchor>
                                        .
                                    </>
                                }
                            />
                        }
                    />
                )}
            </Section>
        </>
    );
};

export default Canceled;
