const ratePrecision = 100; // backend format to 4 decimals

export const convertTokenToCents = (
    tokenAmount: number,
    rate: number
): number => (tokenAmount * rate) / ratePrecision;

export const convertCentsToToken = (
    amountInCents: number,
    rate: number
): number => (amountInCents / rate) * ratePrecision;
