import WalletConnectPanel from "components/WalletConnectPanel";
import * as S from "./style";
import { useCheckoutData } from "checkout/context/CheckoutData";
import { onlyUnique } from "utils/arrays";
import WalletConnectMultiSig from "components/WalletConnectMultiSig";

interface CheckoutConnectWalletProps {}

const CheckoutConnectWallet = (props: CheckoutConnectWalletProps) => {
    const { queryParams, tokens, networks } = useCheckoutData();

    return (
        <S.CheckoutConnectWallet>
            <WalletConnectPanel
                full
                useCheckoutWalletSwitch={queryParams.embed}
            >
                Connect wallet to pay
            </WalletConnectPanel>
            <S.MultiSigWrapper>
                <WalletConnectMultiSig />
            </S.MultiSigWrapper>

            <p>
                Pay with{" "}
                <strong>
                    {tokens
                        .map((t) => t.symbol)
                        .filter(onlyUnique)
                        .join(", ")}
                </strong>{" "}
                on <strong>{networks.map((n) => n.name).join(", ")}</strong>
            </p>
        </S.CheckoutConnectWallet>
    );
};

export default CheckoutConnectWallet;
