import {
    Company,
    EntityInboundTreasuriesByNetworkId,
    PaymentPlatformSource,
} from "company/types";
import {
    STRIPE_SUBSCRIPTION_ID_PREFIX,
    validateStripeSubscriptionId,
} from "./stripe";
import { validateChargebeeSubscriptionId } from "./chargebee";
import { reactAppBuildType } from "default-variables";
import { sub } from "date-fns";

/*
    Helpers
*/

// Always pick the first treasury address
export function getInboundTreasuryAddressByNetworkId(entity?: Company.Entity) {
    if (!entity || !entity.inboundTreasuries) return {};

    const result: EntityInboundTreasuriesByNetworkId = {};

    const keys = Object.keys(entity.inboundTreasuries);
    for (const key of keys) {
        const addresses = entity.inboundTreasuries[key];
        if (addresses.length > 0) {
            result[key] = addresses[0]; // Always take the first address
        }
    }

    return result;
}

/* 
    Formatters
*/

// this is a bit redundant but just in case the backend payload format ever changes
export const entityPaymentPlatformSourceForDisplay = {
    [PaymentPlatformSource.Stripe]: "Stripe",
    [PaymentPlatformSource.Chargebee]: "Chargebee",
    [PaymentPlatformSource.QuickBooks]: "QuickBooks",
    [PaymentPlatformSource.Xero]: "Xero",
};

export const entityPaymentPlatformSourceCanImportSubscription = [
    PaymentPlatformSource.Stripe,
    PaymentPlatformSource.Chargebee,
];

export const subscriptionIdHintForDisplay = {
    [PaymentPlatformSource.Stripe]: `Stripe subscription ID starts with ${STRIPE_SUBSCRIPTION_ID_PREFIX}`,
    [PaymentPlatformSource.Chargebee]:
        "You can find the ID on your Chargebee Dashboard",
    [PaymentPlatformSource.QuickBooks]:
        "You cannot import subscription from Quickbooks",
    [PaymentPlatformSource.Xero]: "You cannot import subscription from Xero",
};

/*
    Policy
*/

export function canImportEntityExternalSubscription(entity: Company.Entity) {
    // No third party payment platform
    if (!entity.paymentPlatformProvider) return false;

    // Can only import Subscription from Stripe & Chargebee
    return entityPaymentPlatformSourceCanImportSubscription.includes(
        entity.paymentPlatformProvider
    );
}

/*
    External Integration URLs class
    Since URLs vastly varies from between integrations (Chargebee, Stripe, etc..),
    we use this class as a "centralized" source of truth to set & get URLs
*/

export class PaymentPlatformUrls {
    paymentPlatformSource?: PaymentPlatformSource;
    siteId?: string;

    baseUrl?: string;
    subscriptionsUrl?: string;

    constructor(
        paymentPlatformSource?: PaymentPlatformSource,
        siteId?: string
    ) {
        this.paymentPlatformSource = paymentPlatformSource;

        // Site ID is only relevant for chargebee, thus optional
        this.siteId = siteId;

        // Set the static URLs
        this.baseUrl = this.getBaseUrl();
        this.subscriptionsUrl = this.getSubscriptionsUrl();
    }

    // These never changes, we just use them as to set the base value
    private getBaseUrl() {
        switch (this.paymentPlatformSource) {
            case PaymentPlatformSource.Stripe:
                return reactAppBuildType === "production"
                    ? "https://dashboard.stripe.com"
                    : `https://dashboard.stripe.com/test`;

            case PaymentPlatformSource.Chargebee:
                if (this.siteId) {
                    return `https://${this.siteId}.chargebee.com`;
                }
                break;
            default:
                break;
        }
    }

    private getSubscriptionsUrl() {
        switch (this.paymentPlatformSource) {
            case PaymentPlatformSource.Stripe:
                return `${this.baseUrl}/subscriptions`;
            case PaymentPlatformSource.Chargebee:
                return `${this.baseUrl}/subscriptions`;
            default:
                break;
        }
    }

    // These are dynamic / based on parameters
    public subscriptionUrl(subscriptionId: string) {
        switch (this.paymentPlatformSource) {
            case PaymentPlatformSource.Stripe:
                return `${this.baseUrl}/subscriptions/${subscriptionId}`;
            case PaymentPlatformSource.Chargebee:
                return `${this.baseUrl}/d/subscriptions/${subscriptionId}`;
            default:
                break;
        }
    }

    public itemUrl(priceId: string) {
        switch (this.paymentPlatformSource) {
            case PaymentPlatformSource.Stripe:
                return `${this.baseUrl}/prices/${priceId}`;

            case PaymentPlatformSource.Chargebee:
                // TODO: We need more info to build the chargebee URL, e.g.
                // https://loopcrypto-test.chargebee.com/d/plans/[Basic-level]/prices/[Basic-level-EUR-Weekly]
                break;

            default:
                break;
        }
    }

    public invoiceUrl(invoiceId: string) {
        // TODO: Need to include Xero & QuickBooks invoices
        switch (this.paymentPlatformSource) {
            case PaymentPlatformSource.Stripe:
                if (invoiceId.startsWith("draft")) {
                    const parts = invoiceId.split("-");
                    const customerId = parts[1];
                    const subscriptionId = parts[2];
                    return `${this.baseUrl}/customers/${customerId}/upcoming_invoice/${subscriptionId}`;
                } else return `${this.baseUrl}/invoices/${invoiceId}`;
            case PaymentPlatformSource.Chargebee:
                return `${this.baseUrl}/d/invoices/${invoiceId}`;
            default:
                break;
        }
    }
}

/*
    External Integration validators class
    Mostly used to validate subscriptions Id
*/
export function validateSubscriptionId(
    subscriptionId: string,
    paymentPlatformSource?: PaymentPlatformSource
) {
    if (!paymentPlatformSource) return false;
    switch (paymentPlatformSource) {
        case PaymentPlatformSource.Stripe:
            return validateStripeSubscriptionId(subscriptionId);

        case PaymentPlatformSource.Chargebee:
            return validateChargebeeSubscriptionId(subscriptionId);
        default:
            return false;
    }
}
