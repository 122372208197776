import { useEffect, useState } from "react";
import { CheckoutItemResponse } from "api/types/checkout";
import { BaseItem } from "types/common";
import {
    minimumBalanceRequiredAmountForCheckout,
    calculateMinimumBalance,
} from "utils/checkout";

export const useMinimumBalance = (
    invoiceAmount: number | undefined,
    items: CheckoutItemResponse[] | BaseItem[],
    minimumBalanceRequired: number | undefined
) => {
    const [minimumBalance, setMinimumBalance] = useState<number>(0);

    useEffect(() => {
        setMinimumBalance(
            minimumBalanceRequiredAmountForCheckout(
                calculateMinimumBalance(
                    invoiceAmount,
                    items,
                    minimumBalanceRequired
                )
            )
        );
    }, [invoiceAmount, items, minimumBalanceRequired]);

    return minimumBalance;
};
