import * as S from "./style";
import { Company } from "company/types";
import { firstToUpper } from "utils/strings";
import InfoToolTip from "components/InfoToolTip";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import ExternalLink from "components/icons/ExternalLink";
import Notification, { NotificationType } from "components/Notification";
import Anchor from "components/Anchor";
import Section from "components/Section";
import Table from "components/Table";
import InboundTreasuryTableCell from "company/components/InboundTreasuryTableCell";
import LoadingBox, { LoadingPlaceholderStyle } from "components/LoadingBox";
import FailedDataFetchingMessage from "company/components/FailedDataFetchingMessage";
import DynamicWalletAddressDisplay from "components/DynamicWalletAddress/DynamicWalletAddressDisplay";
import { CommonBlockchainNetworkResponse } from "api";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useEns } from "contexts/EnsProvider";
import { useUser } from "context/User";

export enum SmartContractType {
    Subscription = 1,
    RecurringSwap = 2,
    VariableRate = 3,
}

interface Endpoints {
    endpoint: string;
    webhooks: Company.Webhook[];
}

const Developer = () => {
    const { getEnsRecord } = useEns();

    const {
        config: { contracts, entities },
        getCompanyConfigIsLoading,
        getCompanyConfigIsError,
        getCompanyConfigIsFetching,
    } = useGetCompanyConfig();

    const { networks, getNetworksIsError } = useGetNetworks();

    const isError = getCompanyConfigIsError || getNetworksIsError;

    const { getEntityId } = useUser();

    const entity: Company.Entity | undefined = entities.find(
        (e: Company.Entity) => e.entityId === getEntityId()
    );

    const renderInboundTreasuries = (networkId: string) => {
        const walletAddresses =
            entity?.inboundTreasuries && entity?.inboundTreasuries[networkId];

        if (!walletAddresses) return;
        return (
            <InboundTreasuryTableCell
                key={`${walletAddresses[0]}-${networkId}`}
                walletAddress={walletAddresses[0]} // Only show the first address for now
                networkId={Number(networkId)}
            />
        );
    };

    // Note: this assumes all entities returned are children of this parent
    // If there's any reason to bring other entities into this object, then a
    // recursive search of the parentId-to-entityId is necessary
    const childEntities: Company.Entity[] = entities.filter(
        ({ parentId }: Company.Entity) => parentId
    );

    const webhooksGroupedByEndpoint =
        entity?.webhooks?.reduce<Endpoints[]>(
            (endpoints: Endpoints[], hook: Company.Webhook) => {
                const endpoint = endpoints.find(
                    ({ endpoint }) => endpoint === hook.endpoint
                );
                if (endpoint) {
                    endpoint.webhooks.push(hook);
                    return endpoints;
                } else {
                    return [
                        ...endpoints,
                        { endpoint: hook.endpoint, webhooks: [hook] },
                    ];
                }
            },
            []
        ) || [];

    const contractRecords: Tuple[] = !contracts
        ? []
        : contracts.reduce<Tuple[]>(
              (
                  records: Tuple[],
                  {
                      networkId,
                      address,
                      owner,
                      certifiedSigner,
                  }: Company.Contract
              ) => {
                  const network: CommonBlockchainNetworkResponse | undefined =
                      networks.find(
                          ({ id }: CommonBlockchainNetworkResponse) =>
                              id === networkId
                      );

                  return !network
                      ? records
                      : [
                            ...records,
                            {
                                id: address,
                                values: [
                                    {
                                        label: firstToUpper(network.name),
                                        value: network.name,
                                        text: network.name,
                                    },
                                    {
                                        label: (
                                            <>
                                                <S.CopyAddress>
                                                    <DynamicAddressDisplay
                                                        address={address}
                                                        networkId={
                                                            network.hexId
                                                        }
                                                    />
                                                </S.CopyAddress>
                                            </>
                                        ),
                                        value: address,
                                        text: address,
                                    },
                                ],
                            },
                        ];
              },
              []
          );

    if (isError) return <FailedDataFetchingMessage />;

    return (
        <>
            <Section title="Contracts">
                {getCompanyConfigIsLoading && (
                    <LoadingBox
                        placeholderStyle={LoadingPlaceholderStyle.Table}
                        tablePlaceholderCols={2}
                        tablePlaceholderRows={2}
                    />
                )}
                {!getCompanyConfigIsLoading && (
                    <Table
                        refetching={getCompanyConfigIsFetching}
                        data={{
                            headings: [
                                { label: `network`, style: { width: "40em" } },
                                { label: `contract` },
                            ],
                            records: contractRecords,
                        }}
                    ></Table>
                )}
            </Section>

            <Section title="Company Details">
                {getCompanyConfigIsLoading && (
                    <LoadingBox
                        placeholderStyle={LoadingPlaceholderStyle.Table}
                        tablePlaceholderCols={6}
                        tablePlaceholderRows={2}
                    />
                )}
                {!getCompanyConfigIsLoading && entity && (
                    <>
                        <Table
                            refetching={getCompanyConfigIsFetching}
                            data={{
                                headings: [
                                    { label: `Entity ID` },
                                    { label: `Name` },
                                    { label: `Reply to` },
                                    { label: `Inbound Treasury Addresses` },
                                    {
                                        label: `Logo`,
                                        style: { shrink: true },
                                    },
                                    {
                                        label: `Website`,
                                        style: { shrink: true },
                                    },
                                ],
                                records: [
                                    {
                                        id: entity.entityId,
                                        values: [
                                            {
                                                label: entity.entityId,
                                                value: entity.entityId,
                                                text: entity.entityId,
                                            },
                                            {
                                                label: entity.name,
                                                value: entity.name,
                                                text: entity.name,
                                            },
                                            {
                                                label: entity.replyTo ? (
                                                    <Anchor
                                                        href={`mailto:${entity.replyTo}`}
                                                        inheritColor
                                                    >
                                                        {entity.replyTo}
                                                    </Anchor>
                                                ) : (
                                                    `-`
                                                ),
                                                value: entity.replyTo || ``,
                                                text: entity.replyTo || `-`,
                                            },
                                            {
                                                label:
                                                    entity.inboundTreasuries &&
                                                    Object.keys(
                                                        entity.inboundTreasuries
                                                    ).length > 0 ? (
                                                        <S.TreasuriesList>
                                                            {Object.keys(
                                                                entity.inboundTreasuries
                                                            ).map(
                                                                renderInboundTreasuries
                                                            )}
                                                        </S.TreasuriesList>
                                                    ) : (
                                                        `-`
                                                    ),

                                                value: "Inbound Treasuries",
                                                text: "Inbound Treasuries",
                                            },
                                            {
                                                label:
                                                    entity.logoUrl &&
                                                    entity.logoUrl.trim() !==
                                                        `-` ? (
                                                        <Anchor
                                                            href={
                                                                entity.logoUrl
                                                            }
                                                        >
                                                            <ExternalLink />
                                                        </Anchor>
                                                    ) : (
                                                        `-`
                                                    ),
                                                value: entity.logoUrl || `-`,
                                                text: entity.logoUrl || `-`,
                                            },
                                            {
                                                label: entity.websiteUrl ? (
                                                    <Anchor
                                                        href={entity.websiteUrl}
                                                    >
                                                        <ExternalLink />
                                                    </Anchor>
                                                ) : (
                                                    `-`
                                                ),
                                                value: entity.websiteUrl || `-`,
                                                text: entity.websiteUrl || `-`,
                                            },
                                        ],
                                    },
                                ],
                            }}
                        ></Table>
                        {childEntities && childEntities.length > 0 && (
                            <footer>
                                <Anchor href="/developer/entities">
                                    View all child entities
                                </Anchor>
                            </footer>
                        )}
                    </>
                )}
            </Section>

            <Section
                title={
                    <>
                        Webhooks{" "}
                        <InfoToolTip
                            title={
                                <>
                                    Please contact Loop to change.{" "}
                                    <Anchor
                                        href={
                                            import.meta.env
                                                .VITE_LOOP_DOCS_WEBHOOKS
                                        }
                                    >
                                        Learn more
                                    </Anchor>
                                    .
                                </>
                            }
                        />
                    </>
                }
            >
                {getCompanyConfigIsLoading && <LoadingBox />}
                {!getCompanyConfigIsLoading && (
                    <>
                        {webhooksGroupedByEndpoint.length === 0 ? (
                            <Notification
                                key={"msg.id"}
                                message={{
                                    id: "webhook",
                                    msg: (
                                        <>
                                            <strong>
                                                No webhooks configured
                                            </strong>
                                            <p>
                                                Send us your endpoint to setup{" "}
                                                <Anchor
                                                    href={
                                                        import.meta.env
                                                            .VITE_LOOP_DOCS_WEBHOOKS
                                                    }
                                                >
                                                    webhooks
                                                </Anchor>
                                                .
                                            </p>
                                        </>
                                    ),
                                    type: NotificationType.INFO,
                                    expires: false,
                                }}
                                removeNotification={false}
                            />
                        ) : (
                            <>
                                {entity?.secret && (
                                    <h3>Secret key: {entity.secret}</h3>
                                )}
                                <Table
                                    refetching={getCompanyConfigIsFetching}
                                    data={{
                                        headings: [
                                            { label: `ID` },
                                            { label: `Events` },
                                        ],
                                        records: webhooksGroupedByEndpoint?.map(
                                            ({ endpoint, webhooks }) => ({
                                                id: endpoint,
                                                values: [
                                                    {
                                                        label: endpoint,
                                                        value: endpoint,
                                                        text: endpoint,
                                                        style: {
                                                            verticalAlign:
                                                                "top",
                                                        },
                                                    },
                                                    {
                                                        label: (
                                                            <S.TableList>
                                                                {webhooks.map(
                                                                    ({
                                                                        webhookEvent,
                                                                    }) => (
                                                                        <li
                                                                            key={
                                                                                webhookEvent
                                                                            }
                                                                        >
                                                                            {
                                                                                webhookEvent
                                                                            }
                                                                        </li>
                                                                    )
                                                                )}
                                                            </S.TableList>
                                                        ),
                                                        value: webhooks.join(
                                                            `, `
                                                        ),
                                                        text: webhooks.join(
                                                            `, `
                                                        ),
                                                    },
                                                ],
                                            })
                                        ),
                                    }}
                                ></Table>
                            </>
                        )}
                    </>
                )}
            </Section>
        </>
    );
};

export default Developer;
