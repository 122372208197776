import IWethAbi from "abis/abi/IWeth.json";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { ethers } from "ethers";
import Safe from "@safe-global/protocol-kit";
import {
    SafeTransactionDataPartial,
    OperationType,
} from "@safe-global/safe-core-sdk-types";
import { useContract } from "hooks/useContract";
import {
    WrapperToken,
    getNativeTokenBySymbol,
    getWrapperSymbols,
} from "hooks/useNativeTokens";
import { useWallet } from "context/Wallet";
import { SafeWallet } from "context/Wallet/hooks/useWalletSafes";

const useSafeApi = () => {
    // [ ] Remove this "any" when safe is integrated
    const { walletConnected, safeWallet } = useWallet();
    const { getErc20Contract }: any = useContract();
    const [safeConnected, setSafeConnected] = useState<SafeWallet | null>(null);

    const safe = useRef<Safe | null>(null);

    useEffect(() => {
        let isMounted = true;

        safeWallet
            .then((wallet) => {
                if (isMounted) {
                    setSafeConnected(wallet);
                }
            })
            .catch(() => {
                if (isMounted) {
                    setSafeConnected(null);
                }
            });

        return () => {
            isMounted = false;
        };
    }, [safeWallet]);

    // [ ] Perhaps safe should be assigned within here, so that we can ditch the useRef and simply store it as state, that resolves when `walletConnected` updates from it's resolution

    const initializeSafe = useCallback(async () => {
        if (!walletConnected?.proxyFor || !safeConnected?.adapter) {
            safe.current = null;
            return Promise.reject(`A Safe wallet has not been configured`);
        }

        // Create Safe instance
        return await Safe.create({
            ethAdapter: safeConnected.adapter,
            safeAddress: ethers.utils.getAddress(walletConnected.proxyFor),
        })
            .then((newSafe) => {
                safe.current = newSafe;
                return Promise.resolve(safe.current);
            })
            .catch((e) => {
                return Promise.reject(`Safe could not be initialized`);
            });
    }, [walletConnected?.proxyFor, safeConnected?.adapter]);

    useEffect(() => {
        (async () => {
            await initializeSafe().catch((e) => null);
        })();
    }, [initializeSafe]);

    const createAndProposeSafeTx = async (data: SafeTransactionDataPartial) => {
        // Run initialization if not already initialized
        safe.current = !safe.current
            ? await initializeSafe().catch((e) => null)
            : safe.current;

        if (
            !safe.current ||
            !walletConnected?.proxyFor ||
            walletConnected.chain !== "EVM"
        )
            return Promise.reject(`Safe has not yet been initialized`);

        const safeTransaction = await safe.current.createTransaction({
            safeTransactionData: data,
        });

        const senderAddress = await walletConnected?.signer?.getAddress();
        const safeTxHash = await safe.current.getTransactionHash(
            safeTransaction
        );
        const signature = await safe.current
            .signTransactionHash(safeTxHash)
            .catch((e: any) => {
                return Promise.reject(
                    `Transaction was rejected by your wallet`
                );
            });

        // Propose transaction to the service
        return await safeConnected?.service?.proposeTransaction({
            safeAddress: ethers.utils.getAddress(walletConnected.proxyFor),
            safeTransactionData: safeTransaction.data,
            safeTxHash,
            senderAddress,
            senderSignature: signature.data,
        });
    };

    const sendApproveAllownaceForSafe = async ({
        token,
        contract,
        amount,
    }: {
        token: Pick<BaseToken, "address" | "decimals" | "symbol"> &
            Partial<BaseToken>;
        contract: string;
        amount: string;
    }): Promise<string | ReactNode> => {
        // Run initialization if not already initialized
        safe.current = !safe.current
            ? await initializeSafe().catch((e) => null)
            : safe.current;

        if (!safe.current)
            return Promise.reject(`Safe has not yet been initialized`);

        if (!walletConnected?.proxyFor)
            return Promise.reject(
                `Your wallet is not being used as a proxy for a Safe multisig`
            );

        const tokenContract = await getErc20Contract({
            tokenIn: ethers.utils.getAddress(token.address),
        });

        // Encode the data for the approve function
        const data = tokenContract.interface.encodeFunctionData(`approve`, [
            ethers.utils.getAddress(contract),
            ethers.utils.parseUnits(amount, token.decimals),
        ]);

        const nextNonce = await safeConnected?.service?.getNextNonce(
            ethers.utils.getAddress(walletConnected.proxyFor)
        );

        // Create transaction
        const safeTransactionData: SafeTransactionDataPartial = {
            to: ethers.utils.getAddress(token.address),
            value: "0",
            data,
            operation: OperationType.Call,
            nonce: nextNonce,
        };

        // Propose transaction to the service
        return createAndProposeSafeTx(safeTransactionData)
            .then((res: any) => {
                return Promise.resolve(
                    <>
                        Transaction to update {token.symbol} allowance to{" "}
                        {amount} was sent to your proxied Safe's queue.
                        <br />
                        The allowance value will not be updated until the
                        transaction is accepted and processed by your Safe
                        wallet.
                    </>
                );
            })
            .catch((e: any) => {
                console.error(e);
                return Promise.reject(
                    `There was a problem sending the allowance transaction to your proxied Safe's queue`
                );
            });
    };

    const sendWrapNativeToken = async ({
        token,
        amount,
    }: {
        token: Pick<WrapperToken, "address" | "symbol" | "wrapsTo"> &
            Partial<WrapperToken>;
        amount: string;
    }) => {
        // if (!safe.current)
        //     return Promise.reject(`Safe has not yet been initialized`);
        safe.current = !safe.current
            ? await initializeSafe().catch((e) => null)
            : safe.current;
        if (!safe.current)
            return Promise.reject(`Safe has not yet been initialized`);

        if (!walletConnected?.proxyFor)
            return Promise.reject(
                `Your wallet is not being used as a proxy for a Safe multisig`
            );

        const supportedWrappers = getWrapperSymbols();

        if (
            !supportedWrappers.includes(token.wrapsTo.toUpperCase()) ||
            walletConnected.chain !== "EVM"
        ) {
            return Promise.reject(
                `${token.symbol.toUpperCase()} cannot be wrapped to ${token.wrapsTo.toUpperCase()}`
            );
        }
        const wrapContract = new ethers.Contract(
            ethers.utils.getAddress(token.address),
            IWethAbi, // IWethAbi deposit function is identical for IWmaticAbi
            walletConnected.signer
        );

        // Encode the data for the approve function
        const data = wrapContract.interface.encodeFunctionData("deposit", []);

        const nextNonce = await safeConnected?.service?.getNextNonce(
            ethers.utils.getAddress(walletConnected.proxyFor)
        );

        // Create transaction
        const safeTransactionData: SafeTransactionDataPartial = {
            to: ethers.utils.getAddress(token.address),
            value: ethers.utils
                .parseUnits(
                    amount,
                    getNativeTokenBySymbol(token.symbol)?.decimals
                )
                .toString(),
            data,
            operation: OperationType.Call,
            nonce: nextNonce,
        };

        // Propose transaction to the service
        return createAndProposeSafeTx(safeTransactionData)
            .then((res: any) => {
                return Promise.resolve(
                    <>
                        Transaction to swap {amount}{" "}
                        {token.wrapsTo.toUpperCase()} to{" "}
                        {token.symbol.toUpperCase()} was sent to your proxied
                        Safe's queue.
                        <br />
                        The balances will not be updated until the transaction
                        is accepted and processed by your Safe wallet.
                    </>
                );
            })
            .catch((e: any) => {
                console.error(e);
                return Promise.reject(
                    `There was a problem sending the token wrapping transaction to your proxied Safe's queue`
                );
            });
    };

    return { sendApproveAllownaceForSafe, sendWrapNativeToken };
};

export { useSafeApi };
