import * as S from "./style";

const Loading = () => {
    return (
        <S.Logo viewBox="0 0 335 400" fill="none">
            <linearGradient id="gradient">
                <S.StartGradient offset="0%" />
                <S.StopGradient offset="40%" />
            </linearGradient>
            <S.Loop
                d="M 67.5 302 C 59 302 16.5002 302 11.0002 302 C 11.0002 276.499 11 22.4996 10.9999 10.9996 L 76.0001 10.9996 C 91.9906 15.9771 99.083 22.4738 101.5 38.9998 L 101.5 389 L 323.5 389 L 323.5 330.5 C 322.638 316.982 311.053 303.116 296.5 302 C 296.5 302 206.25 302 161.5 302 "
                stroke="url(#gradient)"
            />
            <S.Arrow d="M193 259.5L146.5 302L193 343.5" />
        </S.Logo>
    );
};

export default Loading;
