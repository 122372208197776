import * as S from "./style";
import { colorsDeprecated as c } from "global-style";
import { ChangeEvent, forwardRef, Ref, useCallback, useState } from "react";
import { toCoin, toDollar } from "utils/financial";
import { bnToNum, ceilNumber, floorNumber, toNumber } from "utils/numbers";
import { slippage } from "utils/checkout";
import { regexToPattern, regex } from "utils/regex";
import { validateAmountRangeInCents } from "utils/formValidation";
import { CheckoutToken } from "checkout/types";
import { useWrapToken } from "checkout/hooks/useWrapToken";
import { useNotificationQueue } from "context/NotificationQueue";
import { useCheckoutForm } from "checkout/context/CheckoutForm";
import Button, { ButtonVariants } from "components/Button";
import Anchor from "components/Anchor";
import { useCheckoutData } from "checkout/context/CheckoutData";
import Close from "components/icons/Close";
import { NotificationType } from "components/Notification";
import { convertCentsToToken, convertTokenToCents } from "utils/exchangeRates";
import { InputSizes } from "components/Input";

interface WrapNativeTokenFormProps {
    token: CheckoutToken;
    onSuccess?: () => void;
    onCancel?: () => void;
}

interface WrapNativeTokenFormRef {}

const WrapNativeTokenForm = forwardRef(
    (
        { token, onSuccess, onCancel }: WrapNativeTokenFormProps,
        ref: Ref<WrapNativeTokenFormRef>
    ) => {
        const [loading, setLoading] = useState<boolean>(false);
        const { addNotification, removeNotification } = useNotificationQueue();

        const { usdTotalDueToday, isInvoicedCheckout, isExternalSubscription } =
            useCheckoutData();

        const {
            tokenTotalWithoutDiscount,
            tokenTotalDueToday,
            nativeToken,
            tokenBalance,
            wrapperBalance,
            tokenMinimumBalance,
            isTokenPricedCheckout,
            updateBalances,
        } = useCheckoutForm();

        const { sendWrap } = useWrapToken(nativeToken);

        // Wallet's NATIVE token balance in USD
        const usdWrapperBalance: number = floorNumber(
            convertTokenToCents(wrapperBalance, token.exchange.rate),
            2
        );

        // If it's an invoice, then the amount will be reflected in the `usdTotalDueToday` price, else use the total without discount
        const checkoutAmountInToken: number =
            (isInvoicedCheckout || isExternalSubscription) &&
            isTokenPricedCheckout
                ? bnToNum(tokenTotalWithoutDiscount ?? 0, token.decimals)
                : convertCentsToToken(
                      usdTotalDueToday ?? 0,
                      token.exchange.rate
                  );

        const totalTokenAmountNeeded: number = Math.max(
            isTokenPricedCheckout
                ? checkoutAmountInToken
                : checkoutAmountInToken * slippage,
            tokenMinimumBalance
        );

        // When user *should* wrap, calculate how much they should wrap
        const minAmountToWrapToCoverPayment = Math.max(
            isTokenPricedCheckout
                ? totalTokenAmountNeeded - tokenBalance
                : ceilNumber(
                      // convert to USD
                      convertTokenToCents(
                          totalTokenAmountNeeded - tokenBalance,
                          token.exchange.rate
                      ),
                      2
                  ),
            0
        );

        // State - How much the user wants to wrap (in cents)
        const [amountToWrap, setAmountToWrap] = useState<number>(
            isTokenPricedCheckout
                ? minAmountToWrapToCoverPayment ||
                      bnToNum(tokenTotalDueToday ?? 0, token.decimals)
                : minAmountToWrapToCoverPayment ||
                      ceilNumber((usdTotalDueToday ?? 0) * slippage, 2)
        );

        const amountToWrapInToken = toCoin(
            isTokenPricedCheckout
                ? amountToWrap
                : convertCentsToToken(amountToWrap, token.exchange.rate),
            6,
            "ceil"
        );

        // Amount to wrap + user balance
        const totalTokenAmountAfterWrap =
            toNumber(amountToWrapInToken) + tokenBalance;

        const onSubmit = useCallback(async () => {
            if (token && nativeToken) {
                setLoading(true);
                const wrapNotificationId = addNotification({
                    msg: `Swapping ${nativeToken.symbol} for ${token.symbol}...`,
                    type: NotificationType.WORKING,
                });
                await sendWrap(amountToWrapInToken)
                    .then((success) => {
                        addNotification({
                            msg: success,
                            type: NotificationType.SUCCESS,
                        });
                    })
                    .catch((error) => {
                        addNotification({
                            msg: error,
                            type: NotificationType.ERROR,
                        });
                    })
                    .finally(() => {
                        removeNotification(wrapNotificationId);
                    });
                await updateBalances();
                if (onSuccess) {
                    onSuccess();
                }
                setLoading(false);
            }
        }, [
            sendWrap,
            token,
            nativeToken,
            addNotification,
            removeNotification,
            amountToWrapInToken,
            updateBalances,
            onSuccess,
        ]);

        if (!nativeToken) return <></>;

        const usdMinimum = 1;
        const tokenMinimum = 1 / Math.pow(10, token.decimals);

        const inputValueAmountToWrap = isTokenPricedCheckout
            ? String(amountToWrap)
            : (amountToWrap / 100).toFixed(2);

        return (
            <>
                <S.WrapNativeTokenForm onSubmit={onSubmit}>
                    <strong>How much do you want to wrap?</strong>
                    <S.WrapField>
                        <S.WrapLabel htmlFor="amountToWrap">
                            Amount to wrap
                        </S.WrapLabel>
                        <S.InputWrapper>
                            <S.InputWrapAmount
                                inputProps={{
                                    type: "text",
                                    disabled: loading,
                                    name: "amountToWrap",
                                    placeholder: "0",
                                    inputSize: InputSizes.Small,
                                    value: inputValueAmountToWrap,
                                    pattern: isTokenPricedCheckout
                                        ? regexToPattern(regex.coins)
                                        : regexToPattern(regex.dollars),
                                    onFocus: (event) => {
                                        event.target.value =
                                            inputValueAmountToWrap;
                                    },
                                    onBlur: (event) => {
                                        validateAmountRangeInCents({
                                            input: event.target,
                                            reportValidity: true,
                                            min:
                                                minAmountToWrapToCoverPayment ||
                                                isTokenPricedCheckout
                                                    ? tokenMinimum
                                                    : usdMinimum,
                                            max: isTokenPricedCheckout
                                                ? wrapperBalance
                                                : usdWrapperBalance,
                                            errorMessages: {
                                                low: `Amount must be at least ${
                                                    isTokenPricedCheckout
                                                        ? `${toCoin(
                                                              minAmountToWrapToCoverPayment ||
                                                                  tokenMinimum,
                                                              6
                                                          )} ${
                                                              nativeToken.symbol
                                                          }`
                                                        : toDollar(
                                                              minAmountToWrapToCoverPayment ||
                                                                  usdMinimum
                                                          )
                                                }`,
                                                high: `You don't have enough ${
                                                    nativeToken.symbol
                                                } (Balance: ${
                                                    isTokenPricedCheckout
                                                        ? toCoin(wrapperBalance)
                                                        : toDollar(
                                                              usdWrapperBalance
                                                          )
                                                })`,
                                            },
                                        });
                                        event.target.value =
                                            inputValueAmountToWrap;
                                        setAmountToWrap(amountToWrap);
                                    },
                                    onChange: (
                                        event: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (!event.target.validity.valid) {
                                            event.target.value =
                                                inputValueAmountToWrap;
                                            return;
                                        }
                                        setAmountToWrap(
                                            toNumber(event.target.value) * 100
                                        );
                                    },
                                }}
                                addon={{
                                    content: isTokenPricedCheckout
                                        ? nativeToken.symbol
                                        : "$",
                                    position: "left",
                                }}
                            />
                            <small>
                                {isTokenPricedCheckout
                                    ? `${toDollar(
                                          convertTokenToCents(
                                              amountToWrap,
                                              token.exchange.rate
                                          )
                                      )}`
                                    : `${amountToWrapInToken} ${nativeToken.symbol}`}
                            </small>
                        </S.InputWrapper>
                    </S.WrapField>

                    <S.WrapField>
                        <S.WrapLabel>New total balance</S.WrapLabel>
                        <S.InputWrapper>
                            <div>
                                {toCoin(totalTokenAmountAfterWrap)}{" "}
                                {token.symbol}
                                {!isTokenPricedCheckout &&
                                    ` (
                                ${toDollar(
                                    convertTokenToCents(
                                        totalTokenAmountAfterWrap,
                                        token.exchange.rate
                                    )
                                )}
                                )`}
                            </div>
                        </S.InputWrapper>
                    </S.WrapField>
                    <Button
                        full
                        loading={loading}
                        variant={ButtonVariants.PrimaryOutlined}
                    >
                        {loading
                            ? `Wrapping ${nativeToken.symbol}..`
                            : `Wrap ${nativeToken.symbol}`}
                    </Button>
                    {onCancel && (
                        <S.CancelWrapper>
                            <Anchor
                                onClick={onCancel}
                                href="#close-wrap"
                                anchorLinkPreventDefault
                            >
                                <Close
                                    fill={c.popoverCloseLink}
                                    width="0.75rem"
                                    height="0.75rem"
                                />
                            </Anchor>
                        </S.CancelWrapper>
                    )}
                </S.WrapNativeTokenForm>
            </>
        );
    }
);

export default WrapNativeTokenForm;
