import WalletSwitchMessage from "checkout/components/WalletSwitchMessage";
import WalletSwitchSection from "checkout/components/WalletSwitchSection";
import CheckoutFooter from "checkout/components/CheckoutFooter";
import * as S from "./styles";

const WalletSwitch = () => {
    return (
        <S.WalletSwitchLayout>
            <S.WalletSwitchContent>
                <WalletSwitchMessage />
                <WalletSwitchSection />
            </S.WalletSwitchContent>
            <CheckoutFooter showCart={false} />
        </S.WalletSwitchLayout>
    );
};

export default WalletSwitch;
