import styled from "styled-components";
import Form from "components/Form";

export const EditForm = styled(Form)`
    display: flex;
    gap: 1rem;
    align-items: baseline;
    width: 100%;
`;

export const InputWrapper = styled.div`
    min-width: 12rem;
`;

export const UsdCheckboxWrapper = styled.div`
    margin-top: 0.5rem;
`;
