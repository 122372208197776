import { getCompanyAgreements } from "api";
import { useUser } from "context/User";
import { useCompanyAuthQuery } from "hooks/useCompanyAuthQuery";
import { useMemo } from "react";

export const GET_COMPANY_AGREEMENTS_QUERY_KEY = `getCompanyAgreements`;

export const useGetCompanyAgreements = () => {
    const { getEntityId, getSessionToken } = useUser();

    // Scoped query key
    const queryKey = [GET_COMPANY_AGREEMENTS_QUERY_KEY];

    const {
        data: getCompanyAgreementsData,
        isError: getCompanyAgreementsIsError,
        isLoading: getCompanyAgreementsIsLoading,
        dataUpdatedAt: getCompanyAgreementsDataUpdatedAt,
        refetch: getCompanyAgreementsRefetch,
        isFetching: getCompanyAgreementsIsFetching,
    } = useCompanyAuthQuery({
        queryKey: queryKey,
        queryFn: () =>
            getCompanyAgreements(
                getEntityId(),
                {},
                { Authorization: getSessionToken() }
            ),
    });
    const agreements = useMemo(
        () => getCompanyAgreementsData?.agreements || [],
        [getCompanyAgreementsData?.agreements]
    );

    return {
        agreements,
        getCompanyAgreementsIsError,
        getCompanyAgreementsIsLoading,
        getCompanyAgreementsDataUpdatedAt,
        getCompanyAgreementsRefetch,
        getCompanyAgreementsIsFetching,
    };
};
