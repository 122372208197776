import { useRef, useEffect } from "react";
import { deepEqual } from "utils/objects";

const useDeepCompareEffect = (
    callback: React.EffectCallback,
    dependencies: any[]
) => {
    const currentDependenciesRef = useRef<any[]>([]);

    if (!deepEqual(currentDependenciesRef.current, dependencies)) {
        currentDependenciesRef.current = dependencies;
    }

    useEffect(callback, [currentDependenciesRef.current]);
};

export default useDeepCompareEffect;
