import styled from "styled-components";
import { properties as p, colorsDeprecated as c } from "global-style";
import Card from "components/Card";
import SelectWithIcon from "components/SelectWithIcon";
import Button from "components/Button";
import { selectArrow, selectArrowPurple } from "components/Select/styles";
import colors from "theme/colors";

export const WalletConnect = styled.div`
    display: flex;
    gap: 0.5rem;
    margin: 1rem 0;
    align-items: center;
    width: fit-content;
`;
export const WalletBtn = styled.ul`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin: 0;
`;
export const Logo = styled.div`
    height: 1.5rem;
    aspect-ratio: 1 / 1;
    img {
        height: 100%;
    }
`;
export const SettingChain = styled.div`
    line-height: 1;
    text-align: center;
`;
export const NetworkSelect = styled(SelectWithIcon)`
    flex-grow: 1;
`;
export const WalletSelect = styled.span`
    position: relative;
    flex-grow: 1;
`;
export const AddrSelected = styled.span`
    flex-grow: 1;
    text-align: start;
`;
export const WalletPanel = styled(Card)`
    position: absolute;
    z-index: ${p.zIndex.wallet};
    top: calc(100% + 0.25rem);
    right: 0;
    width: max-content;
    margin: 0;
    border: 1px solid ${c.border};

    h1 {
        font-size: 1.2rem;
    }
`;
export const CurrentWallet = styled(Button)`
    &::after {
        content: "";
        display: block;
        font-size: 0.75em;
        width: 1em;
        height: 1em;
        background-image: url("${selectArrow}");
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(0deg);
        transition: transform ${p.speed};
    }
    &:focus,
    &:hover {
        &::after {
            background-image: url("${selectArrowPurple}");
        }
    }
    &[open],
    &[open] ~ ${WalletPanel} {
        outline: ${p.focus};
    }
    &[open]::after {
        transform: rotate(180deg);
    }
`;

export const Wallets = styled.ul`
    margin-block: 0 1rem;
`;

export const Wallet = styled.li`
    padding-block: 1rem;
    &:not(:last-child) {
        border-bottom: 1px solid ${c.border};
    }
`;

export const WalletHeading = styled.h2`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-block: 0.25em !important;
    font-size: 1rem !important;
`;

export const WalletAccount = styled.p`
    margin-block: 0;
`;

export const SafeWallets = styled.section`
    margin-block-start: 0.5rem;
    padding-inline-start: 0.25rem;
`;

export const Address = styled(Button)`
    font-family: ${p.font.monospace};
    padding-inline: 0.25rem;
    border-radius: ${p.radiusSm};
    &:not([disabled]):hover {
        cursor: pointer;
        background-color: ${c.onTertiaryHover};
    }
    &[disabled],
    &[data-proxying="true"] {
        color: ${colors.primary};
    }
`;

export const WalletLogo = styled.img`
    height: 1.5rem;
    vertical-align: middle;
`;

export const SafeLogo = styled.img`
    height: 1rem;
    vertical-align: middle;
    margin: 0 0.125rem 0.2rem;
`;
