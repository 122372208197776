import * as S from "./styles";
import {
    Ref,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { CommonBlockchainNetworkResponse } from "api";
import { useGetSelfServeAcceptedTokens } from "hooks/useGetSelfServeAcceptedTokens";
import FailedDataFetchingMessage from "company/components/FailedDataFetchingMessage";
import LoadingBox from "components/LoadingBox";
import { useGetNetworks } from "hooks/useGetNetworks";
import { EntityInboundTreasuriesByNetworkId } from "company/types";
import EntityInboundTreasuryField, {
    EntityInboundTreasuryFieldRef,
} from "company/components/entities/EntityInboundTreasuryField";
import Anchor from "components/Anchor";
import SubSection from "components/SubSection";
import Field from "components/Field";
import { Spacing } from "theme/spacing";

export const LOOP_CRYPTO_CONTRACT_FEE = 1.5;
export const LOOP_CRYPTO_BASE_FEE = 0.05;

interface EntityInboundTreasuriesFieldsProps {
    onChange?: (inboundTreasuries: EntityInboundTreasuriesByNetworkId) => void;
    inboundTreasuriesNetworkIdsRequired: number[];
    itemName: string;
}

export type EntityInboundTreasuriesFieldsRef = {
    inboundTreasuries: EntityInboundTreasuriesByNetworkId;
    validate: () => boolean;
};

function EntityInboundTreasuriesFields(
    {
        onChange,
        inboundTreasuriesNetworkIdsRequired,
        itemName,
    }: EntityInboundTreasuriesFieldsProps,
    ref: Ref<EntityInboundTreasuriesFieldsRef>
) {
    // States
    const {
        acceptedNetworkIds,
        getSelfServeAcceptedTokensIsLoading,
        getSelfServeAcceptedTokensIsError,
    } = useGetSelfServeAcceptedTokens();

    const { networks } = useGetNetworks();

    const [inboundTreasuries, setInboundTreasuries] =
        useState<EntityInboundTreasuriesByNetworkId>({});

    const refs = useRef<EntityInboundTreasuryFieldRef[]>([]);

    const networksSorted = networks.reduce<{
        accepted: CommonBlockchainNetworkResponse[];
        notAccepted: CommonBlockchainNetworkResponse[];
    }>(
        (sorted, network) => {
            if (acceptedNetworkIds.includes(network.id)) {
                sorted.accepted.push(network);
            } else {
                sorted.notAccepted.push(network);
            }
            return sorted;
        },
        { accepted: [], notAccepted: [] }
    );

    const validate = useCallback(() => {
        if (refs.current.length === 0) return false;

        return refs.current.every((ref) => ref.validate());
    }, [refs]);

    const updateInboundTreasuries = useCallback(() => {
        let results: EntityInboundTreasuriesByNetworkId = {};
        refs.current
            .filter((ref) => ref.networkEnabled)
            .forEach((ref) => {
                results[ref.network.id] = ref.inboundTreasury;
            });
        setInboundTreasuries(results);
    }, [refs]);

    useImperativeHandle(
        ref,
        () => ({
            inboundTreasuries: inboundTreasuries,
            validate,
        }),
        [inboundTreasuries, validate]
    );

    useEffect(() => {
        validate();
        if (onChange) onChange(inboundTreasuries);
    }, [
        onChange,
        validate,
        inboundTreasuries,
        inboundTreasuriesNetworkIdsRequired,
    ]);

    return (
        <>
            {" "}
            <SubSection
                title={`Networks for "${itemName}" and future items`}
                description={`Payment will be directed to these addresses. Loop charges ${LOOP_CRYPTO_CONTRACT_FEE}% + $${LOOP_CRYPTO_BASE_FEE} on all one-time and subscription payments. Fees will be deducted at the time of payment.`}
            >
                <Field spacing={[Spacing.sm, Spacing.none]}>
                    {getSelfServeAcceptedTokensIsLoading ? (
                        <LoadingBox height="20rem" />
                    ) : getSelfServeAcceptedTokensIsError ? (
                        <FailedDataFetchingMessage />
                    ) : (
                        networksSorted.accepted.map((network, index) => (
                            <EntityInboundTreasuryField
                                network={network}
                                key={network.id}
                                required={inboundTreasuriesNetworkIdsRequired.includes(
                                    network.id
                                )}
                                onChange={updateInboundTreasuries}
                                ref={(el) =>
                                    el ? (refs.current[index] = el) : undefined
                                }
                            />
                        ))
                    )}
                </Field>
            </SubSection>
            {networksSorted.notAccepted.length && (
                <SubSection
                    title="Additional networks available"
                    description={
                        <>
                            <em>Want to make it even easier to get paid?</em>
                            {` `}
                            With a Premium account you can receive payment on
                            other networks, including{" "}
                            {networksSorted.notAccepted.map(
                                ({ name }, i, { length }) => (
                                    <>
                                        {i === 0
                                            ? ` `
                                            : i < length - 1
                                            ? `, `
                                            : ` and `}
                                        <b>{name}</b>
                                    </>
                                )
                            )}
                            .
                            <S.Contact>
                                {
                                    <Anchor
                                        href={`mailto:${
                                            import.meta.env.VITE_EMAIL_SUPPORT
                                        }`}
                                    >
                                        Contact us
                                    </Anchor>
                                }{" "}
                                after sign-up to upgrade your account to
                                Premium.
                            </S.Contact>
                        </>
                    }
                ></SubSection>
            )}
        </>
    );
}

export default forwardRef(EntityInboundTreasuriesFields);
