import { ethers } from "ethers";
import { simulateDelay } from "utils/debugging";

const deferredPromise = <T>(): [
    Promise<T>,
    (value: T) => void,
    (reason?: any) => void
] => {
    let resolve: (value: T) => void;
    let reject: (error?: any) => void;

    const promise: Promise<T> = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });

    promise.catch((error) => {
        return error;
    });

    return [promise, resolve!, reject!];
};

// Recursively attempt to call a function
type RetryMethod<T extends any[] = any[], R = any> = (...args: T) => Promise<R>;

interface RetryOptions {
    context?: any; // Provide context for functions that require scoped "this"
    error?: string; // Custom error message
}

const asyncCallWithRetries: <T extends any[], R>(
    method: RetryMethod<T, R>,
    delays: number[],
    args: T,
    options: RetryOptions
) => Promise<R> = async (method, delays, args, { error, context }) => {
    // Check for retries, throw an error if no more retries available
    if (!delays.length) return Promise.reject(error || `No result`);

    const [delay, ...remainingDelays] = delays;

    // Wait for the current delay
    await simulateDelay(delay);

    try {
        const result = context
            ? await method.apply(context, args)
            : await method(...args);

        // Result not found, try again
        if (!result) {
            return asyncCallWithRetries(method, remainingDelays, args, {
                error,
                context,
            });
        }

        // Result found, return it
        return result;
    } catch (err: any) {
        // Error occurred, try next delay
        return asyncCallWithRetries(method, remainingDelays, args, {
            error: err?.message || `Error`,
            context,
        });
    }
};

export { deferredPromise, asyncCallWithRetries };
