import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from "react";
import { AvailableNetwork } from "default-variables";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import { EthersExtension } from "@dynamic-labs/ethers-v5";
import {
    DynamicContextProps,
    useDynamicContext,
    useIsLoggedIn,
} from "@dynamic-labs/sdk-react-core";
import { SolanaWalletConnectors } from "@dynamic-labs/solana";
import WalletModal, { WalletModalRef } from "components/WalletModal";
import withDynamicContext from "context/Wallet/hoc/withDynamicContext";
import useWalletBalance, {
    GetUpdatedTokenBalanceProps,
    TokenBalanceProps,
    WalletTokenBalance,
} from "context/Wallet/hooks/useWalletBalance";
import useWalletConnected, {
    ConnectedWallet,
    PrimaryWalletAccount,
} from "context/Wallet/hooks/useWalletConnected";
import useWalletNetwork, {
    SetConnectedNetworkProps,
} from "context/Wallet/hooks/useWalletNetwork";
import useWalletSignature, {
    WalletSignerToken,
} from "context/Wallet/hooks/useWalletSignature";
import useWalletEmail from "context/Wallet/hooks/useWalletEmail";
import useWalletAllowance, {
    GetTokenAllowanceProps,
    SetTokenAllowanceProps,
    TokenAllowanceProps,
} from "context/Wallet/hooks/useWalletAllowance";
import { BigNumber } from "ethers";
import useWalletSafes, { SafeWallet } from "./hooks/useWalletSafes";

export interface LocalhostWalletEmail {
    wallet: string;
    email: string;
}

export interface WalletProviderValue {
    walletConnected: PrimaryWalletAccount | null;
    networkConnected: AvailableNetwork | null;
    walletsAvailable: ConnectedWallet[];
    safeWallet: Promise<SafeWallet | null>;
    isWalletModalOpen: boolean;
    isWalletConnecting: boolean;
    isLoggedIn: boolean;
    isWalletConnected: boolean;
    isNetworkSetting: boolean;
    handleConnectWallet: () => void;
    handleDisconnectWallet: () => Promise<void>;
    setPrimaryWallet: (wallet: any) => void;
    setProxyWallet: (proxyAddr: string | null) => any;
    setConnectedNetwork: (
        options: SetConnectedNetworkProps
    ) => Promise<boolean>;
    hasTokenBalanceStored: (options: TokenBalanceProps) => boolean;
    getTokenBalance: (
        options?: GetUpdatedTokenBalanceProps
    ) => Promise<WalletTokenBalance["balance"]>;
    hasTokenAllowanceStored: (options: TokenAllowanceProps) => boolean;
    getTokenAllowance: (options: GetTokenAllowanceProps) => Promise<BigNumber>;
    setTokenAllowance: (
        options: SetTokenAllowanceProps
    ) => Promise<BigNumber | null>;
    setTokenAllowanceIfInsufficient: (
        options: SetTokenAllowanceProps
    ) => Promise<BigNumber | null | false>;
    addToTokenAllowance: (
        options: SetTokenAllowanceProps
    ) => Promise<BigNumber | null>;
    setWalletEmail: (email: string) => void;
    getWalletEmail: () => string | null;
    getSignedMessage: () => Promise<WalletSignerToken | false>;
    generateTransferSignatureToken: (props: any) => Promise<any>;
    verifySignedMessage: (signature: string) => boolean | undefined;
}

const WalletContext = createContext<WalletProviderValue | undefined>(undefined);

interface WalletProviderProps {
    children: ReactNode;
}

/* # Notes

## On whether we need isWalletConnected:
Connecting, then refreshing, will first show that primaryWallet?.address is undefined,
so there is an instance in which you are connected, but the address is not yet available.
However, isLoggedIn, seems to be immediately up to date with the connection status.
So there may be value in converting the connectedWallet into a Promise that resolves to the
ConnectedWallet when the address is available, but will immediately know to resolve to null if 
isLoggedIn is false. This prevents having to skip processes that check isWalletConnected, and instead
await the connectedWallet to be ready before proceeding.

# On multiple addresses: 
- Seems to be holding the active wallet as connector.activeWallet, even though it's 
not the one necessarily connected.
- Does not seem to be storing multiple addresses that are connected to, into a wallet the way Onboard did
*/

/*  WalletProvider will re-render because a property of useDynamicContext updates when the window is refocused.
    Strictly managing the render conditions on properties handed down through the "value" of this context
    will prevent sub components from needlessly re-rendering. Just be sure to tightly control what triggers
    re-memoization of the "value" object and subcribing components and children will be impervious. */

const WalletProvider = ({ children }: WalletProviderProps) => {
    const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
    const walletModal = useRef<WalletModalRef>(null);

    // Dynamic hooks
    const isLoggedIn = useIsLoggedIn();
    // [ ] If an error occurs when connecting, even if connected, it seems like the checkout isn't getting past the "connect" button - must be this var

    const {
        handleLogOut,
        /* // [ ] Is "hangleLogOut" sufficient to "logout", or do wallets need to be disconnected? This is also available, but un-tested:
        handleDisconnectWallet: (walletId: string) => void  */
    } = useDynamicContext();

    // Custom hooks
    const {
        isWalletConnecting,
        wallet,
        walletsAvailable,
        setPrimaryWallet,
        setProxyWallet,
    } = useWalletConnected();
    const { network, isNetworkSetting, setConnectedNetwork } =
        useWalletNetwork();
    const { getTokenBalance, hasTokenBalanceStored } = useWalletBalance(
        wallet,
        network
    );
    const { setWalletEmail, getWalletEmail } = useWalletEmail(wallet);
    const {
        generateTransferSignatureToken, // [ ] No longer supported, consider removal
        getSignedMessage,
        verifySignedMessage,
    } = useWalletSignature(wallet, network);
    const {
        hasTokenAllowanceStored,
        getTokenAllowance,
        setTokenAllowance,
        addToTokenAllowance,
        setTokenAllowanceIfInsufficient,
    } = useWalletAllowance(wallet, network);
    const { safe } = useWalletSafes(wallet, network);

    const connectWallet = useCallback(() => {
        return walletModal.current ? walletModal.current.connect : () => {};
    }, []);

    const isWalletConnected = !!wallet?.address;

    const value = useMemo(
        (): WalletProviderValue => ({
            walletConnected: wallet,
            networkConnected: network,
            walletsAvailable,
            safeWallet: safe,
            isWalletModalOpen,
            isWalletConnecting,
            isWalletConnected,
            isLoggedIn,
            isNetworkSetting,
            handleConnectWallet: connectWallet(),
            handleDisconnectWallet: handleLogOut,
            setPrimaryWallet,
            setProxyWallet,
            setConnectedNetwork,
            hasTokenBalanceStored,
            getTokenBalance,
            hasTokenAllowanceStored,
            getTokenAllowance,
            setTokenAllowance,
            setTokenAllowanceIfInsufficient,
            addToTokenAllowance,
            setWalletEmail,
            getWalletEmail,
            getSignedMessage,
            generateTransferSignatureToken,
            verifySignedMessage,
        }),
        [
            wallet,
            network,
            safe,
            walletsAvailable,
            isWalletModalOpen,
            isWalletConnecting,
            isWalletConnected,
            isLoggedIn,
            isNetworkSetting,
            connectWallet,
            handleLogOut,
            setPrimaryWallet,
            setProxyWallet,
            setConnectedNetwork,
            hasTokenBalanceStored,
            getTokenBalance,
            hasTokenAllowanceStored,
            getTokenAllowance,
            setTokenAllowance,
            setTokenAllowanceIfInsufficient,
            addToTokenAllowance,
            setWalletEmail,
            getWalletEmail,
            getSignedMessage,
            generateTransferSignatureToken,
            verifySignedMessage,
        ]
    );

    return (
        <WalletContext.Provider value={value}>
            {children}
            <WalletModal
                ref={walletModal}
                onStateChange={setIsWalletModalOpen}
                isWalletConnecting={isWalletConnecting}
                isWalletConnected={isWalletConnected}
            />
        </WalletContext.Provider>
    );
};

const settings: DynamicContextProps["settings"] = {
    environmentId: import.meta.env.VITE_DYNAMIC_ENVIRONMENT_ID,
    walletConnectorExtensions: [EthersExtension],
    walletConnectors: [EthereumWalletConnectors, SolanaWalletConnectors],
    initialAuthenticationMode: "connect-only",
    enableVisitTrackingOnConnectOnly: false,
    privacyPolicyUrl: "https://loopcrypto.xyz/privacy-policy",
    termsOfServiceUrl: "https://loopcrypto.xyz/terms-of-service",
    onboardingImageUrl:
        "https://loop-entity-logos.s3.us-east-2.amazonaws.com/loop-crypto-long.svg",
};

export const WalletContextProvider = withDynamicContext(
    WalletProvider,
    settings
);

export const useWallet = (): WalletProviderValue => {
    const context = useContext(WalletContext);
    if (context === undefined) {
        throw new Error(`useWallet() must be used within a WalletProvider`);
    }
    return context;
};

export default WalletContextProvider;
