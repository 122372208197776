import * as S from "./style";
import safeLogo from "assets/img/logos/safe-wallet.svg";
import safeLogoWhite from "assets/img/logos/safe-wallet-white.svg";
import Anchor from "components/Anchor";
import Tooltip from "components/Tooltip";

const WalletConnectMultiSig = ({ ...props }) => {
    return (
        <S.SafeMultisig>
            <Tooltip
                title={
                    <S.SafeTip>
                        <p>
                            To connect and manage your{" "}
                            <S.SafeLogo
                                src={safeLogoWhite}
                                alt="Safe (wallet)"
                            />{" "}
                            Safe multisig wallet:
                        </p>
                        <ol>
                            <li>
                                Connect your EOA (externally owned account) and
                                sign the authentication message sent to your
                                wallet.
                            </li>
                            <li>
                                Ensure you are connected to the EOA wallet and
                                network that matches your multisig wallet.
                            </li>
                            <li>
                                Click the wallet address dropdown (in the
                                top-right corner) and select the Safe multisig
                                wallets you wish to manage.
                            </li>
                        </ol>
                        <p>
                            Read more about{" "}
                            <Anchor href={import.meta.env.VITE_SAFE_CUSTOMER}>
                                using Loop with your multisig wallet
                            </Anchor>{" "}
                            in our user guide
                        </p>
                    </S.SafeTip>
                }
                placement="bottom"
                abbr
                noWrap
            >
                Want to use a{" "}
                <Anchor
                    href="https://safe.global/"
                    underlined={false}
                    inheritColor={true}
                >
                    <S.SafeLogo src={safeLogo} alt="Safe (wallet)" />
                    Safe
                </Anchor>{" "}
                multisig wallet?
            </Tooltip>
        </S.SafeMultisig>
    );
};

export default WalletConnectMultiSig;
