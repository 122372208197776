import styled, { css } from "styled-components";
import { HintType } from ".";
import colors from "theme/colors";

const hintTypeStyles = {
    default: css`
        color: ${colors.text}; // Example text color
    `,
    info: css`
        color: ${colors.primary}; // Example text color: ;
    `,
    success: css`
        color: ${colors.success};
    `,
    warning: css`
        color: ${colors.warning};
    `,
    error: css`
        color: ${colors.error};
    `,
};

export const Hint = styled.small<{ type: HintType }>`
    font-style: italic;
    display: block;
    ${(props) => hintTypeStyles[props.type]};
`;
