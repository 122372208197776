import { useCallback, useEffect, useState } from "react";
import { AuthorizationTableRow, Customer } from "customer/types";
import { ItemCategoryTypeInbound, AgreementType } from "types/common-enums";
import { formatDateTimeFromSeconds, strFrequency } from "utils/datetime";
import { toDollar } from "utils/financial";
import { firstToUpper } from "utils/strings";
import { useEntityFamilies } from "customer/hooks/useEntityFamilies";
import Tooltip from "components/Tooltip";
import CancelAuthorization from "customer/components/CancelAuthorization";

export interface EntityAuthorizations {
    entity: string;
    records: AuthorizationTableRow[];
}

export interface AuthorizationsByEntity {
    (entityId: string): AuthorizationTableRow[] | undefined;
}

interface Authorizations {
    (
        entities: Customer.Entity[],
        agreements: Customer.Agreement[],
        allItems: Customer.Item[],
        tokens: Customer.Token[]
    ): {
        entityAuthorizations: EntityAuthorizations[];
        getAuthorizationRecordsByEntity: AuthorizationsByEntity;
    };
}

const useAuthorizations: Authorizations = (
    entities,
    agreements,
    allItems,
    tokens
) => {
    const [entityAuthorizations, setEntityAuthorizations] = useState<
        EntityAuthorizations[]
    >([]);
    const families = useEntityFamilies(entities);

    useEffect(() => {
        // Build the authorization table
        const allFamilies = families.map<EntityAuthorizations>(
            ({ parent }) => ({
                entity: parent,
                records: [],
            })
        );

        setEntityAuthorizations(
            agreements.reduce<EntityAuthorizations[]>(
                (
                    authorizations,
                    { id, entity, items, token, status, endDate, createdAt }
                ) => {
                    // Agreement item that: (1) exists, (2) is "inbound"
                    const agreementItems = allItems
                        .filter(
                            ({ id, type }) =>
                                items.includes(id) &&
                                ItemCategoryTypeInbound.includes(type)
                        )
                        .sort((a, b) => (a.name < b.name ? -1 : 1));

                    // If there are no items for this agreement that pass all criteria, don't create a row
                    if (agreementItems.length < 1) return authorizations;

                    const itemNames = agreementItems
                        .map(({ name }) => firstToUpper(name))
                        .join(`, `);

                    const itemFreq = agreementItems
                        .filter(({ frequency }) => !!frequency)
                        .map(
                            ({ frequency, name }, i, arr) =>
                                frequency
                                    ? arr.length === 1
                                        ? strFrequency(
                                              frequency.value,
                                              frequency.type
                                          )
                                        : `${strFrequency(
                                              frequency.value,
                                              frequency.type
                                          )} (${name})`
                                    : `` // This case won't happen, but TS doesn't seem to realize that
                        )
                        .join(`,`);

                    const itemAmount = agreementItems.reduce<number>(
                        (sum, { amount }) => sum + amount,
                        0
                    );

                    const symbol = tokens.find(
                        ({ address }) =>
                            address.toLowerCase() === token.toLowerCase()
                    )?.symbol;

                    const amountInvoicedLabel = (
                        <>
                            {toDollar(itemAmount)} USD
                            {symbol ? (
                                <>
                                    <br />
                                    in {symbol}
                                </>
                            ) : (
                                ``
                            )}
                        </>
                    );
                    const amountInvoicedText = `${toDollar(itemAmount)} USD${
                        symbol ? ` in ${symbol}` : ``
                    }`;

                    const headOfTheFamily = families.find(
                        ({ parent, children }) =>
                            entity === parent || children.includes(entity)
                    )?.parent;
                    if (!headOfTheFamily) return authorizations;

                    const parentAuthorization = authorizations.find(
                        ({ entity }) => entity === headOfTheFamily
                    );
                    if (!parentAuthorization) return authorizations;

                    const agreementEntity = entities.find(
                        ({ entityId }) => entityId === entity
                    );

                    // Append the entity name before the item(s) if not the same as the authorization parent (ie, a child)
                    const nameColumn =
                        agreementEntity?.entityId !== headOfTheFamily
                            ? `${agreementEntity?.name} - ${itemNames}`
                            : itemNames;

                    const manageCol: Field =
                        status === AgreementType[AgreementType.One_Time]
                            ? { label: ``, value: status, text: `` }
                            : status === AgreementType[AgreementType.Canceled]
                            ? {
                                  label: (
                                      <Tooltip
                                          title={
                                              <>
                                                  Canceled:
                                                  <br />
                                                  {formatDateTimeFromSeconds(
                                                      endDate
                                                  )}
                                              </>
                                          }
                                      >
                                          <span>Canceled</span>
                                      </Tooltip>
                                  ),
                                  value: status,
                                  text: `Canceled: ${formatDateTimeFromSeconds(
                                      endDate
                                  )}`,
                                  style: { textAlign: `center` },
                              }
                            : {
                                  label: (
                                      <CancelAuthorization
                                          id={id}
                                          name={itemNames}
                                          entity={
                                              agreementEntity?.name ||
                                              `the company`
                                          }
                                      />
                                  ),
                                  value: status,
                                  text: `Cancel`,
                                  style: { textAlign: `center` },
                              };

                    parentAuthorization.records.push({
                        id: {
                            label: id,
                            value: id,
                            text: id,
                        },
                        itemName: {
                            label: nameColumn,
                            value: nameColumn,
                            text: nameColumn,
                        },
                        frequency: {
                            label: itemFreq,
                            value: itemFreq,
                            text: itemFreq,
                        },
                        invoiced: {
                            label: amountInvoicedLabel,
                            value: itemAmount,
                            text: amountInvoicedText,
                            style: { textAlign: `right` },
                        },
                        invoicedAmt: {
                            label: toDollar(itemAmount),
                            value: toDollar(itemAmount),
                            text: toDollar(itemAmount),
                        },
                        createdAt: {
                            label: formatDateTimeFromSeconds(createdAt),
                            value: createdAt,
                            text: formatDateTimeFromSeconds(createdAt),
                        },
                        invoicedToken: {
                            label: symbol || ``,
                            value: symbol || ``,
                            text: symbol || ``,
                        },
                        canceled: {
                            label:
                                status === AgreementType[AgreementType.Canceled]
                                    ? `Yes`
                                    : ``,
                            value:
                                status === AgreementType[AgreementType.Canceled]
                                    ? `Yes`
                                    : ``,
                            text:
                                status === AgreementType[AgreementType.Canceled]
                                    ? `Yes`
                                    : ``,
                        },
                        status: { ...manageCol },
                    });

                    return authorizations;
                },
                [...allFamilies]
            )
        );
    }, [agreements, families]);

    const getAuthorizationRecordsByEntity: AuthorizationsByEntity = useCallback(
        (entityId) => {
            const headOfTheFamily = families.find(
                ({ parent, children }) =>
                    entityId === parent || children.includes(entityId)
            )?.parent;

            return entityAuthorizations.find(
                ({ entity }) => entity === headOfTheFamily
            )?.records;
        },
        [families, entityAuthorizations]
    );

    return {
        entityAuthorizations,
        getAuthorizationRecordsByEntity,
    };
};

export { useAuthorizations };
