import * as S from "company/components/CustomersTable/styles";
import { useState, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
    CompanyAgreementResponse,
    CustomerTableRow,
    ItemSourceType,
} from "company/types";
import {
    ItemCategoryTypeInbound,
    AgreementType,
    ItemCategoryType,
} from "types/common-enums";
import {
    formatDateTimeFromSeconds,
    strFrequency,
    SECONDS_IN_DAY,
} from "utils/datetime";
import { firstToUpper } from "utils/strings";
import Dropdown from "components/Dropdown";
import DropdownItem from "components/DropdownItem";
import TokenTableCell from "company/components/TokenTableCell";
import Badge from "components/Badge";
import VerticalDots from "components/icons/VerticalDots";
import CancelAccountForm from "company/components/CancelAccountForm";
import BalanceAndAllowanceCell from "components/BalanceAndAllowanceCell";
import DynamicWalletAddressDisplay from "components/DynamicWalletAddress/DynamicWalletAddressDisplay";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import { useGetCompanyAgreements } from "./useGetCompanyAgreements";
import { useGetCompanyItems } from "./useGetCompanyItems";
import { useEns } from "contexts/EnsProvider";
import { UserRole, useUser } from "context/User";
import { useModal } from "context/ModalProvider";
import { PaymentPlatformUrls } from "company/utils/entities";
import { useGetCompanyConfig } from "./useGetCompanyConfig";
import Anchor from "components/Anchor";

interface FormatCustomers {
    (): {
        customers: CustomerTableRow[];
        customersIsLoading: boolean;
        customersIsError: boolean;
        customersIsFetching: boolean;
    };
}

const useFormatCustomers: FormatCustomers = () => {
    const navigate = useNavigate();
    const { hasRole } = useUser();

    const [customers, setCustomers] = useState<CustomerTableRow[]>([]);
    const { openModal, closeModal } = useModal();
    const { getEnsRecord } = useEns();

    const { tokens, getTokensMetadataIsError, getTokensMetadataIsLoading } =
        useGetTokensMetadata();

    const { networks, getNetworksIsLoading, getNetworksIsError } =
        useGetNetworks();

    const {
        items,
        getCompanyItemsIsError,
        getCompanyItemsIsLoading,
        getCompanyItemsIsFetching,
    } = useGetCompanyItems();

    const {
        agreements,
        getCompanyAgreementsIsLoading,
        getCompanyAgreementsIsError,
        getCompanyAgreementsIsFetching,
    } = useGetCompanyAgreements();

    const customersIsLoading =
        getTokensMetadataIsLoading ||
        getNetworksIsLoading ||
        getCompanyItemsIsLoading ||
        getCompanyAgreementsIsLoading;

    const customersIsError =
        getTokensMetadataIsError ||
        getNetworksIsError ||
        getCompanyItemsIsError ||
        getCompanyAgreementsIsError;

    const customersIsFetching =
        getCompanyItemsIsFetching || getCompanyAgreementsIsFetching;

    const canManage = hasRole(UserRole.COMPANY);
    const {
        config: { entities },
    } = useGetCompanyConfig();

    // TODO: inboundItemsIds and inboundAgreements should be combined into a single reduce()
    // Check out src/company/components/InvoiceForm/AgreementItems.tsx for example
    // Likely abstract into a hook that all components can use
    const inboundAgreements = useMemo(() => {
        const inboundItemsIds = items
            .filter((item) => ItemCategoryTypeInbound.includes(item.type))
            .map((item) => item.id);
        return agreements.filter((agreement) =>
            inboundItemsIds.includes(agreement.items[0])
        );
    }, [items, agreements]);

    const formatAgreement = useCallback(
        (agreement: CompanyAgreementResponse) => {
            const entity = entities.find(
                (entity) => entity.entityId === agreement.entity
            );

            const paymentPlatformUrls = new PaymentPlatformUrls(
                entity?.paymentPlatformProvider,
                entity?.externalSite
            );

            const network = networks.find(
                (network) => network.id === agreement.networkId
            );

            const token = tokens.find(
                (token) =>
                    token.address === agreement.token &&
                    token.networkId === agreement.networkId
            );
            const tokenNetwork =
                token &&
                networks.find((network) => network.id === token.networkId);

            const agreementItems = items.filter((item) =>
                agreement.items.includes(item.id)
            );

            const agreementCanceled =
                agreement.status === AgreementType[AgreementType.Canceled];

            const agreementScheduleCancel =
                agreement.status ===
                AgreementType[AgreementType.Scheduled_Cancel];

            const canCreateInvoice =
                canManage &&
                !agreementCanceled &&
                agreement.type !== `Static` &&
                agreementItems.every(
                    (item) => item.sourceId === ItemSourceType.Loop
                );

            const canCancelAgreement =
                canManage &&
                !agreementCanceled &&
                agreementItems.every(
                    (item) => item.type !== ItemCategoryType.One_Time
                );

            // ! RP: This looks sketchy to me - this should be handled better
            if (!token || !network || !agreementItems.length || !tokenNetwork) {
                return { id: agreement.id, values: [] };
            }

            const itemsList = (
                <S.ItemList>
                    {agreementItems.map((item) => (
                        <li key={item.id}>
                            <b>{item.name}</b>{" "}
                            <em>
                                {item.frequency?.value ||
                                item.frequency?.value === 0
                                    ? strFrequency(
                                          item.frequency.value,
                                          item.frequency.type
                                      )
                                    : ""}
                            </em>
                        </li>
                    ))}
                </S.ItemList>
            );

            const itemNames = agreementItems
                .map((item) => item.name)
                .join(", ");

            const frequencyString = agreementItems
                .map((item) =>
                    item.frequency?.value || item.frequency?.value === 0
                        ? strFrequency(
                              item.frequency.value,
                              item.frequency.type
                          )
                        : ""
                )
                .join(", ");

            const createdAtDateTime = new Date(agreement.createdAt * 1000);

            const showNewBadge =
                agreement.createdAt > Date.now() / 1000 - SECONDS_IN_DAY * 2 &&
                !agreementCanceled;

            const senderEnsName = getEnsRecord(agreement.sender.wallet)?.name;

            const handleCancelAccountFormModal = () => {
                if (!token) return;
                openModal(
                    <CancelAccountForm
                        onClose={closeModal}
                        onComplete={closeModal}
                        agreementId={agreement.id}
                        createdAt={agreement.createdAt}
                        senderWallet={agreement.sender.wallet}
                        senderEmail={agreement.sender.email}
                        items={agreementItems}
                        token={token}
                        network={network}
                    />,
                    `Cancel Agreement`
                );
            };

            const hasSubscription = agreementItems.some(
                (item) => item.type === ItemCategoryType.Subscription
            );
            const subscriptionLink =
                agreement.externalId && hasSubscription ? (
                    <Anchor
                        href={paymentPlatformUrls.subscriptionUrl(
                            agreement.externalId
                        )}
                        target="_blank"
                    >
                        {agreement.externalId}
                    </Anchor>
                ) : (
                    `-`
                );

            return {
                id: {
                    label: agreement.id,
                    value: agreement.id,
                    text: agreement.id,
                },
                sender: {
                    label: (
                        <S.FlexContainer>
                            <div>
                                <DynamicWalletAddressDisplay
                                    address={agreement.sender.wallet}
                                    ensName={senderEnsName}
                                    networkId={network?.hexId}
                                    shorten
                                    icon
                                    iconFill="currentColor"
                                />
                                <p>{agreement.sender.email}</p>
                            </div>
                            {showNewBadge && <Badge variant="green">New</Badge>}
                        </S.FlexContainer>
                    ),
                    value: `${agreement.sender.wallet}, ${agreement.sender.email}`,
                    text: `${agreement.sender.wallet}, ${agreement.sender.email}`,
                },
                senderAddress: {
                    label: agreement.sender.wallet || ``,
                    value: agreement.sender.wallet || ``,
                    text: agreement.sender.wallet || ``,
                },
                senderEmail: {
                    label: agreement.sender.email || ``,
                    value: agreement.sender.email || ``,
                    text: agreement.sender.email || ``,
                },
                externalId: {
                    label: subscriptionLink,
                    value: agreement.externalId || `-`,
                    text: agreement.externalId || `-`,
                },
                item: {
                    label: itemsList,
                    value: itemNames,
                    text: itemNames,
                },
                frequency: {
                    label: frequencyString,
                    value: frequencyString,
                    text: frequencyString,
                },
                network: {
                    label: firstToUpper(tokenNetwork.name),
                    value: firstToUpper(tokenNetwork.name),
                    text: firstToUpper(tokenNetwork.name),
                },
                startDate: {
                    label: formatDateTimeFromSeconds(agreement.createdAt),
                    value: createdAtDateTime.getTime(),
                    text: formatDateTimeFromSeconds(agreement.createdAt),
                },
                token: {
                    label: <TokenTableCell token={token} />,
                    value: token.name,
                    text: token.name,
                },
                allowanceAndBalance: {
                    label: (
                        <BalanceAndAllowanceCell
                            token={token}
                            walletAddress={agreement.sender.wallet}
                        />
                    ),
                    value: "Get Data",
                    text: "Get Data",
                },
                manage: {
                    label: (
                        <>
                            {agreementCanceled ? (
                                <S.CanceledDate>
                                    Canceled:
                                    <br />
                                    {formatDateTimeFromSeconds(
                                        agreement.endDate
                                    )}
                                </S.CanceledDate>
                            ) : agreementScheduleCancel &&
                              agreement.cancellationEffectiveDate ? (
                                <S.CanceledDate>
                                    Scheduled Cancel:
                                    <br />
                                    {formatDateTimeFromSeconds(
                                        agreement.cancellationEffectiveDate
                                    )}
                                </S.CanceledDate>
                            ) : canCreateInvoice || canCancelAgreement ? (
                                <Dropdown
                                    anchorEl={
                                        <a
                                            href="#menu"
                                            onClick={(event) =>
                                                event.preventDefault()
                                            }
                                        >
                                            <VerticalDots
                                                width="1rem"
                                                height="1rem"
                                                fill="#888"
                                            />
                                        </a>
                                    }
                                >
                                    {canCreateInvoice && (
                                        <DropdownItem
                                            onClick={() =>
                                                navigate(
                                                    `/invoice/${agreement.id}`
                                                )
                                            }
                                        >
                                            Invoice
                                        </DropdownItem>
                                    )}
                                    {canCancelAgreement && (
                                        <DropdownItem
                                            onClick={
                                                handleCancelAccountFormModal
                                            }
                                        >
                                            Cancel
                                        </DropdownItem>
                                    )}
                                </Dropdown>
                            ) : (
                                <></>
                            )}
                        </>
                    ),
                    value: "Manage",
                    text: "Manage",
                    style: { textAlign: "center" },
                },
            };
        },
        [canManage, getEnsRecord, items, navigate, networks, tokens]
    );

    useEffect(() => {
        if (customersIsLoading || customersIsError) return;
        // @ts-ignore
        setCustomers(inboundAgreements.map(formatAgreement) || []);
    }, [
        formatAgreement,
        inboundAgreements,
        customersIsLoading,
        customersIsError,
    ]);

    return {
        customers,
        customersIsLoading,
        customersIsError,
        customersIsFetching,
    };
};

export { useFormatCustomers };
