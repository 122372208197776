import { useCallback } from "react";
import { ethers } from "ethers";
import { tokenABI } from "default-variables";
import { useWallet } from "context/Wallet";
import { EvmWalletSigner } from "types/common";

// [ ] Review this code (replace try/catch)
// [ ] Can this just replace "getContract" in the other hooks?

const useContract = () => {
    const { walletConnected, isWalletConnected } = useWallet();

    const getErc20Contract = useCallback(
        ({ tokenIn }: { tokenIn: string }) => {
            let tokenContract;
            if (!walletConnected || !isWalletConnected) {
                throw new Error(`Wallet is not ready for contract interaction`);
            }
            try {
                // TODO: Save to a useState to avoid multiple instantiation?
                tokenContract = new ethers.Contract(
                    tokenIn,
                    tokenABI,
                    walletConnected.signer as EvmWalletSigner
                );
            } catch (error) {
                throw new Error(
                    `There was a problem connecting to the token's smart contract`
                );
            }

            return tokenContract;
        },
        [walletConnected, isWalletConnected]
    );

    return { getErc20Contract };
};

export { useContract };
