import CheckoutFooter from "checkout/components/CheckoutFooter";
import { useCheckoutData } from "checkout/context/CheckoutData";
import { CheckoutFormProvider } from "checkout/context/CheckoutForm";
import { DetailsManagerProvider } from "context/DetailsManager";
import { sizes } from "global-style";
import { memo, useEffect, useState } from "react";
import CheckoutConnectWallet from "checkout/components/CheckoutConnectWallet";
import CheckoutPanels from "checkout/components/CheckoutPanels";
import * as S from "./style";
import { NetworkAndTokenProvider } from "checkout/context/NetworkAndToken";
import { useWallet } from "context/Wallet";

export enum CheckoutPanel {
    PAYMENT = "Payment",
    CONTACT = "Contact",
    APPROVE = "Checkout",
}

export const panelSummaries = {
    [CheckoutPanel.PAYMENT]: {
        idle: memo(() => (
            <S.Summary>
                <S.Idle>1</S.Idle>Payment
            </S.Summary>
        )),
        complete: memo(() => (
            <S.Summary>
                <S.Complete>
                    <S.Checkmark />
                </S.Complete>
                Payment
            </S.Summary>
        )),
    },
    [CheckoutPanel.CONTACT]: {
        idle: memo(() => (
            <S.Summary>
                <S.Idle>2</S.Idle>Contact
            </S.Summary>
        )),
        complete: memo(() => (
            <S.Summary>
                <S.Complete>
                    <S.Checkmark />
                </S.Complete>
                Contact
            </S.Summary>
        )),
    },
    [CheckoutPanel.APPROVE]: {
        idle: memo(() => (
            <S.Summary>
                <S.Idle>3</S.Idle>Checkout
            </S.Summary>
        )),
        complete: memo(() => (
            <S.Summary>
                <S.Complete>
                    <S.Checkmark />
                </S.Complete>
                Checkout
            </S.Summary>
        )),
    },
};

const CartAndForm = () => {
    const { queryParams } = useCheckoutData();

    const [windowWidth, setWindowWidth] = useState<number>(0);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        setWindowWidth(window.innerWidth);

        window.addEventListener("resize", handleResize);
    }, [setWindowWidth]);

    const isMobile = windowWidth < parseFloat(sizes.md);

    const { walletConnected } = useWallet();
    return (
        <S.Checkout showCart={queryParams.cartEnabled}>
            <DetailsManagerProvider>
                <CheckoutFormProvider>
                    <NetworkAndTokenProvider>
                        {queryParams.cartEnabled && (
                            <S.Details>
                                <S.ItemizedCart />
                                {!isMobile && (
                                    <CheckoutFooter
                                        showCart={queryParams.cartEnabled}
                                    />
                                )}
                            </S.Details>
                        )}
                        <S.Steps>
                            {!walletConnected ? (
                                <S.WalletConnectWrapper>
                                    <S.GridContent>
                                        <CheckoutConnectWallet />
                                    </S.GridContent>
                                </S.WalletConnectWrapper>
                            ) : (
                                <S.GridContent>
                                    <CheckoutPanels />
                                </S.GridContent>
                            )}
                        </S.Steps>
                        {(!queryParams.cartEnabled || isMobile) && (
                            <CheckoutFooter
                                showCart={queryParams.cartEnabled}
                            />
                        )}
                    </NetworkAndTokenProvider>
                </CheckoutFormProvider>
            </DetailsManagerProvider>
        </S.Checkout>
    );
};

export default CartAndForm;
