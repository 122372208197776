import { useEffect, useState } from "react";
import { CheckoutItemResponse } from "api/types/checkout";
import { BaseItem } from "types/common";
import {
    suggestedAllowanceAmountForCheckout,
    calculateSuggestedAllowance,
} from "utils/checkout";

export const useSuggestedAllowance = (
    invoiceAmount: number | undefined,
    items: CheckoutItemResponse[] | BaseItem[],
    minimumBalanceRequired: number | undefined,
    defaultSpendingCap: number | undefined
) => {
    const [suggestedAllowance, setSuggestedAllowance] = useState<number>(0);

    useEffect(() => {
        setSuggestedAllowance(
            suggestedAllowanceAmountForCheckout(
                calculateSuggestedAllowance(
                    invoiceAmount,
                    items,
                    minimumBalanceRequired,
                    defaultSpendingCap
                )
            )
        );
    }, [invoiceAmount, items, minimumBalanceRequired, defaultSpendingCap]);

    return suggestedAllowance;
};
