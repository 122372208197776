import * as S from "customer/routes/Transactions/style";
import { useState, useMemo } from "react";
import { ethers } from "ethers";
import { TransferStatus } from "types/common-enums";
import { Customer, TransactionTableRow } from "customer/types";
import { strFrequency, getDateTimeFromSeconds } from "utils/datetime";
import { toDollar, toCoin } from "utils/financial";
import { firstToUpper } from "utils/strings";
import { BlockExplorerEntityType } from "utils/urls";
import Anchor from "components/Anchor";
import Tooltip from "components/Tooltip";
import { Attention } from "company/routes/Transactions/style";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import Badge from "components/Badge";

interface FormatTransactions {
    (
        paymentHistory: Customer.Transaction[],
        upcomingPayments: Customer.Transaction[],
        tokens: Customer.Token[],
        entities: Customer.Entity[],
        items: Customer.Item[],
        agreements: Customer.Agreement[],
        networks: Customer.Network[]
    ): {
        upcoming: TransactionTableRow[];
        confirmed: TransactionTableRow[];
        due: TransactionTableRow[];
    };
}

const useFormatTransactions: FormatTransactions = (
    paymentHistory,
    upcomingPayments,
    tokens,
    entities,
    items,
    agreements,
    networks
) => {
    const [upcoming, setUpcoming] = useState<TransactionTableRow[]>([]);
    const [confirmed, setConfirmed] = useState<TransactionTableRow[]>([]);
    const [due, setDue] = useState<TransactionTableRow[]>([]);

    const formatTransaction = ({
        agreementId,
        amount,
        billDate,
        invoiceId,
        payment,
        status,
        usd,
    }: Customer.Transaction): TransactionTableRow | never => {
        // Join "agreements" objects
        const {
            entity,
            items: itemIds = [],
            networkId,
            token = ``,
        } = agreements.find(
            ({ id }: Customer.Agreement) => agreementId === id
        ) || {};

        const network = networks.find((n) => n.id === networkId);

        // Join "token" objects
        const { decimals = 0, symbol = `` } =
            tokens.find(
                ({ address }: Customer.Token) =>
                    token.toUpperCase() === address.toUpperCase()
            ) || {};

        // Join "entity" objects
        const { name: entityName = ``, parentId } =
            entities.find(
                ({ entityId }: Customer.Entity) => entity === entityId
            ) || {};

        // Find parent entity if it exists
        // Note, this just looks at the direct parent entity
        const { name: parentName = `` } = parentId
            ? entities.find(
                  ({ entityId }: Customer.Entity) => parentId === entityId
              ) || {}
            : {};
        const authorizationNames = parentName ? (
            <>
                {entityName} (
                <Anchor href={`/authorizations/details/${parentId}`}>
                    {parentName}
                </Anchor>
                )
            </>
        ) : (
            <Anchor href={`/authorizations/details/${entity}`}>
                {entityName}
            </Anchor>
        );

        // Aggregate all "items"
        const allItems = itemIds.reduce((names: any, itemId: string) => {
            const item = items.find(({ id }: Customer.Item) => id === itemId);
            if (!item) return names;

            return [
                ...names,
                `${firstToUpper(item?.name || ``)}${` / ${
                    item?.frequency
                        ? strFrequency(
                              item.frequency.value,
                              item.frequency.type
                          )
                        : ``
                }`}`,
            ];
        }, []);

        const itemsAndFreq = itemIds.reduce(
            (together: any, itemId: string) => {
                const item = items.find(
                    ({ id }: Customer.Item) => id === itemId
                );
                if (!item) return together;

                return {
                    items: [...together.items, firstToUpper(item.name)],
                    frequencies: [
                        ...together.frequencies,
                        strFrequency(
                            item.frequency?.value,
                            item.frequency?.type
                        ),
                    ],
                };
            },
            { items: [], frequencies: [] }
        );

        // Payment details
        const {
            paidAmount = `0`,
            processedAt = 0,
            transactionHash = null,
        } = payment ?? {};

        // Paid date
        const {
            date: atDate,
            time: atTime,
            zone: atZone,
        } = getDateTimeFromSeconds(processedAt, true);

        // Billed date
        const {
            date: forDate,
            time: forTime,
            zone: forZone,
        } = getDateTimeFromSeconds(billDate, true);

        // If this invoice is in USD, convert to dollar (2 decimal), otherwise, toCoin
        const amountInvoicedLabel = usd ? (
            <>
                {toDollar(amount)}
                {symbol ? (
                    <>
                        <br />
                        in {symbol}
                    </>
                ) : (
                    ``
                )}
            </>
        ) : decimals === 0 && !symbol ? (
            ``
        ) : (
            <>
                {toCoin(
                    Number(ethers.utils.formatUnits(String(amount), decimals))
                )}
                <br />
                {symbol}
            </>
        );
        const amountInvoicedText = usd
            ? `${toDollar(amount)}${symbol ? ` in ${symbol}` : ``}`
            : decimals === 0 && !symbol
            ? ``
            : `${toCoin(
                  Number(ethers.utils.formatUnits(String(amount), decimals))
              )} ${symbol}`;

        const uniquePaidAmount = paidAmount
            ? `${toCoin(
                  Number(ethers.utils.formatUnits(String(paidAmount), decimals))
              )} ${symbol}`
            : usd
            ? `${toDollar(amount)}${symbol ? ` in ${symbol}` : ``}`
            : ``;

        // Join "agreements" objects
        return {
            id: {
                label: `${billDate}_${invoiceId}_${uniquePaidAmount}`,
                value: `${billDate}_${invoiceId}_${uniquePaidAmount}`,
                text: `${billDate}_${invoiceId}_${uniquePaidAmount}`,
            },
            dateDue: {
                label: (
                    <S.SplitCol>
                        <span>
                            {forDate}
                            <br />
                            <S.NoWrap>
                                {forTime} {forZone}
                            </S.NoWrap>
                        </span>
                        {status === TransferStatus.Draft ? (
                            <Tooltip title="Draft payments are not processed until they are finalized. Payments typically finalize on their due date.">
                                <span>
                                    <Badge variant="purple">Draft</Badge>
                                </span>
                            </Tooltip>
                        ) : (
                            ``
                        )}
                    </S.SplitCol>
                ),
                value: billDate,
                text: `${forDate} ${forTime} ${forZone}`,
            },
            draftStatus: {
                label: status === TransferStatus.Draft ? `Yes` : ``,
                value: status === TransferStatus.Draft ? `Yes` : ``,
                text: status === TransferStatus.Draft ? `Yes` : ``,
            },
            datePaid: {
                label: (
                    <>
                        {atDate}
                        <br />
                        <S.NoWrap>
                            {atTime} {atZone}
                        </S.NoWrap>
                    </>
                ),
                value: processedAt,
                text: `${atDate} ${atTime} ${atZone}`,
            },
            invoiced: {
                label: amountInvoicedLabel,
                value: amount,
                text: amountInvoicedText,
                style: { textAlign: `right` },
            },
            billedInUsd: {
                label: usd ? `Yes` : ``,
                value: usd ? `Yes` : ``,
                text: usd ? `Yes` : ``,
            },
            invoicedAmt: {
                label: usd
                    ? toDollar(amount)
                    : toCoin(
                          Number(
                              ethers.utils.formatUnits(String(amount), decimals)
                          )
                      ),
                value: usd
                    ? toDollar(amount)
                    : toCoin(
                          Number(
                              ethers.utils.formatUnits(String(amount), decimals)
                          )
                      ),
                text: usd
                    ? toDollar(amount)
                    : toCoin(
                          Number(
                              ethers.utils.formatUnits(String(amount), decimals)
                          )
                      ),
            },
            invoicedToken: {
                label: symbol,
                value: symbol,
                text: symbol,
            },
            paid: {
                label: (
                    <>
                        {status === TransferStatus.Failed ? (
                            <Tooltip
                                title={
                                    <>
                                        While this transaction <em>was</em>
                                        {` `}
                                        processed on-chain, the invoice{` `}
                                        <em>was not</em>
                                        {` `}
                                        fulfilled. Please send another request
                                        with a new invoice# if you would like
                                        this transaction to be processed.{` `}
                                        <Anchor
                                            href={
                                                import.meta.env
                                                    .VITE_LOOP_DOCS_FAILED_TX
                                            }
                                            inheritColor={true}
                                        >
                                            Learn more
                                        </Anchor>
                                        .
                                    </>
                                }
                                placement="top"
                            >
                                <Attention>Not fulfilled</Attention>
                            </Tooltip>
                        ) : (
                            <>
                                {paidAmount
                                    ? `${toCoin(
                                          Number(
                                              ethers.utils.formatUnits(
                                                  String(paidAmount),
                                                  decimals
                                              )
                                          )
                                      )} ${symbol}`
                                    : `-`}{" "}
                            </>
                        )}
                        {transactionHash && network ? (
                            <>
                                <br />
                                <DynamicAddressDisplay
                                    address={transactionHash}
                                    networkId={network.hexId}
                                    type={BlockExplorerEntityType.Transaction}
                                    shorten
                                    icon
                                    iconFill="currentColor"
                                >
                                    Tx
                                </DynamicAddressDisplay>
                            </>
                        ) : (
                            ``
                        )}
                    </>
                ),
                value: paidAmount ?? 0,
                text: `${
                    paidAmount
                        ? toCoin(
                              Number(
                                  ethers.utils.formatUnits(
                                      String(paidAmount),
                                      decimals
                                  )
                              )
                          )
                        : `-`
                } ${symbol}${transactionHash ? ` (${transactionHash})` : ``}`,
                style: {
                    whiteSpace: `nowrap`,
                    textAlign: `right`,
                },
            },
            paidAmt: {
                label:
                    status === TransferStatus.Failed
                        ? `Not fulfilled`
                        : paidAmount
                        ? toCoin(
                              Number(
                                  ethers.utils.formatUnits(
                                      String(paidAmount),
                                      decimals
                                  )
                              )
                          )
                        : ``,
                value:
                    status === TransferStatus.Failed
                        ? `Not fulfilled`
                        : paidAmount
                        ? toCoin(
                              Number(
                                  ethers.utils.formatUnits(
                                      String(paidAmount),
                                      decimals
                                  )
                              )
                          )
                        : ``,
                text:
                    status === TransferStatus.Failed
                        ? `Not fulfilled`
                        : paidAmount
                        ? toCoin(
                              Number(
                                  ethers.utils.formatUnits(
                                      String(paidAmount),
                                      decimals
                                  )
                              )
                          )
                        : ``,
            },
            txHash: {
                label: transactionHash || ``,
                value: transactionHash || ``,
                text: transactionHash || ``,
            },
            entity: {
                label: entity,
                value: entity,
                text: entity || ``,
            },
            authorization: {
                label: authorizationNames,
                value: entityName,
                text: `${entityName}${parentName ? ` (${parentName})` : ``}`,
            },
            item: {
                label: allItems.join(`, `),
                value: allItems.join(`, `),
                text: allItems.join(`, `),
                style: { expand: true },
            },
            itemName: {
                label: itemsAndFreq.items.join(`, `),
                value: itemsAndFreq.items.join(`, `),
                text: itemsAndFreq.items.join(`, `),
            },
            frequency: {
                label: itemsAndFreq.frequencies.join(`, `),
                value: itemsAndFreq.frequencies.join(`, `),
                text: itemsAndFreq.frequencies.join(`, `),
            },
            invoice: {
                label: invoiceId ? (
                    invoiceId.length > 8 ? (
                        <Tooltip title={invoiceId} placement="left">
                            <span>{invoiceId.slice(0, 8).trim()}&hellip;</span>
                        </Tooltip>
                    ) : (
                        invoiceId
                    )
                ) : (
                    `-`
                ),
                value: invoiceId ?? ``,
                text: `${invoiceId ?? `-`}`,
            },
            status: {
                label: status || ``,
                value: status || ``,
                text: status || ``,
            },
        };
    };

    // Reformat all of the transaction data
    useMemo(() => {
        const pastTx = paymentHistory.reduce(
            (tx: any, row: Customer.Transaction) => {
                const formattedTx = formatTransaction(row);
                return {
                    confirmed:
                        formattedTx?.status?.value !== TransferStatus.Cancelled
                            ? [...tx.confirmed, formattedTx]
                            : tx.confirmed,
                };
            },
            {
                confirmed: [],
            }
        );

        const futureTx = upcomingPayments.reduce(
            (tx: any, row: Customer.Transaction) => {
                const formattedTx = formatTransaction(row);
                return {
                    // Not cancelled && not due
                    // Not cancelled && due
                    upcoming:
                        formattedTx?.status?.value !==
                            TransferStatus.Cancelled &&
                        formattedTx.dateDue.value * 1000 >= Date.now()
                            ? [...tx.upcoming, formattedTx]
                            : tx.upcoming,
                    due:
                        formattedTx?.status?.value !==
                            TransferStatus.Cancelled &&
                        formattedTx.dateDue.value * 1000 < Date.now()
                            ? [...tx.due, formattedTx]
                            : tx.due,
                };
            },
            {
                upcoming: [],
                due: [],
            }
        );

        setConfirmed(pastTx.confirmed);
        setUpcoming(futureTx.upcoming);
        setDue(futureTx.due);
    }, [paymentHistory, upcomingPayments]);

    return { confirmed, upcoming, due };
};

export { useFormatTransactions };
