import styled from "styled-components";
import { sizes as s } from "global-style";
import RefreshCounter from "components/RefreshCounter";
import Button from "components/Button";

export const TransactionHeader = styled.header`
    margin-block-end: 2rem;
`;

export const DetailsRow = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${s.md}) {
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        h2 {
            margin-inline-end: 2rem;
            margin-block: 0.5rem;
        }
    }
`;

export const Refresh = styled(RefreshCounter)`
    grid-column: span 2;
`;

export const CancelBtn = styled(Button)`
    margin-block: 0.5rem;
`;
