import {
    DynamicContextProps,
    DynamicContextProvider,
} from "@dynamic-labs/sdk-react-core";

const withDynamicContext = (
    Component: React.ComponentType<any>,
    settings: DynamicContextProps["settings"]
) => {
    return (props: any) => (
        <DynamicContextProvider settings={settings}>
            <Component {...props} />
        </DynamicContextProvider>
    );
};

export default withDynamicContext;
