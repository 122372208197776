import {
    createContext,
    ReactNode,
    useState,
    useContext,
    useMemo,
    Dispatch,
    SetStateAction,
    useEffect,
} from "react";
import { firstToUpper } from "utils/strings";
import { useCheckoutData } from "checkout/context/CheckoutData";
import { useCheckoutForm } from "checkout/context/CheckoutForm";
import { SelectOption } from "components/Select";
import { CheckoutNetwork, CheckoutToken } from "checkout/types";
import { useWallet } from "context/Wallet";
import { hasMultipleUniqueValues } from "utils/arrays";

interface NetworkAndTokenProps {
    children: ReactNode;
}

interface NetworkAndTokenValue {
    networkOptions: SelectOption<string>[];
    networkHexId: string;
    updateNetworkHexId: (network: string) => Promise<void>;
    tokenOptions: SelectOption<string>[];
    tokenAddress: string;
    setTokenAddress: Dispatch<SetStateAction<string>>;
    selectedNetwork?: CheckoutNetwork;
    selectedToken?: CheckoutToken;
}

const NetworkAndTokenContext = createContext<NetworkAndTokenValue>(
    {} as NetworkAndTokenValue
);

const getNetworkIfAvailable = (
    connectedOn: string | undefined,
    networks: CheckoutNetwork[]
) => {
    return connectedOn &&
        networks.find(({ hexId, chain }) => hexId === connectedOn)
        ? connectedOn
        : ``;
};

const NetworkAndTokenProvider = ({ children }: NetworkAndTokenProps) => {
    const { walletConnected, networkConnected, setConnectedNetwork } =
        useWallet();
    const {
        tokens,
        networks,
        totalPricePerCommonToken,
        usdTotalWithoutDiscount,
    } = useCheckoutData();
    const { updateNetwork, updateToken } = useCheckoutForm();

    const [networkHexId, setNetworkHexId] = useState<string>(
        getNetworkIfAvailable(networkConnected?.networkId, networks)
    );
    const [tokenAddress, setTokenAddress] = useState<string>(``);

    const [availableNetworks, setAvailableNetworks] = useState<
        CheckoutNetwork[]
    >([]);

    const [networkOptions, setNetworkOptions] = useState<
        SelectOption<string>[]
    >([]);

    // Load token dropdown options
    const tokenOptions = useMemo(() => {
        return tokens
            .filter(
                ({ networkId, address }) =>
                    networkId === parseInt(networkHexId) &&
                    // If no USD total, only show tokens with prices
                    (usdTotalWithoutDiscount !== null ||
                        totalPricePerCommonToken.some(
                            (commonToken) =>
                                commonToken.network === networkId &&
                                commonToken.address === address
                        ))
            )
            .map(({ symbol, address }) => ({
                value: address,
                label: symbol,
            }));
    }, [
        tokens,
        networkHexId,
        totalPricePerCommonToken,
        usdTotalWithoutDiscount,
    ]);

    useEffect(() => {
        // If no USD total, only show networks with token prices
        setAvailableNetworks(
            usdTotalWithoutDiscount === null
                ? networks.filter(({ id }) =>
                      totalPricePerCommonToken.some(
                          (commonToken) => commonToken.network === id
                      )
                  )
                : networks
        );
    }, [networks, totalPricePerCommonToken, usdTotalWithoutDiscount]);

    useEffect(() => {
        const isMultiChain = hasMultipleUniqueValues(
            availableNetworks,
            "chain",
            1
        );

        // Sort options on the chain the wallet is connected to to the top
        if (isMultiChain) {
            availableNetworks.sort((a, b) => {
                if (a.chain === walletConnected?.chain) return -1;
                if (b.chain === walletConnected?.chain) return 1;
                return 0;
            });
        }

        setNetworkOptions(
            availableNetworks.map(({ hexId, name, chain }) => ({
                value: hexId,
                label: `${firstToUpper(name)}${
                    chain !== walletConnected?.chain
                        ? ` (Switch to compatible wallet)`
                        : ``
                }`,
                disabled: chain !== walletConnected?.chain,
            }))
        );
    }, [availableNetworks, walletConnected?.chain]);

    // When wallet connects/changes, set the network dropdown to match the wallet's network
    useEffect(() => {
        if (!walletConnected?.address || !networkConnected?.networkId) return;

        const hexId = getNetworkIfAvailable(
            networkConnected.networkId,
            availableNetworks
        );

        setNetworkHexId(hexId);
    }, [
        walletConnected?.address,
        networkConnected?.networkId,
        availableNetworks,
    ]);

    const updateNetworkHexId = async (updatedNetworkHexId: string) => {
        if (!walletConnected) {
            setNetworkHexId(updatedNetworkHexId);
            return Promise.resolve();
        }

        setConnectedNetwork({ networkId: updatedNetworkHexId }).then(
            (didChange: boolean) => {
                // If wallet wasn't changed, update the network dropdown to match the network
                setNetworkHexId(
                    didChange
                        ? updatedNetworkHexId
                        : getNetworkIfAvailable(
                              networkConnected?.networkId,
                              availableNetworks
                          )
                );
                return Promise.resolve();
            }
        );
    };

    // When network updates, notify the form provider
    useEffect(() => {
        updateNetwork(networkHexId);
    }, [networkHexId, updateNetwork]);

    // When token updates, notify the form provider
    useEffect(() => {
        updateToken(tokenAddress);
    }, [tokenAddress, networkHexId, updateToken]);

    const selectedNetwork = networks.find(
        (network) => network.hexId === networkHexId
    );
    const selectedToken = tokens.find(
        (token) =>
            token.address === tokenAddress &&
            token.networkId === selectedNetwork?.id
    );

    return (
        <NetworkAndTokenContext.Provider
            value={{
                networkOptions,
                networkHexId,
                updateNetworkHexId,
                tokenOptions,
                tokenAddress,
                setTokenAddress,
                selectedNetwork,
                selectedToken,
            }}
        >
            {children}
        </NetworkAndTokenContext.Provider>
    );
};

const useNetworkAndToken = (): NetworkAndTokenValue => {
    const context = useContext(NetworkAndTokenContext);
    if (context === undefined) {
        throw new Error(
            `useNetworkAndToken() must be used within a NetworkAndTokenProvider`
        );
    }
    return context;
};

export { NetworkAndTokenProvider, useNetworkAndToken };
