import * as S from "./styles";
import { useEffect, useState, useCallback, FunctionComponent } from "react";
import { formateDateForUrl } from "utils/datetime";
import { hasManageHeading, rowsToTableRecords } from "utils/tables";
import { CustomerTableRow } from "company/types";
import { useFormatCustomers } from "company/hooks/useFormatCustomers";
import Anchor from "components/Anchor";
import Button from "components/Button";
import DownloadCsv from "components/DownloadCsv";
import Table from "components/Table";
import ArrowDownright from "components/icons/ArrowDownright";
import Section from "components/Section";
import RefreshCounter, { CounterType } from "components/RefreshCounter";
import LoadingBox, { LoadingPlaceholderStyle } from "components/LoadingBox";
import FailedDataFetchingMessage from "../FailedDataFetchingMessage";
import { useGetCompanyAgreements } from "company/hooks/useGetCompanyAgreements";
import { UserRole, useUser } from "context/User";
import EmptyTableMessage from "components/EmptyTableMessage";

// Table properties
const sort: SortBy = {
    column: 3,
    isAscending: false,
};
const pagination: Pagination = {
    perPage: 25,
    page: 1,
};

// Defines which columns end up in the table
const tableHeadings: RowHeading<CustomerTableRow>[] = [
    { label: "Sender", field: "sender", sortable: true },
    { label: "Item", field: "item", sortable: true },
    { label: "Subscription ID", field: "externalId", sortable: true },
    { label: "Start Date", field: "startDate", sortable: true },
    { label: "Token", field: "token", sortable: true },
    { label: "Status", field: "allowanceAndBalance" },
];

// Defines which columns end up in the CSV
const csvHeadings: RowHeading<CustomerTableRow>[] = [
    { label: "Sender Address", field: "senderAddress" },
    { label: "Sender Email", field: "senderEmail" },
    { label: "Start Date", field: "startDate" },
    { label: "Subscription ID", field: "externalId", sortable: true },
    { label: "Item", field: "item" },
    { label: "Frequency", field: "frequency" },
    { label: "Token", field: "token" },
    { label: "Network", field: "network" },
];

interface CustomersTableProps {}

const CustomersTable: FunctionComponent<CustomersTableProps> = () => {
    // Hooks
    const { hasRole } = useUser();

    const {
        agreements,
        getCompanyAgreementsDataUpdatedAt,
        getCompanyAgreementsRefetch,
    } = useGetCompanyAgreements();

    const {
        customers,
        customersIsLoading,
        customersIsError,
        customersIsFetching,
    } = useFormatCustomers();

    // React variables
    const [searchResults, setSearchResults] = useState<Tuple[]>([]);

    // Local variables
    const canManage = hasRole(UserRole.COMPANY);

    // Effects
    useEffect(() => {
        if (canManage && !hasManageHeading(tableHeadings)) {
            tableHeadings.push({
                label: "Manage",
                field: "manage",
                style: { shrink: true, textAlign: "center" },
            });
        }
    }, [canManage]);

    useEffect(() => {
        if (!customers || customersIsLoading || customersIsError) return;
        setSearchResults(rowsToTableRecords(customers, tableHeadings));
    }, [customers, customersIsError, customersIsLoading]);

    // Callbacks
    const handleSortTable = useCallback(
        (sort: any) => {
            searchResults.sort(sort);
        },
        [searchResults]
    );

    if (customersIsError) return <FailedDataFetchingMessage />;
    if (customersIsLoading)
        return (
            <LoadingBox
                placeholderStyle={LoadingPlaceholderStyle.Table}
                tablePlaceholderCols={tableHeadings.length}
            />
        );

    return (
        <>
            {agreements.length > 0 && (
                <Section>
                    <S.Filters>
                        <Button href="/invoice" disabled={!canManage}>
                            <ArrowDownright
                                fill="white"
                                width="0.7rem"
                                height="0.7rem"
                            />
                            <span>Invoice</span>
                        </Button>

                        <S.Download>
                            <DownloadCsv
                                filename={`customers_${formateDateForUrl()}.csv`}
                                columns={csvHeadings}
                                rows={rowsToTableRecords(
                                    customers,
                                    csvHeadings
                                )}
                            />
                        </S.Download>
                    </S.Filters>
                </Section>
            )}
            <Section>
                <RefreshCounter
                    refreshedAt={getCompanyAgreementsDataUpdatedAt}
                    counter={CounterType.Running}
                    onRefresh={getCompanyAgreementsRefetch}
                    refreshing={customersIsFetching}
                />
                <Table
                    data={{
                        headings: tableHeadings,
                        records: searchResults,
                    }}
                    defaultSort={sort}
                    pagination={pagination}
                    refetching={customersIsFetching}
                    onSort={handleSortTable}
                    ifNoRecords={
                        <EmptyTableMessage
                            title="No customers to display yet"
                            description={
                                <>
                                    Customers are wallets that have given
                                    permission to the smart contract to spend.
                                    These are "from" wallets in the payment
                                    request. <br />
                                    Need help? Take a look at our{" "}
                                    <Anchor
                                        href={
                                            import.meta.env
                                                .VITE_LOOP_DOCS_TOOLS_COMPANY_ACCOUNTS
                                        }
                                    >
                                        documentation
                                    </Anchor>{" "}
                                    or{" "}
                                    <Anchor
                                        href={`mailto:${
                                            import.meta.env.VITE_EMAIL_SUPPORT
                                        }`}
                                    >
                                        contact us
                                    </Anchor>
                                    .
                                </>
                            }
                        />
                    }
                />
            </Section>
        </>
    );
};

export default CustomersTable;
