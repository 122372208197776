import { ethers } from "ethers";
import { regex } from "utils/regex";

// Series of helper function that leverage native browser validation:
// https://caniuse.com/?search=setCustomValidity
// https://caniuse.com/?search=reportValidity

type GenericValidateInputProps = {
    valid: boolean;
    input: HTMLInputElement | HTMLTextAreaElement;
    reportValidity?: boolean;
    errorMessage?: string;
};

// Generic input validation function
export const validateInput = ({
    valid,
    input,
    reportValidity = true,
    errorMessage = " ",
}: GenericValidateInputProps) => {
    if (valid) {
        input.setCustomValidity("");
    } else {
        input.setCustomValidity(errorMessage);

        // Avoid showing error while editing
        if (reportValidity) {
            input.reportValidity();
        }
    }

    return valid;
};

type ValidateInputProps = {
    input: HTMLInputElement | HTMLTextAreaElement;
    reportValidity?: boolean;
    errorMessage?: string;
};

// Specific validation
export const validateWalletAddress = ({
    input,
    reportValidity = true,
    errorMessage = "Please enter a valid wallet address",
}: ValidateInputProps) => {
    const valid = ethers.utils.isAddress(input.value);
    validateInput({
        valid,
        input,
        reportValidity,
        errorMessage,
    });
    return valid;
};

// Specific validation
export const validateEnsAddress = ({
    input,
    reportValidity = true,
    errorMessage = "Please enter a valid ENS domain",
}: ValidateInputProps) => {
    const valid = input.value.endsWith(".eth");
    validateInput({
        valid,
        input,
        reportValidity,
        errorMessage,
    });
    return valid;
};

export const validateUrl = ({
    input,
    reportValidity = true,
    errorMessage = "Please enter a valid url, beginning with http:// or https://",
}: ValidateInputProps) => {
    const valid = regex.url.test(input.value);

    validateInput({
        valid,
        input,
        reportValidity,
        errorMessage,
    });

    return valid;
};

// Specific validation
export const validateEmailAddress = ({
    input,
    reportValidity = true,
    errorMessage = "Please enter a valid email address",
}: ValidateInputProps) => {
    // Regular expression for email validation

    const valid = regex.email.test(input.value);

    validateInput({
        valid,
        input,
        reportValidity,
        errorMessage,
    });

    return valid;
};

// Specific validation
export const validatePositiveNumber = ({
    input,
    reportValidity = true,
    errorMessage = "Please enter a positive amount",
}: ValidateInputProps) => {
    const valid = input.value.trim().length > 0 && parseFloat(input.value) > 0;
    validateInput({
        valid,
        input,
        reportValidity,
        errorMessage,
    });
    return valid;
};

// Specific validation
export const validateZeroOrHigher = ({
    input,
    reportValidity = true,
    errorMessage = "Please enter a number that's 0 or higher",
}: ValidateInputProps) => {
    const valid = input.value.trim().length > 0 && parseFloat(input.value) >= 0;
    validateInput({
        valid,
        input,
        reportValidity,
        errorMessage,
    });
    return valid;
};

// Specific validation
export const validatePresence = ({
    input,
    reportValidity = true,
    errorMessage = " ",
}: ValidateInputProps) => {
    const valid = input.value.trim().length > 0;
    validateInput({
        valid,
        input,
        reportValidity,
        errorMessage,
    });
    return valid;
};

// Specific validation
type ValidateStringLengthProps = ValidateInputProps & {
    min?: number;
    max?: number;
};
export const validateStringLength = ({
    input,
    reportValidity = true,
    errorMessage = " ",
    min,
    max,
}: ValidateStringLengthProps) => {
    let valid = true;
    let stringLength = input.value.trim().length;

    if (max && stringLength > max) {
        valid = false;
        validateInput({
            valid,
            input,
            reportValidity,
            errorMessage: errorMessage || `Must be shorter than ${max}`,
        });
    }

    if (min && stringLength < min) {
        valid = false;
        validateInput({
            valid,
            input,
            reportValidity,
            errorMessage: errorMessage || `Must be longer than ${min}`,
        });
    }

    return valid;
};

export const validateDateTimeLocal = ({
    input,
    reportValidity = true,
    errorMessage = "Please enter a valid date",
}: ValidateInputProps) => {
    const date = new Date(input.value.trim());
    // @ts-ignore
    const valid = input.value.trim().length > 0 && date !== "Invalid Date";

    validateInput({
        valid,
        input,
        reportValidity,
        errorMessage,
    });
    return valid;
};

type ValidateDateTimeRangeProps = ValidateInputProps & {
    min?: Date;
    max?: Date;
};

export const validateDateTimeRange = ({
    input,
    reportValidity = true,

    min,
    max,
    errorMessage,
}: ValidateDateTimeRangeProps) => {
    // initially valid the date
    let valid = validateDateTimeLocal({
        input,
        reportValidity,
    });

    if (!valid) return valid;

    const date = new Date(input.value.trim());

    if (max && date > max) {
        valid = false;
        validateInput({
            valid,
            input,
            reportValidity,
            errorMessage: errorMessage || "Date is too far in the past",
        });
    }

    if (min && date < min) {
        valid = false;
        validateInput({
            valid,
            input,
            reportValidity,
            errorMessage: errorMessage || "Date is far in the future",
        });
    }
    return valid;
};

export const validateUsDollars = ({
    input,
    reportValidity = true,
    errorMessage = "Please enter a valid dollar amount",
}: ValidateInputProps) => {
    const valid = regex.dollars.test(input.value.trim());
    validateInput({
        valid,
        input,
        reportValidity,
        errorMessage,
    });

    return valid;
};

type ValidateAmountRangeProps = Omit<ValidateInputProps, `errorMessage`> & {
    min?: number;
    max?: number;
    errorMessages?: {
        high?: string;
        low?: string;
    };
};

// Specific validation
export const validateAmountRangeInCents = ({
    input,
    reportValidity = true,
    min,
    max,
    errorMessages = {},
}: ValidateAmountRangeProps) => {
    let valid = true;

    const hasValue = input.value.trim().length;
    if (!hasValue) valid = false;

    const value = parseFloat(input.value) * 100;

    if (valid && (max || max === 0) && value > max) {
        valid = false;
        validateInput({
            valid,
            input,
            reportValidity,
            errorMessage:
                errorMessages?.high || `Amount is too high (max: ${max})`,
        });
    }

    if (valid && (min || min === 0) && value < min) {
        valid = false;
        validateInput({
            valid,
            input,
            reportValidity,
            errorMessage:
                errorMessages?.low || `Amount is too low (min: ${min})`,
        });
    }
    return valid;
};

type ValidateWithFunction = ValidateInputProps & {
    validateFunction: (value: any) => boolean;
};
export const validateWithFunction = ({
    input,
    reportValidity = true,
    validateFunction,
    errorMessage = "Please enter a valid value",
}: ValidateWithFunction) => {
    const valid = validateFunction(input.value);
    validateInput({
        valid,
        input,
        reportValidity,
        errorMessage,
    });

    return valid;
};
