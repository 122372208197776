import {
    CommonBlockchainNetworkResponse,
    GeneralTokenDetailsResponse,
} from "api";
import {
    Company,
    ItemSourceType,
    itemSourceForCheckout,
    itemSourceForDisplay,
    itemSourceForInvoice,
} from "company/types";
import { ItemCategoryType, ItemCategoryTypeOutbound } from "types/common-enums";
import { onlyUnique } from "utils/arrays";
import { isVariablePricing } from "utils/items";

/* Types */

export enum ItemNetworkDisabledReason {
    SelfServeUpgradeRequired = "self_serve_upgrade_required",
    MissingContract = "missing_contract",
}

export type ItemNetwork = {
    network: CommonBlockchainNetworkResponse;
    acceptedTokens: GeneralTokenDetailsResponse[];
    selectedTokens: GeneralTokenDetailsResponse[];
    inboundTreasuryAddress?: string;
    showInboundTreasuryAddress?: boolean;
    disabled: boolean;
    disabledReason?: ItemNetworkDisabledReason;
};

// These differs slightly for Company,
export function itemIsSubscription(item: Company.Item) {
    return (
        item.type === ItemCategoryType.Subscription &&
        item.frequency.value !== 0
    );
}

export function itemIsOneTimePayment(item: Company.Item) {
    return (
        !ItemCategoryTypeOutbound.includes(item.type) &&
        item.frequency.value === 0
    );
}

export function itemIsActive(item: Company.Item) {
    return item.active;
}

export function getCommonAcceptedTokensByNetwork(
    items: Company.Item[]
): Company.AcceptedTokensByNetwork {
    if (!items || items.length === 0) return {};

    // use first item as "base" accepted token to compare against
    const [firstItem, ...otherItems] = items;

    // Structured clone will ensure we don't mess with the original object
    const commonAcceptedTokensByNetwork = {
        ...firstItem.acceptedTokensByNetwork,
    };

    // to avoid unnecessary loops over unaccapted network ids later
    const acceptedNetworkIds = Object.keys(commonAcceptedTokensByNetwork).map(
        (n) => Number(n)
    );

    // Compare with other items
    otherItems.forEach((item) => {
        // Loop
        acceptedNetworkIds.forEach((networkId) => {
            // Get *this* item's network's token addresses
            const itemNetworkToken = item.acceptedTokensByNetwork[networkId];

            // Get the *base* item's network's token addresses
            const commonNetworkTokens =
                commonAcceptedTokensByNetwork[networkId] || [];

            // Find the matching tokens
            const matchingTokens =
                itemNetworkToken?.filter((value) =>
                    commonNetworkTokens.includes(value)
                ) || [];

            // Remove the networkId key if no matching tokens are found
            if (matchingTokens.length === 0) {
                delete commonAcceptedTokensByNetwork[networkId];
            } else {
                // Or set the matching token
                commonAcceptedTokensByNetwork[networkId] = matchingTokens;
            }
        });
    });

    return commonAcceptedTokensByNetwork;
}

// [checkoutUrl]/[entityId]
export function canInvoiceItem(item: Company.Item) {
    return itemIsActive(item) && itemSourceForInvoice.includes(item.sourceId);
}

// [checkoutUrl]/[entityId]/[itemId]
export function canCheckoutItem(item: Company.Item) {
    return itemIsActive(item) && itemSourceForCheckout.includes(item.sourceId);
}

// Fun time: Can you checkout with one or multiple items?
export function canCheckoutItems(items: Company.Item[]) {
    const commonAcceptedTokensByNetwork =
        getCommonAcceptedTokensByNetwork(items);

    // 1. Needs some item(s) to checkout, Duh!
    if (items.length === 0) {
        return {
            canCheckout: false,
            reason: "Need at least one item to checkout",
            commonAcceptedTokensByNetwork,
        };
    }

    // 2. item(s) needs to be on the same source
    const itemsSourceIds = items
        .map((item) => item.sourceId)
        .filter(onlyUnique);

    if (itemsSourceIds.length !== 1) {
        return {
            canCheckout: false,
            reason: "You can only checkout items from the same source",
            commonAcceptedTokensByNetwork,
        };
    }

    // 3. Items needs to belong to same entity
    const itemsEntityIds = items
        .map((item) => item.entityId)
        .filter(onlyUnique);
    if (itemsEntityIds.length !== 1) {
        return {
            canCheckout: false,
            reason: "You can only checkout items from the same entity",
            commonAcceptedTokensByNetwork,
        };
    }

    // By this point in the logic there should be only one, unique source
    const itemSourceId = itemsSourceIds[0];

    // 4. item(s) source can be be used for checkout
    const validSourceForCheckout = itemSourceForCheckout.includes(itemSourceId);
    if (!validSourceForCheckout) {
        return {
            canCheckout: false,
            reason: `You cannot checkout ${itemSourceForDisplay[itemSourceId]} items`,
            commonAcceptedTokensByNetwork,
        };
    }

    // 5. There are common accepted tokens between all items
    const hasCommonAcceptedTokens =
        Object.keys(commonAcceptedTokensByNetwork).length > 0;
    if (!hasCommonAcceptedTokens) {
        return {
            canCheckout: false,
            reason: `Items must have at least one common token on the same network`,
            commonAcceptedTokensByNetwork,
        };
    }

    // 6. Cannot subscribe Stripe Subscription with different frequencies
    const sourceIsStripe = itemSourceId === ItemSourceType.Stripe;
    const itemSubscriptions = items.filter(itemIsSubscription);

    if (sourceIsStripe && itemSubscriptions.length > 1) {
        const uniqueFrequencyTypes = itemSubscriptions
            .map((item) => item.frequency.type)
            .filter(onlyUnique);
        const uniqueFrequencyValues = itemSubscriptions
            .map((item) => item.frequency.value)
            .filter(onlyUnique);

        const validFrequency =
            uniqueFrequencyTypes.length === 1 &&
            uniqueFrequencyValues.length === 1;

        if (!validFrequency) {
            return {
                canCheckout: false,
                reason: `Can only checkout items with the same frequency`,
                commonAcceptedTokensByNetwork,
            };
        }
    }

    // TODO: Chargebee?

    return {
        canCheckout: true,
        reason: "",
        commonAcceptedTokensByNetwork,
    };
}

/*
    Policy
*/

export function canEditItemItemName(item: Company.Item) {
    const canEdit = item.sourceId === ItemSourceType.Loop;
    return canEdit;
}

// You can only edit variable/fixed pricing if the item is Loop and not autoInvoice
export function canEditItemPricingModel(item: Company.Item) {
    // Item should be active
    if (!item.active) return false;

    // Can only change pricing model on Loop item
    if (item.sourceId !== ItemSourceType.Loop) return false;

    // A autoInvoice subscription cannot change the pricing model
    if (itemIsSubscription(item) && item.autoInvoice) return false;

    return true;
}

export function canEditItemAmount(item: Company.Item) {
    const canEdit = item.sourceId === ItemSourceType.Loop;
    return canEdit;
}

export function canEditItemPriceMetadata(item: Company.Item) {
    return true;
}

export function canEditItemFrequency(item: Company.Item) {
    const canEdit = item.sourceId === ItemSourceType.Loop;
    return canEdit;
}

export function canEditItemInitialOffset(
    item: Company.Item,
    autoInvoice: boolean
) {
    // Can always edit stripe fre trials
    const isStripe = item.sourceId === ItemSourceType.Stripe;
    if (isStripe) return true;

    // if Loop, can only edit free trials on autoInvoice items
    const isLoop = item.sourceId === ItemSourceType.Loop;
    const canEdit = isLoop && autoInvoice;

    return canEdit;
}

export function canEditItemAcceptedTokensByNetwork(item: Company.Item) {
    return true;
}

// You can only edit autoInvoice if item is loop manual
export function canEditItemAutoInvoice(item: Company.Item) {
    // Item should be active
    if (!item.active) return false;

    // Can only change autoInvoice on Loop item
    if (item.sourceId !== ItemSourceType.Loop) return false;

    // A subscription update from auto invoice to manual
    if (itemIsSubscription(item) && item.autoInvoice) return false;

    // A one time payment cannot from manual to autoInvoice
    if (itemIsOneTimePayment(item) && !item.autoInvoice) return false;

    if (isVariablePricing(item.amount)) return false;

    return true;
}
