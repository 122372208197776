import React from "react";
import * as S from "./styles";
import { SpacingTrack } from "theme/spacing";

interface FieldProps {
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    spacing?: SpacingTrack;
}

// Note: useful to apply styles to field children like label and inputs
export const Field: React.FunctionComponent<FieldProps> = ({
    children,
    className,
    disabled = false,
    spacing,
}) => {
    return (
        <S.Field className={className} disabled={disabled} spacing={spacing}>
            {children}
        </S.Field>
    );
};

export default Field;
