import Section from "components/Section";
import InvoiceForm from "company/components/InvoiceForm";
import { useParams } from "react-router-dom";
import LoadingBox from "components/LoadingBox";
import FailedDataFetchingMessage from "company/components/FailedDataFetchingMessage";
import { useGetCompanyAgreements } from "company/hooks/useGetCompanyAgreements";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";

const Invoice = () => {
    const { agreementId } = useParams();

    const { getCompanyItemsIsError, getCompanyItemsIsLoading } =
        useGetCompanyItems();

    const {
        agreements,
        getCompanyAgreementsIsLoading,
        getCompanyAgreementsIsError,
    } = useGetCompanyAgreements();

    const agreement = agreements.find((a) => a.id === agreementId);

    if (getCompanyItemsIsLoading || getCompanyAgreementsIsLoading) {
        return <LoadingBox />;
    }
    if (getCompanyItemsIsError || getCompanyAgreementsIsError) {
        return <FailedDataFetchingMessage />;
    }
    return (
        <Section>
            <InvoiceForm agreement={agreement} />
        </Section>
    );
};

export default Invoice;
