import { reactAppBuildType } from "default-variables";

const envIdentifier = `${
    import.meta.env.VITE_BUILD_TARGET
}.${reactAppBuildType}.`;

interface Cookie {
    key: string;
    value: string;
    expiration: Date;
}

export const setCookie = (cookies: Cookie[]) => {
    cookies.forEach(({ key, value, expiration }: Cookie) => {
        document.cookie = `${envIdentifier}${key}=${encodeURIComponent(
            value || ``
        )}; expires=${expiration.toUTCString()}; path=/`;
    });
};

export const getCookie = (key: Cookie[`key`]) => {
    return decodeURIComponent(
        document.cookie
            .split("; ")
            .find((row) => row.startsWith(`${envIdentifier}${key}`))
            ?.split("=")[1] || ``
    );
};

interface LocalStorage<T> {
    key: string;
    value: T;
}

export const setLocalStorageItem = <T = any,>({
    key,
    value,
}: LocalStorage<T>) => {
    try {
        window.localStorage.setItem(
            `${envIdentifier}${key}`,
            JSON.stringify(value)
        );
    } catch (error) {
        console.error(
            `Error setting localStorage item ${key} for environment ${envIdentifier}`,
            error
        );
    }
};

export const getLocalStorageItem = <T = any,>(
    key: LocalStorage<T>[`key`]
): T | null => {
    try {
        const storedItem = window.localStorage.getItem(
            `${envIdentifier}${key}`
        );
        return storedItem === null ? null : (JSON.parse(storedItem) as T);
    } catch (error) {
        console.error(
            `Error getting localStorage item ${key} for environment ${envIdentifier}`,
            error
        );
        return null;
    }
};
