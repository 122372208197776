import { useRef, useCallback } from "react";
import { deepEqual } from "utils/objects";

const useDeepCompareCallback = <T extends (...args: any[]) => any>(
    callback: T,
    dependencies: any[]
): T => {
    const previousDependenciesRef = useRef<any[]>(dependencies);
    const callbackRef = useRef(callback);

    // Update the callback ref if the dependencies have deeply changed
    if (!deepEqual(previousDependenciesRef.current, dependencies)) {
        previousDependenciesRef.current = dependencies;
        callbackRef.current = callback;
    }

    // Memoize the callback and return the latest version
    return useCallback(
        ((...args: any[]) => callbackRef.current(...args)) as T,
        [previousDependenciesRef.current]
    );
};

export default useDeepCompareCallback;
