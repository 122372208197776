// The escape before the dash character is necessary for conversion to "pattern" attribute
export const regex = {
    email: /^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$/, // eslint-disable-line
    password: /^.{8,}$/,
    coins: /^(?!,)[0-9,]*\.?[0-9]*$/,
    dollars: /^\d*\.{0,1}\d{0,2}$/, // Allows for no digit before or after decimal. Use /^(?:\d+\.\d{0,2}|\.\d{1,2}|\d+)$/ to require at least one digit before or after decimal
    digits: /^\d+$/,
    dateTime: /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}(?::\d{2}(?:\.\d{1,3})?)?)?$/, // YYYY-MM-DD{THH:MM{:SS{.SSS}}}
    url: /^(https?:\/\/)([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)(\/[a-zA-Z0-9\-._~!$&'()*+,;=:@%]*)?$/,
};

// Used to convert a regex patterns to the HTML "pattern" attribute
// This may not be a full solution, it simply strips "/^" and "$/"
export const regexToPattern = (regex: RegExp): string => {
    const slashCaretOrSlashDollar = /^\/?\^?|\/?\$?/g;
    return regex.toString().replace(slashCaretOrSlashDollar, "");
};
