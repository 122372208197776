import {
    forwardRef,
    useImperativeHandle,
    useState,
    Ref,
    useRef,
    ChangeEvent,
} from "react";
import InputWithAddon from "components/InputWithAddon";
import { validatePositiveNumber } from "utils/formValidation";
import { undecimalizeNumber } from "utils/financial";
import { GeneralTokenDetailsResponse } from "api";

export interface TransferAmountFieldProps {
    disabled: boolean;
    usd: boolean;
    token: GeneralTokenDetailsResponse;
    defaultAmount?: string;
}

export type TransferAmountFieldRef = {
    amount: string;
    amountForApi: string;
    hasChanged: boolean;
    validate: () => boolean;
};

function TransferAmountField(
    { disabled, usd, defaultAmount = "", token }: TransferAmountFieldProps,
    ref: Ref<TransferAmountFieldRef>
) {
    const [amount, setAmount] = useState<string>(defaultAmount);
    const amountRef = useRef<HTMLInputElement>(null);

    // Convert to cents if USD, otherwise convert with token decimals
    const decimals = usd ? 2 : token?.decimals;

    // Use ethers.utils to get the correct numbers for API
    const amountForApi = undecimalizeNumber(amount, decimals);

    const validate = () => {
        if (!amountRef.current) {
            return false;
        }

        return validatePositiveNumber({
            input: amountRef.current,
        });
    };

    const hasChanged = amount !== defaultAmount;
    useImperativeHandle(ref, () => ({
        amount: amount,
        amountForApi: amountForApi,
        validate,
        hasChanged,
    }));

    return (
        <InputWithAddon
            inputProps={{
                type: "number",
                disabled: disabled,
                name: "transferAmount",
                placeholder: "0",
                value: amount,
                innerRef: amountRef,
                onBlur: (event) =>
                    validatePositiveNumber({
                        input: event.target,
                        reportValidity: false,
                    }),
                onChange: (event: ChangeEvent<HTMLInputElement>) =>
                    setAmount(event.target.value),
                min: 0,
                max: 50_000,
                step: ".01",
            }}
            addon={{
                content: usd ? "$" : token ? token.symbol : "",
                position: usd ? "left" : "right",
            }}
        />
    );
}
export default forwardRef(TransferAmountField);
