import { useEffect, useState } from "react";
import { CheckoutItemResponse } from "api/types/checkout";
import { BaseItem } from "types/common";
import {
    minimumAllowanceAmountForCheckout,
    calculateMinimumAllowance,
} from "utils/checkout";

export const useMinimumAllowance = (
    invoiceAmount: number | undefined,
    items: CheckoutItemResponse[] | BaseItem[],
    minimumBalanceRequired: number | undefined
) => {
    const [minimumAllowance, setMinimumAllowance] = useState<number>(0);

    useEffect(() => {
        setMinimumAllowance(
            minimumAllowanceAmountForCheckout(
                calculateMinimumAllowance(
                    invoiceAmount,
                    items,
                    minimumBalanceRequired
                )
            )
        );
    }, [invoiceAmount, items, minimumBalanceRequired]);

    return minimumAllowance;
};
