import { useEffect, useState } from "react";
import { ethers } from "ethers";
import IWethAbi from "abis/abi/IWeth.json";
import solIcon from "assets/img/token-logo/sol.svg";

// [ ] Remove this once "aggregateAddress" is removed for all apps and fallback the hook to accept BaseToken[] only
type TokenWithoutRequiredAggregator = Omit<BaseToken, `aggregatorAddress`> & {
    aggregatorAddress?: string;
};

// Details that indicate what a native token can wrap on which networks
interface WrappableToken {
    wrappedBy: { symbol: string; networkId: number }[];
}

interface NativeToken
    extends Omit<BaseToken, `address` | `aggregatorAddress` | `networkId`>,
        WrappableToken {
    address?: string;
    aggregatorAddress?: string;
    networkId?: number;
}

// A Native token with details about it's wrapper
export interface WrapperToken extends TokenWithoutRequiredAggregator {
    wrapsTo: string;
}

const nativeTokens: NativeToken[] = [
    {
        name: "Ether",
        symbol: "ETH",
        logoUrl: "https://loop-token-logos.s3.us-east-2.amazonaws.com/eth.svg",
        decimals: 18,
        wrappedBy: [
            { symbol: "WETH", networkId: 1 },
            { symbol: "WETH", networkId: 5 },
            { symbol: "WETH", networkId: 10 },
            { symbol: "WETH", networkId: 8453 },
            { symbol: "WETH", networkId: 31337 },
            { symbol: "WETH", networkId: 42161 },
            { symbol: "WETH", networkId: 11155111 },
        ],
    },
    {
        name: "Matic",
        symbol: "MATIC",

        logoUrl:
            "https://loop-token-logos.s3.us-east-2.amazonaws.com/matic.svg",
        decimals: 18,
        wrappedBy: [{ symbol: "WMATIC", networkId: 137 }],
    },
    {
        name: "Sol",
        symbol: "SOL",
        logoUrl: solIcon,
        decimals: 9,
        wrappedBy: [
            { symbol: "WSOL", networkId: 900 },
            { symbol: "WSOL", networkId: 901 },
        ],
    },
];

export const getNativeTokenBySymbol = (searchSymbol: string): NativeToken => {
    const token = nativeTokens.find(
        ({ symbol }) => symbol.toUpperCase() === searchSymbol.toUpperCase()
    );
    if (!token) throw new Error(`No native token found for ${searchSymbol}`);
    return token;
};

export const getWrapperSymbols = (): string[] =>
    nativeTokens
        .reduce<string[]>((uniques, { wrappedBy }) => {
            return [
                ...uniques,
                ...wrappedBy.map(({ symbol }) => symbol.toUpperCase()),
            ];
        }, [])
        .filter((symbol, index, arr) => arr.indexOf(symbol) === index);

export const wrapNativeToken = async (
    tokenAddress: string,
    signer: ethers.Signer,
    value: any,
    fromSymbol: string,
    toSymbol: string,
    skipAwait: boolean = false,
    signedHandler: (() => void) | null = null
): Promise<any> => {
    const supportedWrappers = getWrapperSymbols();
    if (!supportedWrappers.includes(toSymbol.toUpperCase())) {
        return Promise.reject(
            `${fromSymbol.toUpperCase()} cannot be wrapped to ${toSymbol.toUpperCase()}}`
        );
    }

    let response;
    try {
        const wrapContract = new ethers.Contract(
            tokenAddress,
            IWethAbi, // IWethAbi deposit function is identical for IWmaticAbi
            signer
        );
        response = await wrapContract.deposit({
            value: ethers.utils.parseUnits(
                value,
                getNativeTokenBySymbol(fromSymbol)?.decimals
            ),
        });
    } catch (error) {
        return Promise.reject(
            `You wallet rejected the transaction to wrap ${toSymbol.toUpperCase()}`
        );
    }

    if (signedHandler) signedHandler();
    if (skipAwait) return Promise.resolve(response);

    // Get the receipt
    try {
        const receipt = await response.wait();
        if (receipt.status === 0) {
            return Promise.reject(
                `There was a problem wrapping to ${toSymbol.toUpperCase()}`
            );
        }
        return Promise.resolve(receipt);
    } catch (error) {
        return Promise.reject(
            `There was a problem wrapping to ${toSymbol.toUpperCase()}`
        );
    }
};

export const useNativeTokens = <T extends TokenWithoutRequiredAggregator>(
    tokensThatMightBeWrappers: T[] | undefined
) => {
    const [wrappableTokens, setWrappableTokens] = useState<WrapperToken[]>([]);

    useEffect(() => {
        const searchableTokens = tokensThatMightBeWrappers || [];
        setWrappableTokens(
            searchableTokens.reduce<WrapperToken[]>(
                // Loop through each baseToken and check if there is a `wraps` object that matches both the symbol and networkId of the possibleWrapper.
                //      If there is, add the possibleWrapper to the wrappables array.
                //      If there isn't, return the wrappables array
                (wrappables, possibleWrapper) => {
                    return nativeTokens.reduce<WrapperToken[]>(
                        (wrappables, { wrappedBy, ...baseToken }) => {
                            const tokenWrapper = wrappedBy?.find(
                                ({ symbol, networkId }) =>
                                    symbol === possibleWrapper.symbol &&
                                    networkId === possibleWrapper.networkId
                            );
                            if (tokenWrapper) {
                                return [
                                    ...wrappables,
                                    {
                                        ...possibleWrapper,
                                        ...baseToken,
                                        wrapsTo: possibleWrapper.symbol,
                                    },
                                ];
                            }
                            return wrappables;
                        },
                        wrappables
                    );
                },
                []
            )
        );
    }, [tokensThatMightBeWrappers]);

    return {
        wrappableTokens,
    };
};
