import styled, { css } from "styled-components";
import { colorsDeprecated as c } from "global-style";

const bgImg = css`
    ${({ dataSrc }) => dataSrc && `background-image: url('${dataSrc}');`}
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto ${({ tighten }) => (tighten ? `100%` : `66.667%`)};
`;
const colorBg = css`
    background-color: ${c.primaryTrans};
    background-blend-mode: luminosity;
`;
const colorImage = css`
    mix-blend-mode: hard-light;
    filter: contrast(0.6) saturate(0) brightness(0.7);
`;

export const Icon = styled.span`
    --size: ${({ size }) => size};
    display: ${({ block }) => (block ? `flex` : `inline-flex`)};
    ${({ fit }) => {
        switch (fit) {
            case `height`:
                return `height: var(--size); width: auto;`;
            case `width`:
                return `width: var(--size); height: auto;`;
            case `stretch`:
                return `width: var(--size); height: var(--size);`;
            default:
                return `width: var(--size); height: var(--size);`;
        }
    }}
    justify-content: center;
    align-items: center;
    ${({ round }) => (round ? `border-radius: 1.5em;` : ``)}
    ${({ wrapped }) => (wrapped ? `` : bgImg)}
    ${({ colorize, wrapped }) =>
        colorize
            ? wrapped
                ? `background-color: ${c.primaryTrans};`
                : colorBg
            : ``}
    ${({ tighten }) => !tighten && `border: 1px solid ${c.border};`}
    ${({ disabled }) => (disabled ? `filter: saturate(0);` : ``)}
`;

export const Img = styled.img`
    &:is(img),
    & img {
        display: block;
        ${({ tighten, fit }) =>
            fit
                ? `width: 100%; height: 100%;`
                : tighten
                ? `width: max(100%, var(--size));
                    height: max(100%, var(--size));`
                : `width: max(66.667%, calc(0.6666 * var(--size)));
                    height: max(66.667%, calc(0.6666 * var(--size)));`}
        ${({ fit }) => fit !== `stretch` && `object-fit: contain;`}
        ${({ colorize }) => colorize && colorImage}
    }
`;
