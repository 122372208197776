import { ethers } from "ethers";
import { CurrencyType } from "types/common-enums";
import ethIcon from "assets/img/token-logo/eth.svg";
import gorIcon from "assets/img/token-logo/gor.svg";
import maticIcon from "assets/img/token-logo/matic.svg";
import bnbIcon from "assets/img/token-logo/bnb.svg";
import arbiIcon from "assets/img/token-logo/arbi.svg";
import optimismIcon from "assets/img/token-logo/optimism.svg";
import baseIcon from "assets/img/token-logo/base.svg";
import sepoliaIcon from "assets/img/token-logo/eth.svg";
import solIcon from "assets/img/token-logo/sol.svg";
import {
    arbitrum,
    base,
    bsc,
    bscTestnet,
    goerli,
    mainnet,
    optimism,
    polygon,
    sepolia,
    Chain,
} from "viem/chains";

const apiServerUrl =
    import.meta.env.VITE_API_URL || `https://api.loopcrypto.xyz`;

const reactAppBuildType = import.meta.env.VITE_BUILD_TYPE as Environment;

// defaultNetworkId is specifically for instructing the application where to find exchange rates, if no wallet has been connected, but does not otherwise impact the application, or which network a wallet is connected to
const defaultNetworkId = import.meta.env.VITE_DEFAULT_NETWORK || `0x1`;

const userCurrency: CurrencyType = CurrencyType.USD;

const DOLLAR_SCALE_FACTOR = 100;
const PERCENTAGE_SCALE_FACTOR = 1000;
const PERCENTAGE_ORDER_OF_MAGNITUDE = 3;

const automationTypes = [
    {
        code: 1,
        label: `Subscription`,
        long: `Subscription`,
        verb: `Subscribe`,
        next: `Payment`,
    },
    {
        code: 2,
        label: `Swap`,
        long: `Recurring swap`,
        verb: `Swap`,
        next: `Swap`,
    },
    {
        code: 3,
        label: `API`,
        long: `Loop API`,
        verb: `Sign up`,
        next: `Payment`,
    },
];

const frequencyTypes = [
    {
        value: String(24 * 60 * 60),
        label: `24 hrs`,
        expectedNumberOfTransactions: 30 /* ~month */,
    },
    {
        value: String(7 * 24 * 60 * 60),
        label: `7 days`,
        expectedNumberOfTransactions: 26 /* half year */,
    },
    {
        value: String(14 * 24 * 60 * 60),
        label: `14 days`,
        expectedNumberOfTransactions: 13 /* half year */,
    },
    {
        value: String(30 * 24 * 60 * 60),
        label: `30 days`,
        expectedNumberOfTransactions: 6 /* ~half year */,
    },
];

// [ ] Move these types to a more appropriate location
export enum Blockchain {
    EVM = "EVM",
    SOL = "Solana",
}

// export type BlockchainNetwork = Blockchain[keyof Blockchain];
export type BlockchainNetwork = keyof typeof Blockchain;

export type Environment =
    | "development"
    | "staging"
    | "production"
    | "demo"
    | "local";

export type AvailableNetwork = {
    id: number;
    networkId: string;
    chain: BlockchainNetwork;
    token: string;
    label: string;
    rpcUrl: string;
    safeUrl: null | string;
    tokensForSale: string[];
    contractAddress: null | string;
    recurringSwapContract: {
        local: null | string;
        development: null | string;
        staging: null | string;
        production: null | string;
        demo: null | string;
        baseFee: number;
        percentFee: number;
    };
    deadline: number;
    icon: string;
    explorer: {
        name: string;
        url: string;
    };
    availableEnv: Environment[];
    viemChain: Chain | null;
};

// [ ] Remove all of the "recurring" references
const availableNetworks: AvailableNetwork[] = [
    {
        id: Number(`0x1`),
        networkId: `0x1`,
        chain: "EVM",
        token: `ETH`,
        label: `Ethereum`,
        rpcUrl: `https://ethereum.publicnode.com`,
        safeUrl: `https://safe-transaction-mainnet.safe.global`,
        tokensForSale: [`USDC`],
        contractAddress: `0xffF847a0d4D2afb8487D71aA59ad75874CF6b39D`,
        recurringSwapContract: {
            local: null,
            development: null,
            staging: null,
            production: `0xffF847a0d4D2afb8487D71aA59ad75874CF6b39D`,
            demo: null,
            baseFee: 25,
            percentFee: 10,
        },
        deadline: 30 * 60 * 1000,
        icon: ethIcon,
        explorer: {
            name: `Etherscan`,
            url: `https://etherscan.io`,
        },
        availableEnv: [`production`],
        viemChain: mainnet,
    },
    {
        id: Number(`0x5`),
        networkId: `0x5`,
        chain: "EVM",
        token: `ETH`,
        label: `Goerli`,
        rpcUrl: `https://eth-goerli.g.alchemy.com/v2/xgY4zDQdbZu2n9oqqIhB7d6u-BwRwIIw`,
        safeUrl: `https://safe-transaction-goerli.safe.global`,
        tokensForSale: [`USDC`],
        contractAddress: null,
        recurringSwapContract: {
            local: null,
            development: `0xBA9d853a414C9485894748941bD3CEAfE5397D4a`,
            staging: `0xC5ec894BE5997445e064e914a5685Ac7d39edb71`,
            production: null,
            demo: `0xA50b3c2a61b1d491435a694170117889ba77CfA4`,
            baseFee: 25,
            percentFee: 10,
        },
        deadline: 30 * 60 * 1000,
        icon: gorIcon,
        explorer: {
            name: `Etherscan`,
            url: `https://goerli.etherscan.io`,
        },
        availableEnv: [`development`, `staging`, `demo`],
        viemChain: goerli,
    },
    {
        id: Number(`0xa`),
        networkId: `0xa`,
        chain: "EVM",
        token: `ETH`,
        label: `Optimism`,
        rpcUrl: `https://optimism.publicnode.com`,
        safeUrl: `https://safe-transaction-optimism.safe.global`,
        tokensForSale: [],
        contractAddress: null,
        recurringSwapContract: {
            local: null,
            development: null,
            staging: null,
            production: null,
            demo: null,
            baseFee: 25,
            percentFee: 10,
        },
        deadline: 30 * 60 * 1000,
        icon: optimismIcon,
        explorer: {
            name: `Optimistic Etherscan`,
            url: `https://optimistic.etherscan.io`,
        },
        availableEnv: [`production`],
        viemChain: optimism,
    },
    {
        id: Number(`0x38`),
        networkId: `0x38`,
        chain: "EVM",
        token: `BNB`,
        label: `BNB`,
        rpcUrl: `https://bsc.publicnode.com`,
        safeUrl: `https://safe-transaction-bsc.safe.global`,
        tokensForSale: [],
        contractAddress: null,
        recurringSwapContract: {
            local: null,
            development: null,
            staging: null,
            production: null,
            demo: null,
            baseFee: 25,
            percentFee: 10,
        },
        deadline: 30 * 60 * 1000,
        icon: bnbIcon,
        explorer: {
            name: `BscScan`,
            url: `https://bscscan.com`,
        },
        availableEnv: [`staging`, `production`],
        viemChain: bsc,
    },
    {
        id: Number(`0x61`),
        networkId: `0x61`,
        chain: "EVM",
        token: `chBNB`,
        label: `BNB (Chapel)`,
        rpcUrl: `https://bsc-testnet.publicnode.com`,
        safeUrl: null,
        tokensForSale: [],
        contractAddress: null,
        recurringSwapContract: {
            local: null,
            development: null,
            staging: null,
            production: null,
            demo: null,
            baseFee: 25,
            percentFee: 10,
        },
        deadline: 30 * 60 * 1000,
        icon: bnbIcon,
        explorer: {
            name: `BscScan`,
            url: `https://testnet.bscscan.com`,
        },
        availableEnv: [`development`],
        viemChain: bscTestnet,
    },
    {
        id: Number(`0x89`),
        networkId: `0x89`,
        chain: "EVM",
        token: `MATIC`,
        label: `Polygon`,
        rpcUrl: `https://polygon-bor.publicnode.com`,
        safeUrl: `https://safe-transaction-polygon.safe.global`,
        tokensForSale: [`USDC`],
        contractAddress: `0x4108149C17168071dE8ED3995baBe553Ff77d76A`,
        recurringSwapContract: {
            local: null,
            development: null,
            staging: null,
            production: `0x4108149C17168071dE8ED3995baBe553Ff77d76A`,
            demo: null,
            baseFee: 0.05,
            percentFee: 1,
        },
        deadline: 30 * 60 * 1000,
        icon: maticIcon,
        explorer: {
            name: `Polygonscan`,
            url: `https://polygonscan.com`,
        },
        availableEnv: [`staging`, `production`],
        viemChain: polygon,
    },
    {
        id: Number(`0x2105`),
        networkId: `0x2105`,
        chain: "EVM",
        token: `ETH`,
        label: `Base`,
        rpcUrl: `https://base.publicnode.com`,
        safeUrl: "https://safe-transaction-base.safe.global",
        tokensForSale: [],
        contractAddress: null,
        recurringSwapContract: {
            local: null,
            development: null,
            staging: null,
            production: null,
            demo: null,
            baseFee: 25,
            percentFee: 10,
        },
        deadline: 30 * 60 * 1000,
        icon: baseIcon,
        explorer: {
            name: `Basescan`,
            url: `https://basescan.org`,
        },
        availableEnv: [`production`],
        viemChain: base,
    },
    {
        id: Number(`0x7a69`),
        networkId: `0x7a69`,
        chain: "EVM",
        token: `ETH`,
        label: `Localhost`,
        rpcUrl: `http://localhost:8545`,
        safeUrl: null,
        tokensForSale: [`USDC`],
        contractAddress:
            import.meta.env.VITE_LOCAL_RECURRING_BUY_CONTRACT_ADDRESS || null,
        recurringSwapContract: {
            local:
                import.meta.env.VITE_LOCAL_RECURRING_BUY_CONTRACT_ADDRESS ||
                null,
            development: null,
            staging: null,
            production: null,
            demo: null,
            baseFee: 25,
            percentFee: 10,
        },
        deadline: 30 * 60 * 1000,
        icon: ethIcon,
        explorer: {
            // For validating locally
            name: `Etherscan`,
            url: `https://etherscan.io`,
        },
        availableEnv: [`local`],
        viemChain: mainnet,
    },
    {
        id: Number(`0xa4b1`),
        networkId: `0xa4b1`,
        chain: "EVM",
        token: `ETH`,
        label: `Arbitrum`,
        rpcUrl: `https://arbitrum-one.publicnode.com`,
        safeUrl: `https://safe-transaction-arbitrum.safe.global`,
        tokensForSale: [],
        contractAddress: null,
        recurringSwapContract: {
            local: null,
            development: null,
            staging: null,
            production: null,
            demo: null,
            baseFee: 25,
            percentFee: 10,
        },
        deadline: 30 * 60 * 1000,
        icon: arbiIcon,
        explorer: {
            name: `ArbiScan`,
            url: `https://arbiscan.io`,
        },
        availableEnv: [`staging`, `production`],
        viemChain: arbitrum,
    },
    {
        id: Number(`0xaa36a7`),
        networkId: `0xaa36a7`,
        chain: "EVM",
        token: `ETH`,
        label: `Sepolia`,
        rpcUrl: `https://ethereum-sepolia-rpc.publicnode.com`,
        safeUrl: `https://safe-transaction-sepolia.safe.global`,
        tokensForSale: [],
        contractAddress: null,
        recurringSwapContract: {
            local: null,
            development: null,
            staging: null,
            production: null,
            demo: null,
            baseFee: 25,
            percentFee: 10,
        },
        deadline: 30 * 60 * 1000,
        icon: sepoliaIcon,
        explorer: {
            name: `Etherscan`,
            url: `https://sepolia.etherscan.io`,
        },
        availableEnv: [`development`, `staging`, `demo`, `production`],
        viemChain: sepolia,
    },
    {
        id: 900,
        networkId: `0x384`,
        chain: "SOL",
        token: `SOL`,
        label: `Solana`,
        rpcUrl: `https://solana-rpc.publicnode.com`,
        safeUrl: `???`,
        tokensForSale: [],
        contractAddress: ``,
        recurringSwapContract: {
            local: null,
            development: null,
            staging: null,
            production: null,
            demo: null,
            baseFee: 0,
            percentFee: 0,
        },
        deadline: 0,
        icon: solIcon,
        explorer: {
            name: `Solana Explorer`,
            url: `https://explorer.solana.com`,
        },
        availableEnv: [`production`],
        viemChain: null,
    },
    {
        id: 901,
        networkId: `0x385`,
        chain: "SOL",
        token: `SOL`,
        label: `Devnet`,
        rpcUrl: `https://api.devnet.solana.com`,
        safeUrl: `???`,
        tokensForSale: [],
        contractAddress: ``,
        recurringSwapContract: {
            local: null,
            development: null,
            staging: null,
            production: null,
            demo: null,
            baseFee: 0,
            percentFee: 0,
        },
        deadline: 0,
        icon: solIcon,
        explorer: {
            name: `Solana Devnet Explorer`,
            url: `https://explorer.solana.com/{}?cluster=devnet`,
        },
        availableEnv: [`development`, `staging`, `demo`],
        viemChain: null,
    },
    {
        id: 902,
        networkId: `0x386`,
        chain: "SOL",
        token: `SOL`,
        label: `Localhost (SOL)`,
        rpcUrl: `http://localhost:8899`,
        safeUrl: `???`,
        tokensForSale: [],
        contractAddress: ``,
        recurringSwapContract: {
            local: null,
            development: null,
            staging: null,
            production: null,
            demo: null,
            baseFee: 0,
            percentFee: 0,
        },
        deadline: 0,
        icon: solIcon,
        explorer: {
            name: `Solana Local Explorer`,
            url: `https://explorer.solana.com/{}?cluster=custom&customUrl=http%3A%2F%2Flocalhost%3A8899`,
        },
        availableEnv: [`local`],
        viemChain: null,
    },
];

const availableNetworksForCurrentEnv = availableNetworks.filter((n) =>
    n.availableEnv.includes(reactAppBuildType)
);

export type AvailableTokenNetworkSwapTo = {
    token: string;
    fee: number;
};

// TODO: Align with "BaseToken": Rename "decimal" to "decimals" and "tokenAddress" to "address"
export type AvailableTokenNetwork = {
    networkId: string;
    tokenAddress: string;
    aggregatorAddress: string;
    decimal: number;
    swapsTo: AvailableTokenNetworkSwapTo[];
    minSellAmount?: number;
};

export type AvailableToken = {
    symbol: string;
    label: string;
    networks: AvailableTokenNetwork[];
};

// [ ] Remove all "recurring" references, determine if we still need this array at all
const tokens: AvailableToken[] = [
    {
        symbol: `USDC`,
        label: `USD Coin`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48`,
                aggregatorAddress: `0x8fFfFfd4AfB6115b954Bd326cbe7B4BA576818f6`,
                decimal: 6,
                swapsTo: [
                    { token: `WETH`, fee: 500 },
                    { token: `DPI`, fee: 3000 },
                    { token: `MATIC`, fee: 3000 },
                    { token: `APE`, fee: 3000 },
                    { token: `UNI`, fee: 3000 },
                ],
                minSellAmount: 1000,
            },
            {
                networkId: `0x5`,
                tokenAddress: `0x07865c6e87b9f70255377e024ace6630c1eaa37f`,
                aggregatorAddress: `0xcc1e681b7eeb05984fd90a147a453dcf27311d66`,
                decimal: 6,
                swapsTo: [{ token: `WETH`, fee: 3000 }],
                minSellAmount: 1,
            },
            {
                networkId: `0xa`,
                tokenAddress: `0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85`,
                aggregatorAddress: `0x16a9FA2FDa030272Ce99B29CF780dFA30361E0f3`,
                decimal: 6,
                swapsTo: [],
            },
            {
                networkId: `0x38`,
                tokenAddress: `0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d`,
                aggregatorAddress: `0x51597f405303C4377E36123cBc172b13269EA163`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x89`,
                tokenAddress: `0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359`,
                aggregatorAddress: `0xfe4a8cc5b5b2366c1b58bea3858e81843581b2f7`,
                decimal: 6,
                swapsTo: [
                    { token: `WETH`, fee: 500 },
                    { token: `WBTC`, fee: 500 },
                    { token: `MATIC`, fee: 500 },
                    { token: `UMA`, fee: 10000 },
                    { token: `UNI`, fee: 3000 },
                ],
                minSellAmount: 1,
            },
            {
                networkId: `0x2105`,
                tokenAddress: `0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913`,
                aggregatorAddress: `0x7e860098F58bBFC8648a4311b374B1D669a2bc6B`,
                decimal: 6,
                swapsTo: [],
            },
            {
                networkId: `0x7a69`,
                tokenAddress: `0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48`,
                aggregatorAddress: `0x8fFfFfd4AfB6115b954Bd326cbe7B4BA576818f6`,
                decimal: 6,
                swapsTo: [
                    { token: `WETH`, fee: 500 },
                    { token: `DPI`, fee: 3000 },
                    { token: `MATIC`, fee: 3000 },
                    { token: `APE`, fee: 3000 },
                    { token: `UNI`, fee: 3000 },
                ],
                minSellAmount: 1,
            },
            {
                networkId: `0xa4b1`,
                tokenAddress: `0xaf88d065e77c8cC2239327C5EDb3A432268e5831`,
                aggregatorAddress: `0x50834f3163758fcc1df9973b6e91f0f0f0434ad3`,
                decimal: 6,
                swapsTo: [],
            },
            {
                networkId: `0xaa36a7`,
                tokenAddress: `0x1c7d4b196cb0c7b01d743fbc6116a902379c7238`,
                aggregatorAddress: `0xa2f78ab2355fe2f984d808b5cee7fd0a93d5270e`,
                decimal: 6,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `USDC.e`,
        label: `USD Coin`,
        networks: [
            {
                networkId: `0xa`,
                tokenAddress: `0x7F5c764cBc14f9669B88837ca1490cCa17c31607`,
                aggregatorAddress: `0x16a9FA2FDa030272Ce99B29CF780dFA30361E0f3`,
                decimal: 6,
                swapsTo: [],
            },
            {
                networkId: `0x89`,
                tokenAddress: `0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174`,
                aggregatorAddress: `0xfe4a8cc5b5b2366c1b58bea3858e81843581b2f7`,
                decimal: 6,
                swapsTo: [
                    { token: `WETH`, fee: 500 },
                    { token: `WBTC`, fee: 500 },
                    { token: `MATIC`, fee: 500 },
                    { token: `UMA`, fee: 10000 },
                    { token: `UNI`, fee: 3000 },
                ],
            },
            {
                networkId: `0xa4b1`,
                tokenAddress: `0xff970a61a04b1ca14834a43f5de4533ebddb5cc8`,
                aggregatorAddress: `0x50834f3163758fcc1df9973b6e91f0f0f0434ad3`,
                decimal: 6,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `USDbC`,
        label: `USD Coin`,
        networks: [
            {
                networkId: `0x2105`,
                tokenAddress: `0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA`,
                aggregatorAddress: `0x7e860098F58bBFC8648a4311b374B1D669a2bc6B`,
                decimal: 6,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `USDC.s`,
        label: `USD Coin`,
        networks: [
            {
                networkId: `0xaa36a7`,
                tokenAddress: `0x94a9D9AC8a22534E3FaCa9F4e7F2E2cf85d5E4C8`,
                aggregatorAddress: `0xa2f78ab2355fe2f984d808b5cee7fd0a93d5270e`,
                decimal: 6,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `WETH`,
        label: `Wrapped ETH`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2`,
                aggregatorAddress: `0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x5`,
                tokenAddress: `0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6`,
                aggregatorAddress: `0xD4a33860578De61DBAbDc8BFdb98FD742fA7028e`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0xa`,
                tokenAddress: `0x4200000000000000000000000000000000000006`,
                aggregatorAddress: `0x13e3Ee699D1909E989722E753853AE30b17e08c5`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x38`,
                tokenAddress: `0x2170Ed0880ac9A755fd29B2688956BD959F933F8`,
                aggregatorAddress: `0x9ef1B8c0E4F7dc8bF5719Ea496883DC6401d5b2e`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x2105`,
                tokenAddress: `0x4200000000000000000000000000000000000006`,
                aggregatorAddress: `0x71041dddad3595F9CEd3DcCFBe3D1F4b0a16Bb70`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x61`,
                tokenAddress: `0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378`,
                aggregatorAddress: `0x143db3CEEfbdfe5631aDD3E50f7614B6ba708BA7`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x89`,
                tokenAddress: `0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619`,
                aggregatorAddress: `0xF9680D99D6C9589e2a93a78A04A279e509205945`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x7a69`,
                tokenAddress: `0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2`,
                aggregatorAddress: `0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0xa4b1`,
                tokenAddress: `0x82af49447d8a07e3bd95bd0d56f35241523fbab1`,
                aggregatorAddress: `0x639fe6ab55c921f74e7fac1ee960c0b6293ba612`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0xaa36a7`,
                tokenAddress: `0x7b79995e5f793a07bc00c21412e50ecae098e7f9`,
                aggregatorAddress: `0x694aa1769357215de4fac081bf1f309adc325306`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `DAI`,
        label: `Dai`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0x6B175474E89094C44Da98b954EedeAC495271d0F`,
                aggregatorAddress: `0xAed0c38402a5d19df6E4c03F4E2DceD6e29c1ee9`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0xa`,
                tokenAddress: `0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1`,
                aggregatorAddress: `0x8dBa75e83DA73cc766A7e5a0ee71F656BAb470d6`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x38`,
                tokenAddress: `0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3`,
                aggregatorAddress: `0x132d3C0B1D2cEa0BC552588063bdBb210FDeecfA`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x2105`,
                tokenAddress: `0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb`,
                aggregatorAddress: `0x591e79239a7d679378eC8c847e5038150364C78F`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x89`,
                tokenAddress: `0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063`,
                aggregatorAddress: `0x4746DeC9e833A82EC7C2C1356372CcF2cfcD2F3D`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0xa4b1`,
                tokenAddress: `0xda10009cbd5d07dd0cecc66161fc93d7c9000da1`,
                aggregatorAddress: `0xc5c8e77b397e531b8ec06bfb0048328b30e9ecfb`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0xaa36a7`,
                tokenAddress: `0x68194a729c2450ad26072b3d33adacbcef39d574`,
                aggregatorAddress: `0x14866185b1962b63c3ea9e03bc1da838bab34c19`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `DPI`,
        label: `DeFi Pulse`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b`,
                aggregatorAddress: `0xD2A593BF7594aCE1faD597adb697b5645d5edDB2`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x7a69`,
                tokenAddress: `0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b`,
                aggregatorAddress: `0xD2A593BF7594aCE1faD597adb697b5645d5edDB2`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `MATIC`,
        label: `Polygon`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0`,
                aggregatorAddress: `0x7bAC85A8a13A4BcD8abb3eB7d6b4d632c5a57676`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x89`,
                tokenAddress: `0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270`,
                aggregatorAddress: `0xAB594600376Ec9fD91F8e885dADF0CE036862dE0`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x38`,
                tokenAddress: `0xCC42724C6683B7E57334c4E856f4c9965ED682bD`,
                aggregatorAddress: `0x7CA57b0cA6367191c94C8914d7Df09A57655905f`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x7a69`,
                tokenAddress: `0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0`,
                aggregatorAddress: `0x7bAC85A8a13A4BcD8abb3eB7d6b4d632c5a57676`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `APE`,
        label: `Apecoin`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0x4d224452801ACEd8B2F0aebE155379bb5D594381`,
                aggregatorAddress: `0xD10aBbC76679a20055E167BB80A24ac851b37056`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x7a69`,
                tokenAddress: `0x4d224452801ACEd8B2F0aebE155379bb5D594381`,
                aggregatorAddress: `0xD10aBbC76679a20055E167BB80A24ac851b37056`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `UNI`,
        label: `Uniswap`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984`,
                aggregatorAddress: `0x553303d460EE0afB37EdFf9bE42922D8FF63220e`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x89`,
                tokenAddress: `0xb33EaAd8d922B1083446DC23f610c2567fB5180f`,
                aggregatorAddress: `0xdf0Fb4e4F928d2dCB76f438575fDD8682386e13C`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x7a69`,
                tokenAddress: `0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984`,
                aggregatorAddress: `0x553303d460EE0afB37EdFf9bE42922D8FF63220e`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `WBTC`,
        label: `Bitcoin`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599`,
                aggregatorAddress: `0xF4030086522a5bEEa4988F8cA5B36dbC97BeE88c`,
                decimal: 8,
                swapsTo: [],
            },
            {
                networkId: `0xa`,
                tokenAddress: `0x68f180fcCe6836688e9084f035309E29Bf0A2095`,
                aggregatorAddress: `0x718A5788b89454aAE3A028AE9c111A29Be6c2a6F`,
                decimal: 8,
                swapsTo: [],
            },
            {
                networkId: `0x89`,
                tokenAddress: `0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6`,
                aggregatorAddress: `0xDE31F8bFBD8c84b5360CFACCa3539B938dd78ae6`,
                decimal: 8,
                swapsTo: [],
            },
            {
                networkId: `0x5`,
                tokenAddress: `0xC04B0d3107736C32e19F1c62b2aF67BE61d63a05`,
                aggregatorAddress: `0xA39434A63A52E749F02807ae27335515BA4b07F7`,
                decimal: 8,
                swapsTo: [],
            },
            {
                networkId: `0xa4b1`,
                tokenAddress: `0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f`,
                aggregatorAddress: `0xd0c7101eacbb49f3decccc166d238410d6d46d57`,
                decimal: 8,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `UMA`,
        label: `Uma`,
        networks: [
            {
                networkId: `0x89`,
                tokenAddress: `0x3066818837c5e6eD6601bd5a91B0762877A6B731`,
                aggregatorAddress: `0x33D9B1BAaDcF4b26ab6F8E83e9cb8a611B2B3956`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `LINK`,
        label: `ChainLink`,
        networks: [
            {
                networkId: `0x5`,
                tokenAddress: `0x326C977E6efc84E512bB9C30f76E30c160eD06FB`,
                aggregatorAddress: `0x48731cF7e84dc94C5f84577882c14Be11a5B7456`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0xaa36a7`,
                tokenAddress: `0x779877a7b0d9e8603169ddbd7836e478b4624789`,
                aggregatorAddress: `0xc59e3633baac79493d908e63626716e204a45edf`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `USDT`,
        label: `Tether USD`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0xdAC17F958D2ee523a2206206994597C13D831ec7`,
                aggregatorAddress: `0x3E7d1eAB13ad0104d2750B8863b489D65364e32D`,
                decimal: 6,
                swapsTo: [],
            },
            {
                networkId: `0xa`,
                tokenAddress: `0x94b008aA00579c1307B0EF2c499aD98a8ce58e58`,
                aggregatorAddress: `0xECef79E109e997bCA29c1c0897ec9d7b03647F5E`,
                decimal: 6,
                swapsTo: [],
            },
            {
                networkId: `0x38`,
                tokenAddress: `0x55d398326f99059fF775485246999027B3197955`,
                aggregatorAddress: `0xB97Ad0E74fa7d920791E90258A6E2085088b4320`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x89`,
                tokenAddress: `0xc2132d05d31c914a87c6611c10748aeb04b58e8f`,
                aggregatorAddress: `0x0A6513e40db6EB1b165753AD52E80663aeA50545`,
                decimal: 6,
                swapsTo: [],
            },
            {
                networkId: `0xa4b1`,
                tokenAddress: `0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9`,
                aggregatorAddress: `0x3f3f5df88dc9f13eac63df89ec16ef6e7e25dde7`,
                decimal: 6,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `BUSD`,
        label: `Binance USD`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0x4Fabb145d64652a948d72533023f6E7A623C7C53`,
                aggregatorAddress: `0x833D8Eb16D306ed1FbB5D7A2E019e106B960965A`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x89`,
                tokenAddress: `0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7`,
                aggregatorAddress: `0xE0dC07D5ED74741CeeDA61284eE56a2A0f7A4Cc9`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `FRAX`,
        label: `Frax`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0x853d955aCEf822Db058eb8505911ED77F175b99e`,
                aggregatorAddress: `0xB9E1E3A9feFf48998E45Fa90847ed4D467E8BcfD`,
                decimal: 18,
                swapsTo: [],
            },
            {
                networkId: `0x89`,
                tokenAddress: `0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89`,
                aggregatorAddress: `0x00DBeB1e45485d53DF7C2F0dF1Aa0b6Dc30311d3`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `ENS`,
        label: `Ethereum Name Service`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0xC18360217D8F7Ab5e7c516566761Ea12Ce7F9D72`,
                aggregatorAddress: `0x5C00128d4d1c2F4f652C267d7bcdD7aC99C16E16`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `DYDX`,
        label: `dYdX`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0x92D6C1e31e14520e676a687F0a93788B716BEff5`,
                aggregatorAddress: `0x478909D4D798f3a1F11fFB25E4920C959B4aDe0b`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `WBNB`,
        label: `Wrapped BNB`,
        networks: [
            {
                networkId: `0x38`,
                tokenAddress: `0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c`,
                aggregatorAddress: `0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `GUSD`,
        label: `Gemini dollar`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0x056Fd409E1d7A124BD7017459dFEa2F387b6d5Cd`,
                aggregatorAddress: `0xa89f5d2365ce98B3cD68012b6f503ab1416245Fc`,
                decimal: 2,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `LUSD`,
        label: `LUSD Stablecoin`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0x5f98805A4E8be255a32880FDeC7F6728C6568bA0`,
                aggregatorAddress: `0x3D7aE7E594f2f2091Ad8798313450130d0Aba3a0`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `SBC`,
        label: `Stable Coin`,
        networks: [
            {
                networkId: `0x89`,
                tokenAddress: `0xfdcC3dd6671eaB0709A4C0f3F53De9a333d80798`,
                aggregatorAddress: `0x79d4ce5596B6a38FaABC2144eD78a1d03372aA7B`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `CRV`,
        label: `Curve DAO Token`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0xD533a949740bb3306d119CC777fa900bA034cd52`,
                aggregatorAddress: `0xCd627aA160A6fA45Eb793D19Ef54f5062F20f33f`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `OP`,
        label: `Optimism`,
        networks: [
            {
                networkId: `0xa`,
                tokenAddress: `0x4200000000000000000000000000000000000042`,
                aggregatorAddress: `0x0D276FC14719f9292D5C1eA2198673d1f4269246`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `IMX`,
        label: `Immutable X`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0xD533a949740bb3306d119CC777fa900bA034cd52`,
                aggregatorAddress: `0xCd627aA160A6fA45Eb793D19Ef54f5062F20f33f`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `AMKT`,
        label: `Alongside Crypto Market Index`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0xF17A3fE536F8F7847F1385ec1bC967b2Ca9caE8D`,
                aggregatorAddress: `0x8fFfFfd4AfB6115b954Bd326cbe7B4BA576818f6`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `BOTTO`,
        label: `Botto`,
        networks: [
            {
                networkId: `0x1`,
                tokenAddress: `0x9DFAD1b7102D46b1b197b90095B5c4E9f5845BBA`,
                aggregatorAddress: `0x8fFfFfd4AfB6115b954Bd326cbe7B4BA576818f6`,
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `XAI`,
        label: `Xai`,
        networks: [
            {
                networkId: `0xa4b1`,
                tokenAddress: `0x4cb9a7ae498cedcbb5eae9f25736ae7d428c9d66`,
                aggregatorAddress: `0x8fFfFfd4AfB6115b954Bd326cbe7B4BA576818f6`, // Don't need the aggregator address anymore
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
    {
        symbol: `DEGEN`,
        label: `Degen`,
        networks: [
            {
                networkId: `0x2105`,
                tokenAddress: `0x4ed4e862860bed51a9570b96d89af5e1b0efefed`,
                aggregatorAddress: `0x8fFfFfd4AfB6115b954Bd326cbe7B4BA576818f6`, // Don't need the aggregator address anymore
                decimal: 18,
                swapsTo: [],
            },
        ],
    },
];

const tokenABI: ethers.ContractInterface = [
    {
        inputs: [
            {
                internalType: "address",
                name: "_initialRecipient",
                type: "address",
            },
            { internalType: "string", name: "_name", type: "string" },
            { internalType: "string", name: "_symbol", type: "string" },
            { internalType: "uint8", name: "_decimals", type: "uint8" },
            {
                internalType: "uint256",
                name: "_initialSupply",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "spender",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "value",
                type: "uint256",
            },
        ],
        name: "Approval",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "value",
                type: "uint256",
            },
        ],
        name: "Transfer",
        type: "event",
    },
    {
        inputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "address", name: "spender", type: "address" },
        ],
        name: "allowance",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "approve",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "account", type: "address" }],
        name: "balanceOf",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "decimals",
        outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "spender", type: "address" },
            {
                internalType: "uint256",
                name: "subtractedValue",
                type: "uint256",
            },
        ],
        name: "decreaseAllowance",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "addedValue", type: "uint256" },
        ],
        name: "increaseAllowance",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "name",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "symbol",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "totalSupply",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "transfer",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "transferFrom",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
    },
];

const aggregatorV3InterfaceABI = [
    {
        inputs: [],
        name: "decimals",
        outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "description",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
        name: "getRoundData",
        outputs: [
            { internalType: "uint80", name: "roundId", type: "uint80" },
            { internalType: "int256", name: "answer", type: "int256" },
            { internalType: "uint256", name: "startedAt", type: "uint256" },
            { internalType: "uint256", name: "updatedAt", type: "uint256" },
            { internalType: "uint80", name: "answeredInRound", type: "uint80" },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "latestRoundData",
        outputs: [
            { internalType: "uint80", name: "roundId", type: "uint80" },
            { internalType: "int256", name: "answer", type: "int256" },
            { internalType: "uint256", name: "startedAt", type: "uint256" },
            { internalType: "uint256", name: "updatedAt", type: "uint256" },
            { internalType: "uint80", name: "answeredInRound", type: "uint80" },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "version",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
];

const commonEmailDomains = [
    `loopcrypto.xyz`,
    `gmail.com`,
    `yahoo.com`,
    `hotmail.com`,
    `outlook.com`,
    `aol.com`,
    `live.com`,
    `icloud.com`,
    `msn.com`,
    `protonmail.com`,
    `pm.me`,
    `yandex.com`,
    `gmx.net`,
    `zoho.com`,
];

export {
    apiServerUrl,
    reactAppBuildType,
    defaultNetworkId,
    userCurrency,
    availableNetworks,
    availableNetworksForCurrentEnv,
    tokens,
    automationTypes,
    frequencyTypes,
    tokenABI,
    aggregatorV3InterfaceABI,
    commonEmailDomains,
    DOLLAR_SCALE_FACTOR,
    PERCENTAGE_SCALE_FACTOR,
    PERCENTAGE_ORDER_OF_MAGNITUDE,
};
