import { useEffect, useState } from "react";
import { userCurrency } from "default-variables";
import { CheckoutToken } from "checkout/types";
import { TokenExchangeDetailsResponse } from "api/types/checkout";

export const useCreateCheckoutTokens = (
    tokens: TokenExchangeDetailsResponse[] | undefined
): { checkoutTokens: CheckoutToken[]; tokenError: string | null } => {
    const [checkoutTokens, setCheckoutTokens] = useState<CheckoutToken[]>([]);
    const [tokenError, setTokenError] = useState<string | null>(null);

    useEffect(() => {
        setCheckoutTokens(
            tokens?.map((token) => {
                const { exchangeRates, ...rest } = token;

                const exchange = exchangeRates?.find(
                    (rate) => rate.currency === userCurrency
                );

                if (!exchange) {
                    setTokenError(
                        `Exchange rate for "${rest.name}" to "${userCurrency}" was not found.`
                    );
                    return {} as CheckoutToken;
                }

                return {
                    ...rest,
                    exchange,
                    exchangeRates,
                };
            }) || []
        );
    }, [tokens]);

    return { checkoutTokens, tokenError };
};
