import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { isTxHash } from "utils/strings";
import { asyncCallWithRetries } from "utils/async";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import {
    Connection,
    BlockheightBasedTransactionConfirmationStrategy,
} from "@solana/web3.js";

enum TxStatus {
    Invalid = "invalid", // Invalid hash or network
    Pending = "pending",
    Confirmed = "confirmed",
    Failed = "failed", // Transaction failed to confirm on chain
    Error = "error", // Fetch not complete (or tx not found)
}

interface TransactionStatusReturn {
    status: TxStatus;
    transaction: any; // TransactionResponse
    receipt: any; // TransactionReceipt
}

const retryDelays = [2000, 3000, 5000];

const useTransactionStatus = (
    hash: string | undefined,
    networkHex: string
): TransactionStatusReturn => {
    const [status, setStatus] = useState<TxStatus>(
        !hash || !isTxHash(hash) || !networkHex
            ? TxStatus.Invalid
            : TxStatus.Pending
    );
    const [transaction, setTransaction] = useState<any>(null);
    const [receipt, setReceipt] = useState<any>(null);
    const { getNetworkById } = useAvailableNetworks();

    useEffect(() => {
        const network = getNetworkById(networkHex);

        if (!hash || !network) {
            setStatus(TxStatus.Invalid);
            setTransaction(null);
            setReceipt(null);
            return;
        }

        let provider;
        if (network.chain === "SOL") {
            provider = new Connection(network.rpcUrl, "confirmed");
            console.log(`Confirming SOL transaction: ${hash}`);
            provider
                .confirmTransaction(
                    {
                        signature: hash,
                    } as BlockheightBasedTransactionConfirmationStrategy,
                    "confirmed"
                )
                .then(
                    (resp) => {
                        setStatus(
                            !resp.value.err
                                ? TxStatus.Confirmed
                                : TxStatus.Failed
                        );
                        setReceipt(resp.context.slot);
                    },
                    (error) => {
                        // Promise rejected, tx was not found
                        setStatus(TxStatus.Error);
                        setTransaction(null);
                        setReceipt(null);
                        console.error(
                            `${error}: Unable to locate signature ${hash} on ${network.label}`
                        );
                    }
                );
        } else {
            provider = new ethers.providers.JsonRpcProvider(network.rpcUrl);
            // Fetch the transaction and handle retries
            asyncCallWithRetries(provider.getTransaction, retryDelays, [hash], {
                error: `Transaction not found`,
                context: provider,
            }).then(
                (tx) => {
                    setTransaction(tx);
                    tx.wait().then(
                        (receipt: any) => {
                            setStatus(
                                receipt.status
                                    ? TxStatus.Confirmed // 1
                                    : TxStatus.Failed // 0
                            );
                            setReceipt(receipt);
                        },
                        () => {
                            setStatus(TxStatus.Failed);
                            setReceipt(null);
                        }
                    );
                },
                (error) => {
                    // Promise rejected, tx was not found
                    setStatus(TxStatus.Error);
                    setTransaction(null);
                    setReceipt(null);
                    console.error(
                        `${error}: Unable to locate transaction ${hash} on ${network.label} after ${retryDelays.length} attempts.`
                    );
                }
            );
        }
    }, [hash, networkHex, getNetworkById]);

    return { status, transaction, receipt };
};

export { useTransactionStatus, TxStatus };
