import React from "react";

const Exclamation = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 6 22"
            {...props}
        >
            {name && <title>{name}</title>}
            <path
                d="M5.13333 13.5718H.9L.2 0h5.6l-.66667 13.5718ZM3 16.3915c.84444 0 1.55556.2789 2.13333.8367C5.71111 17.7653 6 18.416 6 19.1803c0 .785-.28889 1.4563-.86667 2.0141C4.55556 21.7315 3.84444 22 3 22c-.82222 0-1.53333-.2685-2.133333-.8056C.288889 20.6366 0 19.9653 0 19.1803c0-.7643.288889-1.415.866667-1.9521C1.46667 16.6704 2.17778 16.3915 3 16.3915Z"
                fill="inherit"
            />
        </svg>
    );
};

export default Exclamation;
