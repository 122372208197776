import React from "react";
import * as S from "./style";

type CheckmarkIconProps = {};
const CheckmarkIcon: React.FunctionComponent<CheckmarkIconProps> = () => {
    return (
        <S.Checkmark
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14 14"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 .33a6.67 6.67 0 1 0 0 13.34A6.67 6.67 0 0 0 7 .33Zm0 12a5.34 5.34 0 1 1 .01-10.68A5.34 5.34 0 0 1 7 12.33ZM5.67 8.45l4.39-4.4L11 5l-5.33 5.33L3 7.67l.94-.94 1.73 1.72Z"
            />
        </S.Checkmark>
    );
};

export default CheckmarkIcon;
