import styled, { css } from "styled-components";
import ReactSelect from "react-select";
import ReactSelectCreatable from "react-select/creatable";
import {
    colorsDeprecated as c,
    properties as p,
    properties,
} from "global-style";
import { boxInputStates } from "theme/shared";
import colors from "theme/colors";
import { fontSizes, lineHeights } from "theme/typography";
import spacing from "theme/spacing";

export const classNamePrefix = "MultiSelect";

export const OptionImageWrapper = styled.div`
    display: flex;
`;

// Value in select
export const MultiValueWrapper = styled.div`
    display: flex;
    align-items: center;
    ${OptionImageWrapper} {
        width: 1rem;
        margin-right: 0.2rem;
    }
`;

// Option in dropdown
export const OptionWrapper = styled.div<{ isDisabled: boolean }>`
    display: flex;
    align-items: center;
    pointer-events: none;
    ${OptionImageWrapper} {
        width: 1.5rem;
        margin-right: 0.5rem;
    }
`;

const reactSelectSharedStyle = css`
    .${classNamePrefix} {
        &__control {
            flex-grow: 1;
            width: 100%;
            ${boxInputStates.default}
            font-size: ${fontSizes.md};
            padding: ${spacing.xxxs} ${spacing.xs};
            line-height: ${lineHeights.xl};

            &:hover {
                ${boxInputStates.hover}
                cursor: pointer;
            }
            &--is-focused {
                ${boxInputStates.focus}
            }

            &--is-disabled {
                background-color: ${c.inputDisabled};
            }
        }

        &__placeholder {
            ${boxInputStates.placeholder}
            margin: 0.26rem;
            margin-left: 0.2rem;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &__multi-value {
            background: rgb(230, 230, 230);
            margin: 0.2rem;
            font-size: 0.8rem;
            border-radius: ${p.radiusLg};
            overflow: hidden;

            &__label {
                padding: 0.2rem;
                color: ${c.label};
            }

            &__remove {
                padding: 0.2rem 0.3rem;
                color: ${c.inputIndicator};
                &:hover {
                    color: ${colors.error};
                }
            }
        }

        &__indicators {
            color: ${c.inputIndicator};
            padding: 0 0.5rem;
        }

        &__clear-indicator:hover {
            color: ${colors.error};
        }

        &__indicator-separator {
            margin: 0 0.3rem;
        }

        &__control:hover,
        &__control--is-focused {
            .${classNamePrefix}__dropdown-indicator {
                color: ${colors.primary};
            }
        }

        &__menu {
            box-shadow: ${properties.shadowMd};
            z-index: ${p.zIndex.selectDropdown};
            border-radius: ${properties.radiusLg};
            margin-top: 0.2rem;
            background-color: ${colors.white};
            border: 1px solid ${c.border};

            &-notice {
                padding: 1rem;
                color: ${c.inputPlaceholder};
            }
        }

        &__group {
            margin-bottom: 0.5rem;
        }

        &__group-heading {
            padding: 0.5rem 1rem;
            padding-top: 1rem;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 0.75rem;
        }

        &__option {
            padding: 0.5rem 1rem;
            color: ${c.input};
            &--is-focused {
                color: ${colors.primary};
                background-color: ${c.dropdownItemHover};
            }
        }
    }
`;

export const SelectMultiple = styled(ReactSelect)`
    width: 100%;
    ${reactSelectSharedStyle}
`;

export const SelectMultipleCreatable = styled(ReactSelectCreatable)`
    width: 100%;
    ${reactSelectSharedStyle}
`;
