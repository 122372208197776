import { Company, TransactionValues } from "company/types";
import { useState, useEffect, useCallback } from "react";
import {
    TransactionFilters,
    TransactionType,
} from "company/routes/Transactions/hooks/useTransactionTable";
import { getCompanyTransfers } from "api";
import { useCompanyAuthQuery } from "hooks/useCompanyAuthQuery";
import { useUser } from "context/User";
import { useEns } from "contexts/EnsProvider";
import { useQueryClient } from "@tanstack/react-query";

export const GET_COMPANY_TRANSFERS_QUERY_KEY = `getCompanyTransfers`;

interface GetCompanyTransfersProps {
    id?: string;
    type?: TransactionType;
    filters?: TransactionFilters;
    sortBy?: TransactionValues | undefined;
    sortDir?: "asc" | "desc" | undefined;
    page?: number;
    limit?: number;
}

export const useGetCompanyTransfers = (props?: GetCompanyTransfersProps) => {
    const { id, type, filters, sortBy, sortDir, page, limit } = props || {};

    const { getEntityId, getSessionToken } = useUser();
    const { lookupEnsRecords } = useEns();

    const [transactions, setTransactions] = useState<Company.Transaction[]>([]);
    const [totalResults, setTotalResults] = useState<number>();

    const queryClient = useQueryClient();

    // Will invalidate any query starting with [GET_COMPANY_TRANSFERS_QUERY_KEY, ...]
    // It could be smarter, see the documentation on invalidating cache queries:
    // https://tanstack.com/query/v5/docs/framework/react/guides/query-invalidation#query-matching-with-invalidatequeries
    const invalidateAllTransfersQueries = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [GET_COMPANY_TRANSFERS_QUERY_KEY],
        });
    }, [queryClient]);

    const queryKey = [
        GET_COMPANY_TRANSFERS_QUERY_KEY,
        { page, limit, sortBy, sortDir, id, type, ...filters },
    ];

    const {
        data: getCompanyTransfersData,
        isError: getCompanyTransfersIsError,
        isLoading: getCompanyTransfersIsLoading,
        isSuccess: getCompanyTransfersIsSuccess,
        dataUpdatedAt: getCompanyTransfersDataUpdatedAt,
        refetch: getCompanyTransfersRefetch,
        isFetching: getCompanyTransfersIsFetching,
    } = useCompanyAuthQuery({
        queryKey,
        queryFn: () => {
            return getCompanyTransfers(
                getEntityId(),
                { page, limit, sortBy, sortDir, id, type, ...filters },
                { Authorization: getSessionToken() }
            );
        },
    });

    useEffect(() => {
        setTransactions(getCompanyTransfersData?.transactions || []);
        setTotalResults(getCompanyTransfersData?.totalResults || 0);

        // Find and format wallet addresses from Contract[] objects
        const transfersAddresses =
            getCompanyTransfersData?.transactions.reduce<string[]>(
                (addresses, { receiver }) => {
                    addresses.push(receiver.wallet);
                    return addresses;
                },
                []
            ) || [];
        lookupEnsRecords(transfersAddresses);
    }, [getCompanyTransfersData, lookupEnsRecords]);

    return {
        transactions,
        totalResults,
        getCompanyTransfersIsError,
        getCompanyTransfersIsLoading,
        getCompanyTransfersIsSuccess,
        getCompanyTransfersDataUpdatedAt,
        getCompanyTransfersRefetch,
        getCompanyTransfersIsFetching,
        invalidateAllTransfersQueries,
    };
};
