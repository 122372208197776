import React from "react";

const User = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
        >
            {name && <title>{name}</title>}
            <path
                d="M3.85 15.1c.85-.65 1.8-1.163 2.85-1.538A9.744 9.744 0 0 1 10 13c1.15 0 2.25.187 3.3.562 1.05.375 2 .888 2.85 1.538a7.737 7.737 0 0 0 1.363-2.325A7.845 7.845 0 0 0 18 10c0-2.217-.779-4.104-2.337-5.663C14.104 2.779 12.217 2 10 2s-4.104.779-5.662 2.337C2.779 5.896 2 7.783 2 10c0 .983.163 1.908.488 2.775A7.72 7.72 0 0 0 3.85 15.1ZM10 11c-.983 0-1.813-.337-2.488-1.012S6.5 8.483 6.5 7.5c0-.983.337-1.813 1.012-2.488S9.017 4 10 4c.983 0 1.813.337 2.488 1.012S13.5 6.517 13.5 7.5c0 .983-.337 1.813-1.012 2.488S10.983 11 10 11Zm0 9a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.732 9.732 0 0 1 0 10c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0 1 10 0c1.383 0 2.683.262 3.9.787a10.105 10.105 0 0 1 3.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0 1 20 10a9.733 9.733 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0 1 10 20Zm0-2c.883 0 1.717-.129 2.5-.387.783-.259 1.5-.63 2.15-1.113a7.542 7.542 0 0 0-2.15-1.113A7.942 7.942 0 0 0 10 15c-.883 0-1.717.129-2.5.387-.783.259-1.5.63-2.15 1.113.65.483 1.367.854 2.15 1.113A7.942 7.942 0 0 0 10 18Zm0-9c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075C10.792 6.142 10.433 6 10 6c-.433 0-.792.142-1.075.425-.283.283-.425.642-.425 1.075 0 .433.142.792.425 1.075C9.208 8.858 9.567 9 10 9Z"
                fill="inherit"
            />
        </svg>
    );
};

export default User;
