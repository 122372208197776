import * as S from "./style";
import {
    forwardRef,
    useImperativeHandle,
    useState,
    Ref,
    useEffect,
} from "react";
import Label from "components/Label";
import Field from "components/Field";
import Radio from "components/Radio";
import Anchor from "components/Anchor";
import InfoToolTip from "components/InfoToolTip";
import { Company } from "company/types";

export interface ItemAutoInvoiceFieldProps {
    disabled?: boolean;
    plurality: InvoicePlurality;
    canAutoInvoice: boolean;
    defaultAutoInvoice?: Company.Item["autoInvoice"];
    onChange?: (autoInvoice: Company.Item["autoInvoice"]) => void;
    fixedPrice: boolean;
    isSubscription: boolean;
}

export type ItemAutoInvoiceFieldRef = {
    autoInvoice: Company.Item["autoInvoice"];
};

export enum InvoicePlurality {
    Single,
    Multiple,
}

function ItemAutoInvoiceField(
    {
        disabled,
        plurality,
        canAutoInvoice,
        defaultAutoInvoice,
        onChange,
        fixedPrice,
        isSubscription,
    }: ItemAutoInvoiceFieldProps,
    ref: Ref<ItemAutoInvoiceFieldRef>
) {
    const [autoInvoice, setAutoInvoice] = useState<boolean>(
        typeof defaultAutoInvoice === "boolean"
            ? defaultAutoInvoice
            : canAutoInvoice
    );

    useImperativeHandle(ref, () => ({
        autoInvoice,
    }));

    const autoInvoiceCopy =
        plurality === InvoicePlurality.Single
            ? `Immediately generate invoice upon authorization + free trial days`
            : `Automatically generate invoices, including the first payment`;

    const manualInvoiceCopy = `Manually generate invoice${
        plurality === InvoicePlurality.Single ? `` : `s`
    }`;

    const frequencyCopy =
        plurality === InvoicePlurality.Single
            ? `an invoice for this item upon authorization`
            : `invoices for this item at the frequency set above (e.g., Hour, Day, Month, etc.)`;

    const autoInvoiceName = "autoInvoice";

    useEffect(() => {
        if (onChange) onChange(autoInvoice);
    }, [autoInvoice, onChange]);

    // Reset autoInvoice
    useEffect(() => {
        if (autoInvoice && !fixedPrice && isSubscription) {
            setAutoInvoice(false);
        }
    }, [autoInvoice, fixedPrice, isSubscription]);

    return (
        <Field disabled={disabled}>
            <Label htmlFor={autoInvoiceName}>
                Payment collection
                <S.TooltipContainer>
                    <InfoToolTip
                        title={
                            <>
                                <p>
                                    If you select <em>"{autoInvoiceCopy}"</em>,
                                    Loop will automatically generate{" "}
                                    {frequencyCopy}.
                                </p>
                                <p>
                                    If you do not wish for this automation to be
                                    activated, select{" "}
                                    <em>"{manualInvoiceCopy}"</em>.
                                </p>
                                <Anchor
                                    href={
                                        import.meta.env
                                            .VITE_LOOP_DOCS_API_ENABLED_CONTRACT_ADDING_ITEMS
                                    }
                                    inheritColor={true}
                                >
                                    Learn more
                                </Anchor>
                                .
                            </>
                        }
                    />
                </S.TooltipContainer>
            </Label>
            {!canAutoInvoice && (
                <S.Paragraph>
                    If you would like to enable auto generation, please contact{" "}
                    <Anchor
                        href={`mailto:${import.meta.env.VITE_EMAIL_SUPPORT}`}
                    >
                        {import.meta.env.VITE_EMAIL_SUPPORT}
                    </Anchor>
                </S.Paragraph>
            )}
            <Label inline>
                <Radio
                    name={autoInvoiceName}
                    value="true"
                    checked={autoInvoice}
                    disabled={
                        disabled ||
                        !canAutoInvoice ||
                        (isSubscription && !fixedPrice)
                    }
                    onChange={() => setAutoInvoice(true)}
                />
                {autoInvoiceCopy}
            </Label>
            <Label inline>
                <Radio
                    name={autoInvoiceName}
                    value="false"
                    checked={!autoInvoice}
                    disabled={disabled}
                    onChange={() => setAutoInvoice(false)}
                />
                {manualInvoiceCopy}
            </Label>
        </Field>
    );
}

export default forwardRef(ItemAutoInvoiceField);
