import * as S from "./style";
import { FormEvent, useState } from "react";
import { useCustomerData } from "customer/context/CustomerData";
import Button, { ButtonSizes, ButtonVariants } from "components/Button";
import Tooltip from "components/Tooltip";
import { useModal } from "context/ModalProvider";
import Paragraph from "components/Paragraph";
import { Spacing } from "theme/spacing";
import { useWalletConnected } from "context/Wallet/WalletConnectedContext";

interface CancelAuthorizationProps {
    id: string;
    name: string;
    entity: string;
}

const CancelAuthorization = ({
    id,
    name,
    entity,
    ...props
}: CancelAuthorizationProps) => {
    const { walletConnected } = useWalletConnected();
    const { cancelAuthorization } = useCustomerData();
    const [isCancelling, setIsCancelling] = useState(false);

    const { openModal, closeModal } = useModal();

    const handleCancelSubmit = (
        event: FormEvent<HTMLFormElement>,
        closePopover: () => void
    ) => {
        event.preventDefault();

        closePopover();
        setIsCancelling(true);
        cancelAuthorization(id, name, entity);
    };

    const handleCancelAccountFormModal = () => {
        openModal(
            <S.CancelAuthorization
                onSubmit={(event) => handleCancelSubmit(event, closeModal)}
            >
                <Paragraph spacing={Spacing.md}>
                    Canceling stops future payments for {name}. It does not
                    cancel any payments that are already due.
                </Paragraph>
                <Paragraph spacing={Spacing.md}>
                    Don't worry, you don't need to sign a transaction or pay
                    gas.
                </Paragraph>
                <S.ConfirmOrReject>
                    <li>
                        <Button type="submit" onClick={() => false}>
                            Proceed with cancellation
                        </Button>
                    </li>
                    <li>
                        <Button
                            type="button"
                            variant={ButtonVariants.NeutralOutlined}
                            onClick={() => closeModal()}
                        >
                            Nevermind
                        </Button>
                    </li>
                </S.ConfirmOrReject>
            </S.CancelAuthorization>,
            `Are you sure you want to cancel ${name}?`
        );
    };

    return (
        <Button
            size={ButtonSizes.Small}
            variant={ButtonVariants.NeutralOutlined}
            loading={isCancelling}
            onClick={handleCancelAccountFormModal}
            {...props}
        >
            {isCancelling ? "Cancelling" : "Cancel"}
        </Button>
    );
};

export default CancelAuthorization;
