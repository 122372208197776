import * as S from "./styles";
import { FunctionComponent, useMemo } from "react";
import { Company, CompanyAgreementResponse } from "company/types";
import { ItemCategoryTypeOutbound, AgreementType } from "types/common-enums";
import { SECONDS_IN_DAY } from "utils/datetime";
import Table from "components/Table";
import Anchor from "components/Anchor";
import Badge from "components/Badge";
import Tooltip from "components/Tooltip";
import BalanceAndAllowanceCell from "components/BalanceAndAllowanceCell";
import TokenTableCell from "company/components/TokenTableCell";
import AuthorizedWalletManage from "company/components/AuthorizedWalletManage";
import VerifiedBadge from "components/icons/VerifiedBadge";
import DynamicWalletAddressDisplay from "components/DynamicWalletAddress/DynamicWalletAddressDisplay";
import { useEns } from "contexts/EnsProvider";
import {
    CommonBlockchainNetworkResponse,
    GeneralTokenDetailsResponse,
} from "api";
import { useGetCompanyAgreements } from "company/hooks/useGetCompanyAgreements";
import { UserRole, useUser } from "context/User";
import colors from "theme/colors";
import EmptyTableMessage from "components/EmptyTableMessage";
import { useWallet } from "context/Wallet";

interface AuthorizedWalletsTableProps {
    items: Company.Item[];
    agreements: CompanyAgreementResponse[];
    networks: CommonBlockchainNetworkResponse[];
    tokens: GeneralTokenDetailsResponse[];
}

const AuthorizedWalletsTable: FunctionComponent<AuthorizedWalletsTableProps> =
    ({ items, agreements, networks, tokens }) => {
        const { getEnsRecord } = useEns();
        const { getCompanyAgreementsIsFetching } = useGetCompanyAgreements();
        const { walletConnected } = useWallet();

        const { hasRole } = useUser();

        const canManage = hasRole(UserRole.COMPANY);
        const outboundItems = items.filter(
            (item) =>
                ItemCategoryTypeOutbound.includes(item.type) && item.active
        );
        const outboundItemsIds = outboundItems.map((item) => item.id);
        const outboundAgreements = agreements.filter((agreement) =>
            outboundItemsIds.includes(agreement.items[0])
        );

        const singleOutboundItem = outboundItems.length === 1;
        const headings: Heading[] = [
            { label: "Sender" },
            { label: "Token" },
            { label: "Item", hide: singleOutboundItem },
            { label: "Status" },
            {
                label: "Manage",
                style: { shrink: true, textAlign: `center` },
                hide: !canManage,
            },
        ];

        const agreementsRecord = useMemo(
            () =>
                outboundAgreements.map((agreement) => {
                    const network = networks.find(
                        (network) => network.id === agreement.networkId
                    );

                    const token = tokens.find(
                        (token) =>
                            token.address === agreement.token &&
                            token.networkId === agreement.networkId
                    );

                    const item = items.find((item) =>
                        agreement.items.includes(item.id)
                    );

                    if (!token || !network || !item) {
                        return { id: agreement.id, values: [] };
                    }

                    const senderWalletIsConnectedWallet =
                        walletConnected &&
                        agreement.sender.wallet.toLocaleLowerCase() ===
                            walletConnected.address.toLocaleLowerCase()
                            ? true
                            : false;

                    const agreementCanceled =
                        agreement.status ===
                        AgreementType[AgreementType.Canceled];

                    const showNewBadge =
                        agreement.createdAt >
                            Date.now() / 1000 - SECONDS_IN_DAY * 2 &&
                        !agreementCanceled;

                    const values: Field[] = [
                        {
                            label: (
                                <S.FlexContainer>
                                    <div>
                                        <DynamicWalletAddressDisplay
                                            address={agreement.sender.wallet}
                                            ensName={
                                                getEnsRecord(
                                                    agreement.sender.wallet
                                                )?.name
                                            }
                                            networkId={network?.hexId}
                                            shorten
                                            icon
                                            iconFill="currentColor"
                                        />

                                        <p>{agreement.sender.email}</p>
                                    </div>
                                    {senderWalletIsConnectedWallet && (
                                        <Tooltip
                                            title="This authorization is associated with your connected wallet, allowing you to edit the allowance"
                                            placement="top"
                                        >
                                            <span>
                                                <VerifiedBadge
                                                    fill={colors.primary}
                                                />
                                            </span>
                                        </Tooltip>
                                    )}
                                    {showNewBadge && (
                                        <Badge variant="green">New</Badge>
                                    )}
                                </S.FlexContainer>
                            ),
                            value: `${agreement.sender.wallet}, ${agreement.sender.email}`,
                            text: `${agreement.sender.wallet}, ${agreement.sender.email}`,
                            style: { expand: true },
                        },
                        {
                            label: <TokenTableCell token={token} />,
                            value: token.name,
                            text: token.name,
                            style: { expand: true },
                        },
                        {
                            label: item.name,
                            value: item.name,
                            text: item.name,
                            style: { expand: true },
                        },
                        {
                            label: (
                                <BalanceAndAllowanceCell
                                    token={token}
                                    walletAddress={agreement.sender.wallet}
                                />
                            ),
                            value: "Get Data",
                            text: "Get Data",
                        },
                        {
                            label: (
                                <AuthorizedWalletManage agreement={agreement} />
                            ),
                            value: "Manage",
                            text: "Manage",
                            style: { textAlign: `center` },
                        },
                    ];

                    return {
                        id: agreement.id,
                        values: values,
                    };
                }),
            [
                getEnsRecord,
                items,
                networks,
                outboundAgreements,
                tokens,
                walletConnected,
            ]
        );

        return (
            <Table
                data={{
                    headings: headings,
                    records: agreementsRecord,
                }}
                refetching={getCompanyAgreementsIsFetching}
                ifNoRecords={
                    <EmptyTableMessage
                        title="No authorized wallets to display yet"
                        description={
                            <>
                                Need help? Take a look at our{" "}
                                <Anchor
                                    href={
                                        import.meta.env
                                            .VITE_LOOP_DOCS_SCHEDULING_OUTBOUND_PAYMENTS
                                    }
                                >
                                    documentation
                                </Anchor>{" "}
                                or{" "}
                                <Anchor
                                    href={`mailto:${
                                        import.meta.env.VITE_EMAIL_SUPPORT
                                    }`}
                                >
                                    contact us
                                </Anchor>
                                .
                            </>
                        }
                    />
                }
            />
        );
    };

export default AuthorizedWalletsTable;
