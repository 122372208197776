import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GlobalStyle } from "global-style";
import { NotificationQueueProvider } from "context/NotificationQueue";
import Dashboards from "./components/Dashboards";
import Login from "./components/Login";
import { SessionProvider, LoginType } from "context/Session";
import { ModalProvider } from "context/ModalProvider";
import WalletContextProvider from "context/Wallet";

const AdminPortal = () => {
    return (
        <NotificationQueueProvider>
            <GlobalStyle />
            <Router>
                <WalletContextProvider>
                    <ModalProvider>
                        <SessionProvider loginType={LoginType.WEB2}>
                            <Routes>
                                <Route path="/login/*" element={<Login />} />
                                <Route path="*" element={<Dashboards />} />
                            </Routes>
                        </SessionProvider>
                    </ModalProvider>
                </WalletContextProvider>
            </Router>
        </NotificationQueueProvider>
    );
};

export default AdminPortal;
