import styled, { css } from "styled-components";
import { colorsDeprecated as c } from "global-style";
import CompanyLogo from "components/CompanyLogo";
import Card, { CardProps, CardSection } from "components/Card";
import colors from "theme/colors";
import { fontSizes } from "theme/typography";

export const ItemizedCheckout = styled(Card)<CardProps>`
    display: flex;
    flex-direction: column;
`;

export const TopRow = styled.hgroup`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block-start: 0.5rem;
    margin-block-end: 3rem;
`;

export const MiddleSection = styled(CardSection)`
    flex-grow: 1;
`;

export const InvoiceId = styled.h2`
    font-size: 1rem;
    font-weight: 400;
`;

export const ItemName = styled.h2`
    font-size: 1rem;
`;

export const PayNow = styled.strong`
    display: flex;
    align-items: center;
    font-size: 2rem;
    color: ${colors.neutral10};
    gap: 0.75rem;
`;

export const Recurring = styled.div`
    color: ${c.lightOnSurface};
`;

export const NetworkFee = styled.small`
    color: ${c.lightOnSurface};
`;

export const ItemRow = css`
    padding-block: 1rem;
`;

export const CouponFormWrapper = styled.div`
    padding-top: 1rem;
    border-top: 1px solid ${c.border};
`;

export const SummaryRow = css`
    padding-block: 1rem;
    border-top: 1px solid ${c.border};
`;

export const SummaryOneLine = styled.span`
    text-align: left;
    & > * {
        display: block;
    }
`;

export const SummaryTwoLine = styled.span`
    text-align: end;
    & > * {
        display: block;
    }
`;

export const PrimaryPrice = styled.div<{ bold?: boolean }>`
    margin-block: 0;
    display: flex;
    gap: 0.25rem;
    align-items: center;
    ${({ bold }) => (bold ? `font-weight: 600;` : undefined)}
    justify-content: end
`;

export const StrikedPrice = styled.span`
    text-decoration: line-through;
    opacity: 0.5;
`;

export const Price = styled.b``;

export const ItemDetails = styled.div`
    background-color: red;
`;

export const ItemDetailsLine = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const InvoiceRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    color: #5f5d67;
`;

export const Entity = styled(CompanyLogo)<{
    entity: any;
}>`
    font-size: 2.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    img {
        display: block;
        height: 1.1em;
    }
    a:focus {
        outline-offset: -1px;
        display: inline-block;
    }
`;

export const TokenUsdPrice = styled.small`
    color: ${colors.neutral70};
    font-size: 0.75em;
`;
