import * as S from "./style";
import { colorsDeprecated as c } from "global-style";
import { useEffect } from "react";
import { IframeMessages } from "checkout/types/widget";
import { firstToUpper } from "utils/strings";
import { toNetworkHex } from "utils/numbers";
import { BlockExplorerEntityType } from "utils/urls";
import { useTransactionStatus, TxStatus } from "hooks/useTransactionStatus";
import { useCheckoutData } from "checkout/context/CheckoutData";
import { CardHeader } from "components/Card";
import DescriptionList from "components/DescriptionList";
import Button, { ButtonVariants } from "components/Button";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import Section from "components/Section";
import Anchor from "components/Anchor";
import colors from "theme/colors";
import { useWallet } from "context/Wallet";

interface CheckoutCompleteProps {
    completeData: CheckoutPostResponse;
}

const CheckoutComplete = ({ completeData }: CheckoutCompleteProps) => {
    const { walletConnected } = useWallet();
    const {
        entity,
        networks,
        isOneTimePayment,
        isAnyPriceVarious,
        isInvoicedCheckout,
        queryParams,
    } = useCheckoutData();

    const { status: txStatus } = useTransactionStatus(
        completeData.transactionHash ?? undefined,
        toNetworkHex(completeData.networkId)
    );

    const isPending: boolean = txStatus === TxStatus.Pending;

    const hasTxIssues =
        txStatus === TxStatus.Failed ? (
            <>
                Transaction was not processed
                <br />
                <em>We'll keep trying!</em>
            </>
        ) : txStatus === TxStatus.Error ? (
            <>Unable to confirm your transaction at this time</>
        ) : (
            false
        );

    const { redirectUrl, websiteUrl } = entity || {};

    // Invoice in the future
    const isScheduledInvoice =
        isInvoicedCheckout &&
        completeData.billDate &&
        completeData.billDate > completeData.startDate;

    // Notify the checkout widget that the checkout is complete
    useEffect(() => {
        if (!completeData || !window) return;

        try {
            window.parent.postMessage(IframeMessages.CheckoutComplete, "*");
        } catch (err) {
            console.error(err);
        }
    }, [completeData]);

    const summaryItems = [];

    if (!isAnyPriceVarious && queryParams.cartEnabled)
        summaryItems.push({
            term:
                txStatus === TxStatus.Confirmed
                    ? `Total paid${
                          !isScheduledInvoice && !isOneTimePayment
                              ? ` today`
                              : ``
                      }`
                    : isScheduledInvoice
                    ? `Scheduled amount`
                    : `Total due`,
            definition: <S.Def>{completeData.amountPaid}</S.Def>,
            style: S.ItemRow,
        });

    if (completeData.transactionHash)
        summaryItems.push({
            term: `Transaction details`,
            definition: (
                <DynamicAddressDisplay
                    address={completeData.transactionHash}
                    networkId={toNetworkHex(completeData.networkId)}
                    type={BlockExplorerEntityType.Transaction}
                    shorten
                    icon
                    inheritColor={false}
                    placement="top"
                />
            ),
            style: S.ItemRow,
        });

    const footerItems = [
        {
            term: `Wallet`,
            definition: (
                <DynamicAddressDisplay
                    address={completeData.walletAddress}
                    networkId={toNetworkHex(completeData.networkId)}
                    shorten
                    icon
                    inheritColor={false}
                    placement="top"
                />
            ),
            style: S.ItemRow,
        },
        {
            term: `Network`,
            definition: firstToUpper(
                networks.find(({ id }) => id === completeData.networkId)
                    ?.name || ``
            ),
            style: S.ItemRow,
        },
    ];

    const unixNow = Date.now() / 1000;

    // If date is "scheduled" from the UI, show that date as "Date scheduled"
    const scheduledDate =
        isScheduledInvoice &&
        completeData.billDate &&
        completeData.billDate > unixNow
            ? completeData.billDate // scheduled in the future
            : completeData.startDate ?? unixNow; // in the past (default to now)

    if (completeData.billDate || completeData.startDate)
        footerItems.push({
            term: isScheduledInvoice ? `Date scheduled` : `Date`,
            definition:
                completeData.transactionHash && txStatus !== TxStatus.Confirmed
                    ? `-`
                    : `${new Date(scheduledDate * 1000).toLocaleString(
                          "en-US",
                          {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                          }
                      )}`,
            style: S.ItemRow,
        });

    const handleCloseWidget = () => {
        try {
            window.parent.postMessage(IframeMessages.CloseWidget, "*");
        } catch (err) {
            console.error(err);
        }
    };

    // Default: Success
    let title: string | JSX.Element = `Checkout complete`;
    let statusMsg: string | JSX.Element | boolean = false;
    let icon: JSX.Element = <S.Checkmark />;
    let iconBgColor = c.secondaryLight; // green

    // State: Pending
    if (isPending) {
        title = `Confirming transaction`;
        statusMsg = `It may take a few minutes for your transaction to confirm on-chain.`;
        icon = <S.Waiting />;
        iconBgColor = c.primaryLight; // purple
    }

    // State: Multisig wallet
    const isMultiSigWallet = !!walletConnected?.proxyFor;

    if (isMultiSigWallet && !completeData.hasMinimumAllowance) {
        title = "Please finish signing the transaction within Gnosis safe app";
        statusMsg =
            "Once all the request signers have finished signing, you will receive a confirmation email.";
        icon = <S.MultiSigIcon />;
        iconBgColor = colors.warning80; // orange
    }

    // State: Issue with transaction
    if (hasTxIssues) {
        title = hasTxIssues;
        statusMsg = (
            <>
                Check your email for a payment receipt,
                <br />
                or{" "}
                <Anchor href={`mailto:${import.meta.env.VITE_EMAIL_HELP}`}>
                    contact support
                </Anchor>{" "}
                for assistance.
            </>
        );
        icon = <S.Warning />;
        iconBgColor = colors.warning80;
    }

    return (
        <S.CheckoutComplete>
            <S.CenterCard forwardedAs="article">
                <CardHeader>
                    <S.Circle iconBgColor={iconBgColor}>{icon}</S.Circle>
                    <Section>
                        <h1>{title}</h1>
                        {statusMsg && <S.StatusMsg>{statusMsg}</S.StatusMsg>}
                    </Section>
                </CardHeader>
                {summaryItems.length > 0 && (
                    <S.Details>
                        <DescriptionList items={summaryItems} />
                    </S.Details>
                )}
                <S.Footer>
                    <DescriptionList items={footerItems} />
                    {queryParams.embed ? (
                        <Button
                            onClick={handleCloseWidget}
                            variant={ButtonVariants.Anchor}
                            full
                        >
                            Close
                        </Button>
                    ) : (
                        (redirectUrl || websiteUrl) &&
                        entity?.name && (
                            <Button
                                href={(redirectUrl || websiteUrl)!}
                                full
                                variant={ButtonVariants.PrimaryOutlined}
                            >
                                Return to {firstToUpper(entity.name)}
                            </Button>
                        )
                    )}
                </S.Footer>
            </S.CenterCard>
        </S.CheckoutComplete>
    );
};

export default CheckoutComplete;
