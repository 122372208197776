import { useCallback, useEffect, useState } from "react";
import {
    useDynamicContext,
    useSwitchWallet,
    useUserWallets,
} from "@dynamic-labs/sdk-react-core";
import { BlockchainNetwork } from "default-variables";
import useDeepCompareEffect from "hooks/useDeepCompareEffect";
import { deepEqual } from "utils/objects";
import {
    EvmWalletSigner,
    EvmProvider,
    SolProvider,
    SolWalletSigner,
} from "types/common";
import { SafeWallet } from "context/Wallet/hooks/useWalletSafes";
import useDeepCompareCallback from "hooks/useDeepCompareCallback";

export interface WalletApp {
    icon: string | null;
    label: string;
}

interface PrimaryWalletAccountBase extends WalletApp {
    id: string;
    address: string;
    signer: EvmWalletSigner | SolWalletSigner;
    ens: string | null;
    proxyFor: string | null;
    safe: Promise<SafeWallet | null>;
}

export type SolWallet = {
    chain: "SOL";
    signer: SolWalletSigner;
    provider: SolProvider;
};

export type EvmWallet = {
    chain: "EVM";
    signer: EvmWalletSigner;
    provider: EvmProvider;
};

export type PrimaryWalletAccount =
    | (PrimaryWalletAccountBase & SolWallet)
    | (PrimaryWalletAccountBase & EvmWallet);

export interface ConnectedWallet extends WalletApp {
    id: string;
    chain: BlockchainNetwork;
    addresses: string[];
}

/* As far as "walletsAvailable" go, it seems that Dynamic's "additionalAddresses" array is only set when
not in "connect-only" made (non-signatured). Thus, only the primary address of a wallet is available, 
rather than with Onboard, where each address/account in a wallet that was used would then be available
and could be switched to. Revisit this if we go with a premium account and use dynamic's signature */

const useWalletConnected = () => {
    const [isWalletConnecting, setIsWalletConnecting] = useState(false);
    const [wallet, setWallet] = useState<PrimaryWalletAccount | null>(null);
    const [walletsAvailable, setWalletsAvailable] = useState<ConnectedWallet[]>(
        []
    );

    const { primaryWallet } = useDynamicContext();
    const switchWallet = useSwitchWallet();
    const userWallets = useUserWallets();

    const setPrimaryWallet = useDeepCompareCallback(
        (walletId: ConnectedWallet[`id`]) => {
            switchWallet(walletId);
        },
        [switchWallet, wallet]
    );

    // [ ] Move this to `useSafeWallets` and move `proxyFor` to a variable and privitize it, exposing "isProxying" and "getActiveAddress"
    const setProxyWallet = useCallback((proxyAddr: string | null) => {
        setWallet((prevWallet) => {
            if (!prevWallet) return null;

            return {
                ...prevWallet,
                proxyFor: proxyAddr,
            };
        });
        return {} as any;
    }, []);

    // This gets around the inefficiencies of Dynamic's useUserWallets hook, that continuously returns a new array
    useDeepCompareEffect(() => {
        const newWallets: ConnectedWallet[] = userWallets.map((wallet) => ({
            id: wallet.id,
            addresses: [wallet.address],
            chain: wallet.chain as BlockchainNetwork,
            // @ts-expect-error wallet is not part of WalletConnector type for some reason
            icon: wallet.connector.wallet?.brand.spriteId
                ? // @ts-expect-error wallet is not part of WalletConnector type for some reason
                  `https://iconic.dynamic-static-assets.com/icons/sprite.svg#${wallet.connector.wallet?.brand.spriteId}`
                : ``,
            label: wallet.connector.name,
        }));

        // Compare old and new wallets
        const updatedWallets = newWallets.map((newWallet) => {
            const existingWallet = walletsAvailable.find(({ addresses }) =>
                // [ ] Perhaps using address as a key isn't enough, given the same address can be in multiple wallets. Consider adding the "id" value from Dynamic's object
                deepEqual(addresses, newWallet.addresses)
            );
            return existingWallet && deepEqual(existingWallet, newWallet)
                ? existingWallet
                : newWallet;
        });

        // Only update walletsAvailable if there are changes
        if (!deepEqual(walletsAvailable, updatedWallets)) {
            setWalletsAvailable(updatedWallets);
        }
    }, [userWallets]);

    useEffect(() => {
        if (!primaryWallet?.address) {
            setIsWalletConnecting(false);
            setWallet(null);
            return;
        }

        (async () => {
            setIsWalletConnecting(true);

            const signer =
                primaryWallet.chain === "SOL"
                    ? await primaryWallet.connector.getSigner<SolWalletSigner>()
                    : await primaryWallet.connector.ethers?.getSigner();

            const provider =
                primaryWallet.chain === "SOL"
                    ? await primaryWallet.connector.getPublicClient<SolProvider>()
                    : await primaryWallet.connector.ethers?.getRpcProvider();

            // @ts-ignore >> Why isn't `wallet` part of the WalletConnector type?
            const iconId = primaryWallet.connector.wallet?.brand.spriteId;

            setWallet({
                id: primaryWallet.id,
                address: primaryWallet.address,
                chain: primaryWallet.chain as BlockchainNetwork,
                icon: iconId
                    ? `https://iconic.dynamic-static-assets.com/icons/sprite.svg#${iconId}`
                    : ``,
                label: primaryWallet.connector.name,
                signer,
                provider,
                ens: null,
                proxyFor: null,
                safe: Promise.resolve(null),
            });
        })();
    }, [
        primaryWallet?.address,
        primaryWallet?.connector,
        primaryWallet?.chain,
        primaryWallet?.id,
    ]);

    useEffect(() => {
        if (!wallet) return;

        setIsWalletConnecting(false);
    }, [wallet]);

    return {
        isWalletConnecting,
        wallet,
        walletsAvailable,
        setPrimaryWallet,
        setProxyWallet,
    };
};

export default useWalletConnected;
