import * as S from "./style";
import { useEffect, useRef, useState, Fragment } from "react";
import { shortenAddress } from "utils/strings";
import Button, { ButtonSizes, ButtonVariants } from "components/Button";
import Icon from "components/Icon";
import CheckmarkIcon from "components/CheckmarkIcon";
import safeLogo from "assets/img/logos/safe-wallet.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import { SelectOption } from "components/Select";
import { useWallet } from "context/Wallet";
import { Blockchain } from "default-variables";
import { ConnectedWallet } from "context/Wallet/hooks/useWalletConnected";
import useSafesConnected from "hooks/useSafesConnected";
import Badge from "components/Badge";
import Anchor from "components/Anchor";
import { Density } from "components/Badge";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import Tooltip from "components/Tooltip";

const WalletConnect = ({ ...props }) => {
    const { getAvailableNetworks } = useAvailableNetworks();
    const {
        handleConnectWallet,
        handleDisconnectWallet,
        isWalletConnecting,
        walletConnected,
        setConnectedNetwork,
        isNetworkSetting,
        networkConnected,
        walletsAvailable,
        setPrimaryWallet,
        setProxyWallet,
    } = useWallet();

    const { safesConnected } = useSafesConnected();

    const [walletPanelOpen, setWalletPanelOpen] = useState(false);
    const walletSelectRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(walletSelectRef, () => setWalletPanelOpen(false));

    const walletPanel = useRef<HTMLDivElement>(null);

    const handleChangeNetwork = (event: React.ChangeEvent<HTMLSelectElement>) =>
        setConnectedNetwork({ networkId: event.target.value });

    const handleClickWallet = () => {
        setWalletPanelOpen(!walletPanelOpen);
    };

    const handleConnect = () => handleConnectWallet();

    const handleChangeAccount = (wallet: ConnectedWallet) => {
        // [ ] Immediately set wallet to one of the flags to disable the UI, theres a delay that could get us into trouble if things are clickable
        setPrimaryWallet(wallet.id);
        setWalletPanelOpen(false);
    };

    const handleDisconnect = (wallet: any) => {
        handleDisconnectWallet(/* { label: wallet } */);
    };

    const handleClickSafeWallet = (safeWallet: any) => {
        setProxyWallet(
            walletConnected?.proxyFor?.toLowerCase() ===
                safeWallet?.toLowerCase()
                ? null
                : safeWallet
        );
    };

    // If someone connects a wallet that is pre-set to a network we don't support, this will be undefined
    const connectedToAvailableChain = getAvailableNetworks().find(
        ({ networkId }) => networkConnected?.networkId === networkId
    );

    useEffect(() => {
        if (!walletPanelOpen || !walletPanel.current) return;
        walletPanel.current.focus();
    });

    const chainOptions: SelectOption[] = getAvailableNetworks().map(
        ({ networkId, label }) => {
            return {
                label: label,
                value: networkId,
            };
        }
    );

    const chainName =
        walletConnected?.chain && Blockchain[walletConnected.chain];

    const placeholder = !connectedToAvailableChain
        ? networkConnected
            ? {
                  value: ``,
                  label: `Select a valid network`,
              }
            : chainName
            ? {
                  value: ``,
                  label: `Switch to compatible wallet`,
              }
            : undefined
        : undefined;

    return (
        <S.WalletConnect {...props}>
            {walletConnected && networkConnected ? (
                <>
                    {isNetworkSetting ? (
                        <S.SettingChain>
                            <strong>Switching chains</strong>
                            <br />
                            <small>Check your wallet...</small>
                        </S.SettingChain>
                    ) : (
                        <>
                            <S.NetworkSelect
                                selectProps={{
                                    value: networkConnected.networkId,
                                    onChange: handleChangeNetwork,
                                    placeholder: placeholder,
                                    options: chainOptions,
                                    name: "network",
                                    bold: true,
                                    hollow: true,
                                }}
                                icon={{
                                    src: networkConnected.icon,
                                    alt: `${networkConnected.label} logo`,
                                }}
                            />
                            <S.WalletSelect ref={walletSelectRef}>
                                <S.CurrentWallet
                                    full
                                    variant={ButtonVariants.NeutralOutlined}
                                    title={
                                        walletConnected.proxyFor ||
                                        walletConnected.address
                                    }
                                    onClick={handleClickWallet}
                                >
                                    {walletConnected.proxyFor ? (
                                        <S.WalletLogo
                                            src={safeLogo}
                                            alt="Safe (wallet)"
                                        />
                                    ) : walletConnected.icon ? (
                                        <S.WalletLogo
                                            src={walletConnected.icon}
                                            alt={walletConnected.label}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <S.AddrSelected>
                                        {walletConnected.proxyFor
                                            ? shortenAddress(
                                                  walletConnected.proxyFor
                                              )
                                            : walletConnected.ens ||
                                              shortenAddress(
                                                  walletConnected.address
                                              )}
                                    </S.AddrSelected>
                                </S.CurrentWallet>

                                {walletPanelOpen && (
                                    <S.WalletPanel
                                        forwardedAs="article"
                                        tabIndex="0"
                                    >
                                        <h1>Connected wallets</h1>
                                        <S.Wallets>
                                            {walletsAvailable.map((wallet) => (
                                                <S.Wallet key={wallet.label}>
                                                    <S.WalletHeading>
                                                        {wallet.icon && (
                                                            <Icon
                                                                src={
                                                                    wallet.icon
                                                                }
                                                                alt={`${wallet.label} logo`}
                                                                size="1.7em"
                                                                tighten
                                                            />
                                                        )}
                                                        <span>
                                                            {wallet.label}
                                                        </span>
                                                        <Button
                                                            title="Disconnect Wallet"
                                                            size={
                                                                ButtonSizes.Small
                                                            }
                                                            variant={
                                                                ButtonVariants.PrimaryOutlined
                                                            }
                                                            onClick={() =>
                                                                handleDisconnect(
                                                                    wallet.label
                                                                )
                                                            }
                                                            disabled={
                                                                isWalletConnecting
                                                            }
                                                        >
                                                            Disconnect
                                                        </Button>
                                                    </S.WalletHeading>
                                                    <>
                                                        {wallet.addresses.map(
                                                            (account) => (
                                                                <Fragment
                                                                    key={
                                                                        account
                                                                    }
                                                                >
                                                                    <S.WalletAccount>
                                                                        <Tooltip
                                                                            title="Switch to this wallet"
                                                                            placement="left"
                                                                            disabled={
                                                                                account ===
                                                                                walletConnected.address
                                                                            }
                                                                        >
                                                                            <S.Address
                                                                                disabled={
                                                                                    account ===
                                                                                    walletConnected.address
                                                                                }
                                                                                noStyles
                                                                                onClick={() => {
                                                                                    if (
                                                                                        account ===
                                                                                        walletConnected.address
                                                                                    )
                                                                                        return;
                                                                                    handleChangeAccount(
                                                                                        wallet
                                                                                    );
                                                                                }}
                                                                                tabIndex={
                                                                                    account ===
                                                                                    walletConnected.address
                                                                                        ? undefined
                                                                                        : 0
                                                                                }
                                                                            >
                                                                                {
                                                                                    account
                                                                                }
                                                                            </S.Address>
                                                                        </Tooltip>
                                                                        {account ===
                                                                            walletConnected.address && (
                                                                            <>
                                                                                {` `}
                                                                                <CheckmarkIcon />
                                                                            </>
                                                                        )}
                                                                    </S.WalletAccount>
                                                                    {account ===
                                                                        walletConnected.address &&
                                                                        safesConnected && (
                                                                            <S.SafeWallets>
                                                                                <h3>
                                                                                    Available{" "}
                                                                                    <Anchor
                                                                                        href="https://safe.global/"
                                                                                        underlined={
                                                                                            false
                                                                                        }
                                                                                        inheritColor={
                                                                                            true
                                                                                        }
                                                                                    >
                                                                                        <S.SafeLogo
                                                                                            src={
                                                                                                safeLogo
                                                                                            }
                                                                                            alt="Safe (wallet)"
                                                                                        />
                                                                                        Safe
                                                                                    </Anchor>{" "}
                                                                                    wallets
                                                                                    to
                                                                                    proxy
                                                                                </h3>
                                                                                <ul>
                                                                                    {safesConnected.map(
                                                                                        ({
                                                                                            address:
                                                                                                safeAddress,
                                                                                        }) => (
                                                                                            <S.Address
                                                                                                as={
                                                                                                    "li"
                                                                                                }
                                                                                                key={
                                                                                                    safeAddress
                                                                                                }
                                                                                                data-proxying={
                                                                                                    walletConnected.proxyFor?.toLowerCase() ===
                                                                                                    safeAddress.toLowerCase()
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    handleClickSafeWallet(
                                                                                                        safeAddress
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <DynamicAddressDisplay
                                                                                                    shorten
                                                                                                    address={
                                                                                                        safeAddress
                                                                                                    }
                                                                                                    networkId={
                                                                                                        networkConnected.networkId
                                                                                                    }
                                                                                                    anchor={
                                                                                                        false
                                                                                                    }
                                                                                                />
                                                                                                {walletConnected.proxyFor?.toLowerCase() ===
                                                                                                    safeAddress.toLowerCase() && (
                                                                                                    <>
                                                                                                        {` `}
                                                                                                        <Badge
                                                                                                            variant="purple"
                                                                                                            density={
                                                                                                                Density.COMPACT
                                                                                                            }
                                                                                                        >
                                                                                                            proxied
                                                                                                        </Badge>
                                                                                                    </>
                                                                                                )}
                                                                                            </S.Address>
                                                                                        )
                                                                                    )}
                                                                                </ul>
                                                                            </S.SafeWallets>
                                                                        )}
                                                                </Fragment>
                                                            )
                                                        )}
                                                    </>
                                                </S.Wallet>
                                            ))}
                                        </S.Wallets>

                                        <footer>
                                            <Button
                                                title="Connect Wallet"
                                                full
                                                onClick={() => {
                                                    handleConnect();
                                                    setWalletPanelOpen(false);
                                                }}
                                                loading={isWalletConnecting}
                                            >
                                                {isWalletConnecting
                                                    ? "Connecting..."
                                                    : "Connect another wallet"}
                                            </Button>
                                        </footer>
                                    </S.WalletPanel>
                                )}
                            </S.WalletSelect>
                        </>
                    )}
                </>
            ) : (
                <Button
                    title="Connect Wallet"
                    onClick={handleConnect}
                    loading={isWalletConnecting}
                >
                    {isWalletConnecting ? "Connecting..." : "Connect wallet"}
                </Button>
            )}
        </S.WalletConnect>
    );
};

export default WalletConnect;
