import * as S from "./style";
import loopLogo from "assets/img/logos/loop-crypto-long-black.svg";
import Anchor from "components/Anchor";

export interface CheckoutFooterProps {
    showCart: boolean;
}

const CheckoutFooter = ({ showCart }: CheckoutFooterProps) => {
    return (
        <S.Credits showCart={showCart}>
            <S.PoweredBy>
                <span>Powered by</span>{" "}
                <Anchor href={import.meta.env.VITE_LOOP_WEBSITE}>
                    <img src={loopLogo} alt="Loop Crypto logo" />
                </Anchor>
            </S.PoweredBy>
            <S.SiteLinks>
                <li>
                    <Anchor
                        href={import.meta.env.VITE_TERMS_OF_SERVICE}
                        underlined={false}
                        inheritColor
                    >
                        Terms
                    </Anchor>
                </li>
                <li>
                    <Anchor
                        href={import.meta.env.VITE_PRIVACY}
                        underlined={false}
                        inheritColor
                    >
                        Privacy
                    </Anchor>
                </li>
                <li>
                    <Anchor
                        href={import.meta.env.VITE_LOOP_DOCS_FAQ}
                        underlined={false}
                        inheritColor
                    >
                        FAQ
                    </Anchor>
                </li>
            </S.SiteLinks>
        </S.Credits>
    );
};

export default CheckoutFooter;
