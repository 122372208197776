import { useState, RefObject, MutableRefObject, useCallback } from "react";
import { ethers } from "ethers";
import {
    validateDateTimeRange,
    validatePositiveNumber,
    validatePresence,
    validateWalletAddress,
} from "utils/formValidation";
import { Company, CompanyAgreementResponse } from "company/types";
import {
    CommonBlockchainNetworkResponse,
    GeneralTokenDetailsResponse,
} from "api";

interface ValidInvoiceFormProps {
    item: Company.Item | undefined;
    agreement: CompanyAgreementResponse | undefined;
    token: GeneralTokenDetailsResponse | undefined;
    network: CommonBlockchainNetworkResponse | undefined;
    amountRef: RefObject<HTMLInputElement>;
    toAddressRef: RefObject<HTMLInputElement>;
    invoiceIdsRef: MutableRefObject<RefObject<HTMLInputElement>[]>;
    billDatesRef: MutableRefObject<RefObject<HTMLInputElement>[]>;
}

interface ValidInvoiceFormReturn {
    isFormValid: () => boolean;
    valid: boolean;
}

const useValidateInvoiceForm = ({
    item,
    agreement,
    token,
    network,
    amountRef,
    toAddressRef,
    invoiceIdsRef,
    billDatesRef,
}: ValidInvoiceFormProps): ValidInvoiceFormReturn => {
    const [valid, setValid] = useState<boolean>(false);

    // Shamelessly copied from TransferBillDateField, since we plan to
    // remove the InvoiceForm entirely in favor of a new invoicing system
    const today = new Date();
    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const thirtyDaysAgo = new Date(today.getTime() - thirtyDaysInMilliseconds);
    const errorMessage = "Date must be within the last 30 days";

    const validateBillDateInput = useCallback(
        (input: HTMLInputElement, reportValidity: boolean) => {
            return validateDateTimeRange({
                input: input,
                min: thirtyDaysAgo,
                reportValidity,
                errorMessage,
            });
        },
        [thirtyDaysAgo]
    );

    const isFormValid = useCallback(() => {
        let isValid = true;

        if (!item?.id || !agreement?.items.includes(item.id)) {
            isValid = false;
        }

        if (
            !agreement?.sender?.wallet ||
            !ethers.utils.isAddress(agreement.sender.wallet)
        ) {
            isValid = false;
        }

        if (
            !token?.address ||
            token.address.toLowerCase() !== agreement?.token?.toLowerCase() ||
            token.networkId !== agreement?.networkId
        ) {
            isValid = false;
        }

        if (!network?.id || network.id !== agreement?.networkId) {
            isValid = false;
        }

        if (amountRef.current) {
            if (!validatePositiveNumber({ input: amountRef.current }))
                isValid = false;
        }

        if (toAddressRef.current) {
            if (!validateWalletAddress({ input: toAddressRef.current }))
                isValid = false;
        }

        invoiceIdsRef.current.forEach(
            (invoiceIdRef: RefObject<HTMLInputElement>) => {
                if (
                    invoiceIdRef.current &&
                    !validatePresence({ input: invoiceIdRef.current })
                )
                    isValid = false;
            }
        );

        billDatesRef.current.forEach(
            (billDateRef: RefObject<HTMLInputElement>) => {
                if (
                    billDateRef.current &&
                    !validateBillDateInput(billDateRef.current, true)
                )
                    isValid = false;
            }
        );

        setValid(isValid);
        return isValid;
    }, [
        item,
        agreement,
        token,
        network,
        amountRef,
        toAddressRef,
        invoiceIdsRef,
        billDatesRef,
        validateBillDateInput,
    ]);

    return { isFormValid, valid };
};

export { useValidateInvoiceForm };
