export const clusterToNetworkId = (
    cluster: string
): `0x${string}` | undefined => {
    switch (cluster) {
        case `mainnet`:
            return `0x384`;
        case `devnet`:
            return `0x385`;
    }
};

export const networkIdToCluster = (
    networkId: string
): `mainnet` | `devnet` | undefined => {
    switch (networkId) {
        case `0x384`:
            return `mainnet`;
        case `0x385`:
            return `devnet`;
    }
};
