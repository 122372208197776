import styled from "styled-components";
import { Select } from "components/Select/styles";
import { properties as p } from "global-style";
export const LabelWrapper = styled.label<{ hasIcon: boolean }>`
    position: relative;
    display: block;
    ${Select} {
        ${({ hasIcon }) => hasIcon && `padding-left: 2.5em;`}
    }
`;

export const IconContainer = styled.span`
    position: absolute;
    z-index: ${p.zIndex.inputIcon};
    height: 100%;
    width: 2.5em;
    display: flex;
    padding: 0.5em 0.6em;
    text-align: center;
    justify-content: center;
    pointer-events: none;
`;
