import { ethers } from "ethers";
import { availableNetworks } from "../default-variables";

export enum BlockExplorerEntityType {
    Transaction,
    Address,
    Token,
}

const entityTypeToLabelMap = {
    [BlockExplorerEntityType.Transaction]: `tx`,
    [BlockExplorerEntityType.Address]: `address`, // Wallets and Contracts
    [BlockExplorerEntityType.Token]: `token`,
};

export const getBlockExplorerUrl = (
    address: string,
    type: BlockExplorerEntityType,
    networkId: string
) => {
    const networkIdHex = networkId.startsWith(`0x`)
        ? networkId
        : ethers.utils.hexValue(networkId);

    const curNetwork = availableNetworks.find(
        (network: any) => network.networkId === networkIdHex
    );

    if (!curNetwork) {
        return null;
    }

    if (curNetwork.chain === "SOL") {
        // Solana explorers don't have a /token endpoint, just use /address
        const typeMap =
            type === BlockExplorerEntityType.Token
                ? BlockExplorerEntityType.Address
                : type;
        const urlPath = `${entityTypeToLabelMap[typeMap]}/${address}`;
        const baseUrl = curNetwork.explorer.url;

        // They append the environment as a query string parameter
        return baseUrl.includes("{}")
            ? baseUrl.replace("{}", urlPath)
            : `${baseUrl}/${urlPath}`;
    } else {
        return `${curNetwork.explorer.url}/${entityTypeToLabelMap[type]}/${address}`;
    }
};

export const isAbsolutePath = (path: string): boolean => {
    return (
        path.startsWith(`http://`) ||
        path.startsWith(`https://`) ||
        path.startsWith(`mailto:`) ||
        path.startsWith(`data:`)
    );
};

export const pathToText = (path: string): string =>
    path
        .replace(/\/+/, ``)
        .split(`/`)
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
        .join(` `);
