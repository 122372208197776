import React from "react";
import ReactDOM from "react-dom/client";
import AdminPortal from "admin/App";
import CustomerPortal from "customer/App";
import RecurringPortal from "recurring/App";
import CheckoutPortal from "checkout/App";
import CompanyPortal from "company/App";
import { reactAppBuildType } from "default-variables";
import reportWebVitals from "reportWebVitals";
import { booleanFromString } from "utils/booleans";
import { clarity } from "react-microsoft-clarity";

// Hide console messages when not in "development" environment
if (reactAppBuildType === `production`) {
    console.log = () => {};
    console.table = () => {};
    console.debug = () => {};
    console.group = () => {};
}

if (reactAppBuildType === "production" || reactAppBuildType === "demo")
    if (!clarity.hasStarted()) clarity.init("nk4e5a8fli");

if (import.meta.env.VITE_BUILD_TARGET === `checkout`) {
    const checkoutUrl = import.meta.env.VITE_CHECKOUT_URL?.substring(8);
} else if (import.meta.env.VITE_BUILD_TARGET === `company`) {
    const companyUrl = import.meta.env.VITE_COMPANY_URL?.substring(8);
} else if (import.meta.env.VITE_BUILD_TARGET === `customer`) {
    const customerUrl = import.meta.env.VITE_DASHBOARD_URL?.substring(8);
}

const renderPortal = () => {
    switch (import.meta.env.VITE_BUILD_TARGET) {
        case "admin":
            return <AdminPortal />;
        case "customer":
            return <CustomerPortal />;
        case "recurring":
            return <RecurringPortal />;
        case "checkout":
            return <CheckoutPortal />;
        case "company":
            return <CompanyPortal />;
        default:
            return <div>Invalid build target</div>;
    }
};

ReactDOM.createRoot(document.getElementById("root")!).render(
    booleanFromString(import.meta.env.VITE_STRICT) === false ? (
        renderPortal()
    ) : (
        <React.StrictMode>{renderPortal()}</React.StrictMode>
    )
);

const style = `color: #fff; background-color: #424ff2; padding: 0.25rem; border-radius: 0.25rem;`;
if (booleanFromString(import.meta.env.VITE_STRICT) === false) {
    console.log(
        `%cReact <StrictMode> is disabled.`,
        `${style} padding: 0.5rem 0.75rem; font-weight: 900;`,
        `\n  Read more about StrictMode here:`,
        `\n  https://react.dev/reference/react/StrictMode`
    );
}

console.group(`Build settings`);
console.log(`%cMode:`, style, import.meta.env.MODE);
console.log(`%cBuild type:`, style, reactAppBuildType);
console.log(`%cApplication:`, style, import.meta.env.VITE_BUILD_TARGET);
console.log(`%cAPI location:`, style, import.meta.env.VITE_API_URL);
console.groupEnd();

reportWebVitals();

if (import.meta.hot) {
    import.meta.hot.accept();
}
