import { colorsDeprecated as c } from "global-style";
import Tooltip from "components/Tooltip";
import Info from "components/icons/Info";

const InfoToolTip = ({
    title,
    size = `1rem`,
    color = `inherit`,
    placement = `right`,
}: any) => {
    color = c[color as keyof typeof c] ? c[color as keyof typeof c] : color;
    return (
        <Tooltip title={title} placement={placement}>
            <span>
                <Info
                    height={size}
                    width={size}
                    fill={color || c.lightOnSurface}
                />
            </span>
        </Tooltip>
    );
};

export default InfoToolTip;
