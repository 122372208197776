import React from "react";

const Checkmark = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
        >
            {name && <title>{name}</title>}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 .33a6.67 6.67 0 1 0 0 13.34A6.67 6.67 0 0 0 7 .33Zm0 12a5.34 5.34 0 1 1 .01-10.68A5.34 5.34 0 0 1 7 12.33ZM5.67 8.45l4.39-4.4L11 5l-5.33 5.33L3 7.67l.94-.94 1.73 1.72Z"
            />
        </svg>
    );
};

export default Checkmark;
