import React, { useCallback, useState, useRef } from "react";
import { Company, UpdateTransferDelegatedSigner } from "company/types";
import * as S from "./style";
import Button, { ButtonVariants } from "components/Button";
import { useNotificationQueue } from "context/NotificationQueue";
import TransferAmountField, {
    TransferAmountFieldRef,
} from "company/components/transfers/TransferAmountField";
import TransferUsdField, { TransferUsdFieldRef } from "../TransferUsdField";
import { GeneralTokenDetailsResponse, patchCompanyTransfers } from "api";
import { NotificationType } from "components/Notification";
import { useGetCompanyTransfers } from "company/hooks/useGetCompanyTransfers";
import { useUser } from "context/User";

interface UpdateTransferAmountFormProps {
    transaction: Company.Transaction;
    token: GeneralTokenDetailsResponse;
    onCancel?: () => void;
    onSuccess?: () => void;
}

const UpdateTransferAmountForm: React.FunctionComponent<UpdateTransferAmountFormProps> =
    ({ transaction, token, onCancel, onSuccess }) => {
        const [formLoading, setFormLoading] = useState<boolean>(false);
        const [usd, setUsd] = useState<boolean>(transaction.usd);

        // Session & Company data
        const { invalidateAllTransfersQueries } = useGetCompanyTransfers({
            id: transaction.transferId,
        });
        const { getEntityId, getSessionToken } = useUser();

        // Notifications
        const { addNotification } = useNotificationQueue();

        const transferAmountFieldRef = useRef<TransferAmountFieldRef>(null);
        const transferUsdFieldRef = useRef<TransferUsdFieldRef>(null);

        const successHandle = useCallback(async () => {
            await invalidateAllTransfersQueries();
            if (onSuccess) onSuccess();
        }, [invalidateAllTransfersQueries, onSuccess]);

        // RIP: useFetch :sad_face_emoji:
        // Note: Talked with Rocco, we need to consider refactoring the useFetch
        // and/or use a library like react-query for the POST scenario
        const updateAmount = async () => {
            setFormLoading(true);

            // Fields not present, return
            if (!transferAmountFieldRef.current || !transferUsdFieldRef.current)
                return;

            // Fields have not changed, return
            if (
                !transferAmountFieldRef.current.hasChanged &&
                !transferUsdFieldRef.current.hasChanged
            ) {
                if (onSuccess) onSuccess();
                return;
            }

            // Amount isn't valid, return
            if (!transferAmountFieldRef.current.validate()) return;

            const patchTransfer: UpdateTransferDelegatedSigner = {
                transferId: transaction.transferId,
                amount: undefined,
                usd: undefined,
            };

            // Always update the amount if usd field has changed
            if (
                transferAmountFieldRef.current.hasChanged ||
                transferUsdFieldRef.current.hasChanged
            ) {
                patchTransfer.amount =
                    transferAmountFieldRef.current.amountForApi;
            }

            if (transferUsdFieldRef.current.hasChanged) {
                patchTransfer.usd = transferUsdFieldRef.current.usd;
            }

            const headers = {
                Authorization: getSessionToken(),
                "entity-id": getEntityId(),
            };
            const { response } = await patchCompanyTransfers(
                [patchTransfer],
                headers
            );

            const successFullResponse = response.ok && response.status === 200;

            if (successFullResponse) {
                await successHandle();
            } else {
                addNotification({
                    msg: `There was an error while updating the amount`,
                    type: NotificationType.ERROR,
                });
            }

            setFormLoading(false);
        };

        const amountForDisplay = transaction.usd
            ? Number(transaction.amount) / 100
            : Number(transaction.amount) / 10 ** token.decimals;

        return (
            <S.EditForm onSubmit={updateAmount}>
                <S.InputWrapper>
                    <TransferAmountField
                        disabled={formLoading}
                        usd={usd}
                        token={token}
                        defaultAmount={String(amountForDisplay)}
                        ref={transferAmountFieldRef}
                    />
                    <S.UsdCheckboxWrapper>
                        <TransferUsdField
                            disabled={formLoading}
                            defaultUsd={transaction.usd}
                            onChangeUsd={(usd) => setUsd(usd)}
                            ref={transferUsdFieldRef}
                        />
                    </S.UsdCheckboxWrapper>
                </S.InputWrapper>
                <Button type="submit" loading={formLoading}>
                    {formLoading ? "Saving" : "Save"}
                </Button>
                <Button
                    variant={ButtonVariants.Anchor}
                    type="button"
                    disabled={formLoading}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
            </S.EditForm>
        );
    };

export default UpdateTransferAmountForm;
